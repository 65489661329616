import { ReportsCardInterface } from 'src/app/pages/about-us/about-fund/reports/reports-card/reports-card.component';

export const annualReportsMockData: ReportsCardInterface = {
  title: 'Рiчні звіти',
  text: [
    'У річних звітах ми описуємо досягнення фонду за рік, а також головні події у напрямку боротьби з деменцією.',
    'У звітах ми детально описуємо результати роботи по всіх програмах фонду, згадуємо про важливі події та ділимося планами. У звіті ви також можете переглянути фотографії та історії з перших вуст. Обирайте потрібний рік, щоб ознайомитися зі звітом.',
  ],
  reportsLinks: [
    { title: 'Квартальний звіт І Квартал 2021', link: '/' },
    { title: 'Квартальний звіт ІI Квартал 2021', link: '/' },
    { title: 'Квартальний звіт ІII Квартал 2021', link: '/' },
  ],
};
