<section>
  <app-page-banner-item
    [title]="'Напрями діяльності'"
    [text]="
      'Задля комплексних змін в ситуації з деменцією в Україні ми працюємо одночасно у декількох напрямах та з різними цільовими аудиторіями '
    "
    [imgUrl]="
      '../../../../assets/images/banner/areas-of-activities.png'
    "></app-page-banner-item>
</section>

<!-- <section class="py-30 w-295 mx-auto">
  <div class="">
    <app-info-cards [cards]="aboutUsOurProgramCards"></app-info-cards>
  </div>
</section> -->

<section class="my-30">
  <app-wide-card></app-wide-card>
</section>
