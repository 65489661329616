<div class="max-w-full bg-primary-base">
  <div
    class="flex flex-row mx-auto relative h-100 bg-primary-base bg-[url('src/assets/images/bgPic/nezabutkaFlower.png')] bg-no-repeat bg-[top_right_14rem]">
    <div
      class="flex flex-row content-container justify-start items-center text-gray-white">
      <div class="flex flex-col items-start w-145">
        <h2 class="text-h2-heading pb-5 uppercase">
          Чому нас треба підтримувати?
        </h2>
        <p class="w-195 text-main-text">
          Щодня ми працюємо над тим, щоб більше 500 тисяч людей з деменцією в
          Україні та їх родичів могли жити повноцінно та щасливо. Для цього ми
          надаємо фахові консультації лікарів, доглядальників, психологів та
          інших фахівців, проводимо заходи для людей з деменцією та їх родин,
          перекладаємо посібники, організовуємо інформаційні компанії, створюємо
          пам'ятки та ініціюємо зміни на державному рівні.
        </p>
      </div>
    </div>
  </div>
</div>
