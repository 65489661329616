import { Component, OnInit } from '@angular/core';
import { currentResearchInfoMockDate } from 'src/mockData/currentResearchInfoMockDate';
import ICurrentResearchInfo from 'src/app/models/currentResearchInfo.interface';

@Component({
  selector: 'app-current-research-info',
  templateUrl: './current-research-info.component.html',
  styleUrls: ['./current-research-info.component.css'],
})
export class CurrentResearchInfoComponent implements OnInit {
  currentResearchInfo: ICurrentResearchInfo[] = currentResearchInfoMockDate;

  constructor() {}

  ngOnInit(): void {}
}
