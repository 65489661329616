import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AboutDementiaThirdLevelComponent } from 'src/app/pages/about-dementia/about-dementia-second-level/about-dementia-third-level/about-dementia-third-level.component';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbAboutDementiaThirdLevelResolver
  implements Resolve<boolean>
{
  constructor(private breadcrumb: AboutDementiaThirdLevelComponent) {}

  resolve(): Observable<any> {
    return of(this.breadcrumb.currentBreadcrumb);
  }
}
