<app-page-banner-item
  [imgUrl]="'assets/images/banner/news-and-media.png'"
  title="Новини та медіа"
  text="Читайте останні новини про деменцію. Дізнайтеся, що потрапляє в заголовки газет, від досліджень деменції до оновлених кампаній, політики та збору коштів."></app-page-banner-item>
<main class="mt-5">
  <div class="my-30">
    <app-info-cards [cards]="cards"></app-info-cards>
  </div>
  <app-subscribe-news></app-subscribe-news>
</main>
