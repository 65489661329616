import { Component, OnInit, Input } from '@angular/core';
import { PressRelaseCardInterface } from 'src/app/models/press-relase-card.interface';
import IDetailedNews from 'src/app/models/detailed-news.interface';
import { detailedNewsPressReleases } from 'src/mockData/detailedNewsMockDate';

@Component({
  selector: 'app-press-release-card',
  templateUrl: './press-release-card.component.html',
  styleUrls: ['./press-release-card.component.css'],
})
export class PressReleaseCardComponent implements OnInit {
  detailedNewsPressRealize: IDetailedNews[] = detailedNewsPressReleases;

  @Input() PRCard!: PressRelaseCardInterface;

  isHovered: boolean = false;
  constructor() {}
  ngOnInit(): void {}
}
