import { Component, OnInit } from '@angular/core';
import IInfoPage from '../../../models/infoPage.interface';
import {
  aboutUsCardConfig,
  aboutUsTextConfig,
} from '../../../../mockData/ourPeopleMockData';

@Component({
  selector: 'app-our-people',
  templateUrl: './our-people.component.html',
  styleUrls: ['./our-people.component.css'],
})
export class OurPeopleComponent implements OnInit {
  block: IInfoPage[] = aboutUsTextConfig;
  cards: IInfoPage[] = aboutUsCardConfig;

  constructor() {}

  ngOnInit(): void {}
}
