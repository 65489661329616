import { Component, OnInit } from '@angular/core';
import { legalAidCards } from 'src/mockData/infoCardConfig';
import IInfoPage from 'src/app/models/infoPage.interface';
import { moreInfoLegalAid } from 'src/mockData/moreInfoMockData';
import { moreInfoHeaderLegalAid } from 'src/mockData/moreInfoMainMockData';
import moreIInfo from 'src/app/models/moreInfo.interface';
import moreIInfoMain from 'src/app/models/moreInfoMainModel';

@Component({
  selector: 'app-legal-assistance',
  templateUrl: './legal-assistance.component.html',
  styleUrls: ['./legal-assistance.component.css'],
})
export class LegalAssistanceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  moreInfosAid: moreIInfo[] = moreInfoLegalAid;
  mainText: moreIInfoMain[] = moreInfoHeaderLegalAid;
  cards: IInfoPage[] = legalAidCards;
}
