<ng-container>
  <div class="content-container">
    <h1 class="text-h1-heading text-primary-base uppercase">Допомогти</h1>

    <div class="flex-wrap mb-20 mt-5">
      <div class="flex flex-row">
        <app-tabs>
          <app-tab [tabTitle]="'Разова допомога'" [position]="'first'"
            ><app-single-help></app-single-help
          ></app-tab>
          <app-tab [tabTitle]="'Щомісячна допомога'" [position]="'last'"
            ><app-subscribe-help></app-subscribe-help
          ></app-tab>
        </app-tabs>
        <div class="w-95 ml-5 mt-19.5">
          <div class="flex flex-col bg-gray-card rounded-30 px-5 pt-10 pb-5">
            <h2 class="text-h2-heading text-primary-base uppercase pr-19.5">
              На що підуть ці гроші?
            </h2>
            <div
              class="flex flex-row items-center h-45 border-b-2 border-b-gray-stroke">
              <img
                class="mr-4"
                src="./assets/images/hepl-page/people-information.png"
                alt="" />
              <div class="text-gray-dark text-main-text">
                Надання інформаційної, юридичної та психологічної підтримки
                родинам, які зіштовхнулися із деменцією
              </div>
            </div>
            <div
              class="flex flex-row h-45 items-center border-b-2 border-b-gray-stroke">
              <img
                class="mr-4"
                src="./assets/images/hepl-page/speaker.png"
                alt="" />
              <div class="text-gray-dark text-main-text">
                Поширення обізнаності щодо деменції та профілактики хвороби
                серед населення України
              </div>
            </div>
            <div class="flex flex-row h-45 items-center">
              <img
                class="mr-4"
                src="./assets/images/hepl-page/hands.png"
                alt="" />
              <div class="text-gray-dark text-main-text">
                Надання екстреної адресної допомоги родинам та закладам
                геріатричного та психоневрологічною профілю
              </div>
            </div>
          </div>
          <div class="mt-4.5 w-95 h-12">
            <app-link-button
              [title]="'Переглянути звіти'"
              [linkButtonType]="'Secondary'"
              routerLink="/about-us/about-fund/reports">
            </app-link-button>
          </div>
        </div>
      </div>
    </div>
    <section class="mb-30">
      <app-slider
        id="payment"
        [title]="'Реквізити рахунків фонду у гривні та валюті'"
        [slide]="true"
        [SlidesArray]="requisitesPaid"></app-slider>
    </section>

    <section class="mb-30">
      <app-slider
        [title]="'Інші способи зробити переказ'"
        [slide]="false"
        [SlidesArray]="AlternativePaidArray"></app-slider>
    </section>

    <section class="flex-col">
      <div class="mb-10">
        <h3 class="text-h2-heading text-primary-base">
          {{ 'Запитання та відповіді' | uppercase }}
        </h3>
      </div>
      <app-accordion
        [AccordionsList]="accordionList"
        [options]="{ multi: false }"
        [classList]="'mb-30'"></app-accordion>
    </section>
  </div>
</ng-container>
