import { Component, Injectable, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import IDetailedNews from 'src/app/models/detailed-news.interface';
import { detailedNewsPressReleases } from 'src/mockData/detailedNewsMockDate';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-press-releases-details',
  templateUrl: './press-releases-details.component.html',
  styleUrls: ['./press-releases-details.component.css'],
})
export class PressReleasesDetailsComponent implements OnInit {
  title = 'Release title';
  detailedNewsPressRealize: IDetailedNews[] = detailedNewsPressReleases;
  private pdfSubscription!: Subscription;

  currentMember!: IDetailedNews;
  constructor(
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private http: HttpClient,
  ) {}
  memberID = this.route.snapshot.params['id'];

  ngOnInit(): void {}

  TitleSet(): string {
    return this.title;
  }

  ngOnDestroy(): void {
    this.pdfSubscription && this.pdfSubscription.unsubscribe();
  }

  public downloadPdfFile(): void {
    this.pdfSubscription = this.http
      .get('/assets/pdf/statistics.pdf', {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe((res: any) => {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement('a');
        a.download = 'test';
        a.href = url;
        a.click();
      });
  }
  copyMail() {
    this.clipboard.copy('media@nezabutni.org');
  }
}
