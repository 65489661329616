<div class="flex flex-col items-center content-container">
  <div class="content-container mb-10">
    <h1 class="text-h1-heading text-primary-base uppercase md:w-95">Новини</h1>
  </div>
  <app-filter
    (chosenValue)="valueForFilter($event)"
    [ListArray]="['Спочатку нові', 'Спочатку старі']"></app-filter>
  <div
    class="flex flex-wrap justify-center px-2 xl:px-0 xl:grid xl:grid-cols-3 gap-5">
    <app-card
      *ngFor="
        let newsCard of newsCards
          | paginate : { itemsPerPage: 9, currentPage: p }
      "
      [newsCard]="newsCard"></app-card>
  </div>
  <app-pagination class="mt-20" (pageChange)="p = $event"></app-pagination>
</div>
<app-subscribe-news></app-subscribe-news>
