import IResearchCard from 'src/app/models/researchCard.interface';
export const researchCardMockDate: IResearchCard[] = [
  {
    text: 'Дата публікації:',
    data: '22.09.2001',
    title: 'Благодійність у часи війни — кількісне дослідження',
    link: 'Перейти',
  },
  {
    text: 'Дата публікації:',
    data: '22.09.2001',
    title: 'Благодійність у часи війни — кількісне дослідження',
    link: 'Перейти',
  },
  {
    text: 'Дата публікації:',
    data: '22.09.2001',
    title: 'Благодійність у часи війни — кількісне дослідження',
    link: 'Перейти',
  },
];

export const otherPublicationsCards: IResearchCard[] = [
  {
    text: 'Дата публікації:',
    data: '22.09.2001',
    title:
      'World Alzheimer Report 2022 Life after diagnosis: Navigating treatment, care and support',
    link: 'Перейти',
  },
  {
    text: 'Дата публікації:',
    data: '22.09.2001',
    title:
      'Оцінка ефективності управління соціальними послугами для хворих на демецію через механізм громадського контролю',
    link: 'Перейти',
  },
];
