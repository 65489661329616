import IInfoPage from 'src/app/models/infoPage.interface';

export const infoCardConfig: IInfoPage[] = [
  {
    title: 'Більше про деменцію',
    text: 'Прочитайте нашу сторінку «Що таке деменція?» для отримання додаткової інформації.',
    link: '/about-dementia/info/what-dementia-is',
  },
  {
    title: 'Симптоми',
    text: 'Дізнайтеся більше про симптоми деменції з нашої сторінки.',
    link: '/about-dementia/info/symptoms',
  },
  {
    title: 'Типи деменції',
    text: 'Різні типи деменції можуть по-різному впливати на людей, і кожен буде відчувати симптоми по-своєму.',
    link: '/about-dementia/info/dementia-types',
  },
  {
    title: 'Каталог «Про деменцію»',
    text: 'Ми створили для Вас зручний каталог у PDF, який можна переглянути за завантажити на сторінці.',
    link: '/about-dementia/info/catalog-about-dementia',
  },
  {
    title: 'Каталог «Для доглядальників»',
    text: 'Детальна інформація по догляду людей із деменцією.',
    link: '/',
  },
  {
    title: 'Часті питання',
    text: 'Тут Ви можете знайти відповіді на часті питання.',
    link: '/about-dementia/info/faq',
  },
];

export const peopleWithDementiaInfoCardConfig: IInfoPage[] = [
  {
    title: 'Історії дітей та друзів',
    text: 'Прочитайте історії дітей та друзів людей, які живуть із деменцією.',
    link: '/about-dementia/personal-stories/children-and-friends',
  },
  {
    title: 'Історії родичів та доглядальників',
    text: 'Прочитайте розповіді членів сімей людей, які живуть з деменцією.',
    link: '/about-dementia/personal-stories/relatives-and-caregivers',
  },
];

export const newsAndMedia: IInfoPage[] = [
  {
    title: 'Новини',
    text: 'Прочитайте нашу сторінку «Що таке деменція?» для отримання додаткової інформації.',
    link: '/about-us/news-and-media/news',
  },
  {
    title: 'Статистика',
    text: 'Дізнайтеся більше про симптоми деменції з нашої сторінки.',
    link: '/about-us/news-and-media/statistic',
  },
  {
    title: 'Медіа центр',
    text: 'Різні типи деменції можуть по-різному впливати на людей, і кожен буде відчувати симптоми по-своєму.',
    link: '/about-us/news-and-media/media-center',
  },
  {
    title: 'Змі про нас',
    text: 'Ми створили для Вас зручний каталог у PDF, який можна переглянути за завантажити на сторінці.',
    link: '/about-us/news-and-media/media-about-us',
  },
];

export const legalAidCards: IInfoPage[] = [
  {
    title: 'Отримання інвалідності',
    text: 'Прочитайте нашу сторінку «Що таке деменція?» для отримання додаткової інформації.',
    link: '',
  },
  {
    title: 'отримання недієздатності',
    text: 'Дізнайтеся більше про симптоми деменції з нашої сторінки.',
    link: '',
  },
  {
    title: 'оформлення спадщини',
    text: 'Різні типи деменції можуть по-різному впливати на людей, і кожен буде відчувати симптоми по-своєму.',
    link: '',
  },
  {
    title: 'пільги для людей з деменцією',
    text: 'Різні типи деменції можуть по-різному впливати на людей, і кожен буде відчувати симптоми по-своєму.',
    link: '',
  },
];
