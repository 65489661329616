<div class="flex w-full md:w-295 items-center justify-between">
  <a
    *ngIf="headerButtonVisible; else elseBlock"
    href="/"
    class="h-10 md:h-13 w-12 md:w-52.5 text-h2-heading overflow-hidden md:flex-grow">
    <img
      class="h-10 md:h-13 w-12 md:w-52.5 object-cover object-left"
      src="assets/images/svg-images/logo.svg"
      alt="Logo nezabutni" />
  </a>
  <ng-template #elseBlock>
    <a href="/" class="mr-6 h-19.75 w-80.5 text-h2-heading mb-33">
      <img
        width="322"
        height="80"
        src="assets/images/not-found/header_logo_notFound.png"
        alt="Logo nezabutni" />
    </a>
  </ng-template>

  <div
    class="flex gap-5 items-center md:flex-shrink-0"
    *ngIf="headerButtonVisible">
    <app-link-button
      *ngIf="!(isMobile$ | async) && !(isTablet$ | async)"
      url="sai"
      [linkButtonType]="'Secondary'"
      [title]="'Звʼязатись'"
      class="w-50 md:w-55">
    </app-link-button>
    <app-link-button
      url="join/donate"
      [linkButtonType]="'Donate'"
      [title]="'Допомогти'"
      class="w-50 h-10 md:h-12 md:w-55">
    </app-link-button>
  </div>
  <app-mobile-main-menu
    class="md:ml-5 md:flex-shrink-0"
    *ngIf="
      (isMobile$ | async) || (isTablet$ | async) || (isLaptop$ | async)
    "></app-mobile-main-menu>
</div>
