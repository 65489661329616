<li
  class="group border-2 border-stoke p-5 xl:p-[38px] bg-gray-white hover:border-secondary-base h-73 w-86 md:w-102.5 xl:w-145 rounded-30">
  <a
    class="w-full h-full flex flex-col justify-between"
    routerLink="{{ card.link }}">
    <div class="h-full">
      <h2
        class="text-primary-base uppercase text-h3-subheading md:text-h2-heading mt-[1px] ml-[1px] mb-2.5 flex-none w-full xl:w-124">
        {{ card.title }}
      </h2>
      <p class="grow text-smaller-main md:text-main-text text-gray-dark">
        {{ card.text }}
      </p>
    </div>

    <div class="w-full md:w-82.5 xl:w-55 h-12 content-center flex-none">
      <!-- <app-link
        class="group-hover:bg-secondary-base"
        [linkType]="'Primary'"
        [title]="'Перейти'"></app-link> -->
      <p
        class="w-full h-full inline-block py-2.5 text-center text-main-text-bold text-gray-white rounded-10 bg-accent-base bg-accent group-hover:bg-secondary-base">
        Перейти
      </p>
    </div>
  </a>
</li>

<!-- <li class="border-2 border-stoke rounded-30 p-10 h-73 flex flex-col">
  <h2 class="text-primary-base uppercase text-h2-heading mb-2.5 flex-none w-124">
    {{ card.title }}
  </h2>
  <p class="grow text-main-text text-gray-dark">
    {{ card.text }}
  </p>
  <div class="w-55 h-12 content-center flex-none">
    <app-link-button
      [linkButtonType]="'Primary'"
      routerLink="{{ card.link }}"
      [title]="'Перейти'"></app-link-button>
  </div>
</li> -->
