<app-page-banner-item
  id="page-top"
  title="Про деменцію"
  text="Що таке деменція, як поставити діагноз і як зменшити ризик розвитку деменції або відстрочити її вплив"
  [imgUrl]="
    './assets/images/banner/banner-about-dementia.jpg'
  "></app-page-banner-item>
<!-- <div>{{ currentBreadcrumb }}</div> -->
<section class="grid mt-30">
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="cards"></app-table-of-content>
</section>
<section class="content-container text-start flex mt-30">
  <div [innerHTML]="lightText"></div>
</section>
<section class="content-container text-start flex mt-15">
  <div [innerHTML]="darkText"></div>
</section>

<section class="content-container text-start flex flex-col mb-30">
  <div *ngFor="let card of cards" class="mt-15" [id]="card.id">
    <div class="text-primary-base text-h2-heading-bold">
      {{ card.title | uppercase }}
    </div>
    <div class="flex flex-wrap gap-5 mt-10">
      <div *ngFor="let subCard of card.subMenuCards">
        <app-page-link-card [card]="subCard"></app-page-link-card>
      </div>
    </div>
  </div>
</section>
