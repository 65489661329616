import { Component, OnInit } from '@angular/core';
import { pressReleasesMockData } from 'src/mockData/pressReleasesMockData';
import { PressRelaseCardInterface } from 'src/app/models/press-relase-card.interface';

@Component({
  selector: 'app-press-releases',
  templateUrl: './press-releases.component.html',
  styleUrls: ['./press-releases.component.css'],
})
export class PressReleasesComponent implements OnInit {
  pressReleasesArray: PressRelaseCardInterface[] = pressReleasesMockData;
  showAllReleases: boolean = false;
  constructor() {}

  ngOnInit(): void {
    if (this.pressReleasesArray.length <= 8) {
      this.showAllReleases = false;
    } else {
      this.showAllReleases = true;
    }
  }
}
