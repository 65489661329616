import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-link-cards-block',
  templateUrl: './page-link-cards-block.component.html',
  styleUrls: ['./page-link-cards-block.component.css'],
})
export class PageLinkCardsBlockComponent implements OnInit {
  @Input() cardBlockTitle: string = '';
  @Input() cardBlockId: string = '';
  @Input() cardList!: any;
  constructor() {}

  ngOnInit(): void {}
}
