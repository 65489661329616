import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, NonNullableFormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import {
  IContactForm,
  IContactFormData,
} from '../../models/contact-form.interface';
import { nameValidator } from 'src/app/components/forms/validators/name-validator';
import { emailValidator } from 'src/app/components/forms/validators/email-validator';
import { AgreementConfig } from 'src/mockData/agreement-docs-config';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css'],
})
export class ContactFormComponent implements OnInit, OnDestroy {
  contactForm!: FormGroup<IContactForm>;
  private pdf: any;
  private fundPolicySub!: Subscription;
  show = false;
  showForm = true;

  openPopUp() {
    if (this.contactForm.valid) {
      this.show = true;
      this.showForm = false;
      this.contactForm.reset();
    }
  }
  closePopUp() {
    this.showForm = true;
    this.show = false;
  }

  constructor(
    private fb: NonNullableFormBuilder,
    private config: AgreementConfig,
    private sanitazer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this._initContactForm();
  }

  ngOnDestroy(): void {
    this.fundPolicySub && this.fundPolicySub.unsubscribe();
  }

  public onSubmit(): void {
    const data: IContactFormData = {
      name: this.contactForm.value.name?.trim(),
      email: this.contactForm.value.email?.trim(),
      message: this.contactForm.value.message?.trim(),
      policy: this.contactForm.value.policy,
    };
    console.log(data);
  }

  public onOpenFundPolicy(): void {
    this.fundPolicySub = this.config
      .getFundPolicy()
      .subscribe((basePdf: any) => {
        let objectURL = 'data:application/pdf;base64,' + basePdf.pdf;
        this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(objectURL);

        const iframe = document.createElement('iframe');
        iframe.src = objectURL;
        iframe.width = '100%';
        iframe.height = '100%';
        iframe.style.border = 'none';

        const newWindow = window.open('', '_blank');
        newWindow!.document.body.appendChild(iframe);
        newWindow!.document.title = 'FundPolicy';
      });
  }

  private _initContactForm(): void {
    this.contactForm = this.fb.group({
      name: this.fb.control('', [Validators.required, nameValidator]),
      email: this.fb.control('', [Validators.required, emailValidator]),
      message: this.fb.control('', [Validators.required]),
      policy: this.fb.control(false, [
        Validators.required,
        Validators.requiredTrue,
      ]),
    });
  }
}
