import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ROUTE_NAMES } from 'src/app/constants/route_names';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-about-dementia-second-level',
  templateUrl: './about-dementia-second-level.component.html',
  styleUrls: ['./about-dementia-second-level.component.css'],
})
export class AboutDementiaSecondLevelComponent implements OnInit, OnDestroy {
  lightText = '';
  darkText = '';
  cards: any[] = [];
  currentBreadcrumb: string | null = 'second';
  private routeSubscription: Subscription | null = null;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(params => {
      const routeId = params['secondId'];
      this.router.navigate([], { state: { id: routeId } });
      if (routeId) {
        this.fetchCardList(routeId)
          .pipe(
            tap((data: any[]) => {
              this.lightText = data[0].contentLight;
              this.darkText = data[0].contentDark;
              this.cards = data[0].menuSubTitle.map((record: any) => ({
                title: record.SubMenuTitle,
                text: record.shortContent,
                id: record.SubMenuId,
                subMenuCards: record.SubMenuCards.map((record: any) => ({
                  link:
                    ROUTE_NAMES.aboutDementia +
                    data[0].id +
                    '/' +
                    record.cardId,
                  cardTitle: record.cardTitle,
                })),
              }));
              // console.log(data[0]);
              this.currentBreadcrumb = data[0].menuTitle;
              const element = document.querySelector(
                '[data-breadcrumb="second"]',
              );
              // console.log(element);
              if (element) {
                element.innerHTML = data[0].menuTitle;
              }
            }),
          )
          .subscribe();
      }
    });
    console.log('first level ngOnInit', this.currentBreadcrumb);
  }

  fetchCardList(routeId: string): Observable<any[]> {
    const url = `https://nezabutni-backend.vercel.app/api/dementia-second-level-page?id=${routeId}`;
    return this.http.get<any[]>(url);
  }
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
