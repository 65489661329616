import { Component, OnInit } from '@angular/core';
import { IContact } from 'src/app/models/contact.interface';
import { ContactsEnum } from 'src/app/models/contacts.enum';
import { ISocialLink } from 'src/app/models/social-link.interface';

import {
  contactsConfig,
  socialLinksConfig,
} from 'src/mockData/aboutUs_Contacts_Config';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
})
export class ContactsComponent implements OnInit {
  protected contacts: IContact[] = contactsConfig;
  protected socialLinks: ISocialLink[] = socialLinksConfig;

  // This Map needed to bind different styles to contact-item component depends on its id
  protected readonly contactItemMap = new Map<ContactsEnum, string>([
    [ContactsEnum.Address, 'text-h3-subheading'],
    [ContactsEnum.Call, 'text-h3-subheading'],
    [ContactsEnum.Write, 'text-h3-subheading'],
  ]);

  constructor() {}

  ngOnInit(): void {}
}
