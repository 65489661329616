export const ROUTE_NAMES = {
  main: 'Головна',
  aboutDementia: 'about-dementia',
  faq: 'faq',
  dementiaTypes: 'dementia-types',
  prophylaxis: 'prophylaxis',
  diagnosis: 'diagnosis-therapy',
  info: 'info',
  whatDementiaIs: 'what-dementia-is',
  needHelp: 'get-support',
  helpWithMedicine: 'help-with-medication',
  personCenteredCare: 'Person-centred care',
  talkingTherapies: 'Talking therapies',
  childrenAndFriends: 'Дітей та друзів',
  psychologicalSupport: 'psychological-support',
  legalAssistance: 'legal-assistance',
  doctorConsultation: 'medical-assistance',
  careConsultation: 'care-consultation',
  rehabilitationProgram: 'rehabilitation-program',
  informationalAssistance: 'informational-assistance',
  crisisAssistance: 'crisis-assistance',
  helpWithGenerators: 'help-with-generators',
  helpAbroad: 'help-abroad',
  helpWithEvacuation: 'help-with-evacuation',
  whatToDoIf: 'what-to-do-if',
  iLiveWithDementia: 'i-live-with-dementia',
  iWorryAboutMemory: 'i-worry-that-i-have-dementia',
  justDiagnosed: 'i-have-dementia',
  medicalEmployee: 'medical-employee',
  professionalCaregiver: 'professional-caregiver',
  relativeHasDementia: 'i-am-a-caregiver,-a-sibling-or-a-friend',
  join: 'join',
  donate: 'donate',
  becomeVolunteer: 'become-volunteer',
  becomePartner: 'become-partner',
  findInformation: 'find-information',
  activities: 'activities',
  aboutUs: 'about-us',
  aboutFund: 'about-fund',
  reportsTax: 'reports-tax',
  reports: 'reports-and-documents',
  reportsYearly: 'reports-yearly',
  reportsDocs: 'reports-docs',
  ourIdea: 'our-idea',
  history: 'history',
  reportsMonthly: 'reports-monthly',
  help: 'help',
  contacts: 'contacts',
  ourPeople: 'our-people',
  fundTeam: 'fund-team',
  supervisoryBoard: 'supervisory-board',
  advisoryGroup: 'advisory-group',
  ourPartners: 'our-partners',
  vacancies: 'vacancies',
  ourPrograms: 'our-programs',
  helpDuringTheWar: 'help-during-the-war',
  supportForFamilies: 'support-for-families',
  friendlySociety: 'friendly-society',
  study: 'study',
  research: 'research',
  newsAndMedia: 'news-and-media',
  news: 'news',
  mediaAboutUs: 'media-about-us',
  statistic: 'statistic',
  mediaCenter: 'media-center',
  pressReleases: 'press-releases',
  howToTalkAboutDementiaInTheMedia: 'how-to-talk-about-dementia-in-the-media',
  keyFactsAboutDementia: 'key-facts-about-dementia',
  glossary: 'glossary',
  detailedNews: 'Детальні новини',
  detailedNewsPressRealize: 'Пресрелізи від 22.09.2021',
  fiveThingsAboutDementiaToKnow: '5 речей, які треба знати про деменцію',
  symptoms: 'Симптоми',
  questionForDoctor: 'Питання лікарю',
  personalStories: 'Персональні історії',
  peopleWithDementia: 'Людей з деменцією',
  relativesAndCaregivers: 'Родичів та доглядальників',
  dementiaStages: 'Стадії деменції',
  dementiaAndCovid19: 'Деменція і COVID-19',
  dementiaInUkraine: 'Деменція в Україні',
  catalogAboutDementia: 'Каталог «Про деменцію»',
  earlyDementia: 'Рання деменція',
  riskFactors: 'Фактори ризику',
  howReduceRiskOfDementia: 'Як зменшити ризики розвитку деменції',
  isDementiaHeritable: 'Чи деменція спадкова?',
  whyDiagnosisNeed: 'Навіщо мати діагноз?',
  diagnosticProcess: 'Процес діагностики',
  diagnosisAcceptance: 'Прийняття діагнозу',
  passTest: 'Пройти тест',
  therapy: 'Терапія',
  medical: 'Медична',
  nonMedical: 'Немедична',
};
