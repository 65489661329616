export const linksOnSourceOfInformationMockData = [
  {
    title: 'Посилання на джерела інформації',
    links: [
      {
        title:
          'World Health Organisation, Risk Reduction of Cognitive Decline and Dementia, 2020.',
        url: 'https://www.who.int/publications/i/item/9789241550543',
      },
    ],
  },
];
