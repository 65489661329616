import { Component, OnInit } from '@angular/core';
import IInfoPage from 'src/app/models/infoPage.interface';
import { newsAndMedia } from 'src/mockData/infoCardConfig';

@Component({
  selector: 'app-news-and-media',
  templateUrl: './news-and-media.component.html',
  styleUrls: ['./news-and-media.component.css'],
})
export class NewsAndMediaComponent implements OnInit {
  cards: IInfoPage[] = newsAndMedia;
  constructor() {}

  ngOnInit(): void {
    console.log(this.cards);
  }
}
