import { Component, Input, OnInit } from '@angular/core';

import { AgreementConfig } from 'src/mockData/agreement-docs-config';
import { DomSanitizer } from '@angular/platform-browser';
import { OpenPdf } from 'src/app/services/pdfOpen.service';

export interface ReportsCardInterface {
  title: string;
  text: string[];
  reportsLinks?: ReportsPrewInterface[];
}
export interface ReportsPrewInterface {
  title: string;
  link: string;
}
@Component({
  selector: 'app-reports-card',
  templateUrl: './reports-card.component.html',
  styleUrls: ['./reports-card.component.css'],
  providers: [OpenPdf],
})
export class ReportsCardComponent implements OnInit {
  @Input()
  reportsData!: ReportsCardInterface;
  pdf: any;
  constructor(
    private config: AgreementConfig,
    private sanitazer: DomSanitizer,
  ) {}

  ngOnInit(): void {}

  onOpenFinancialReport() {
    const openOferta = new OpenPdf(this.config, this.sanitazer);
    openOferta.onOpenTestFinancialReport();
  }
}
