import IAboutUsOurProgram from 'src/app/models/aboutUsOurProgram.interface';
export const aboutUsOurProgramConfig: IAboutUsOurProgram[] = [
  {
    src: '/assets/images/activities/activities-support.png',
    title: 'Допомога та підтримка',
    text: 'Допомога та підтримка людей з деменцією та їхніх родин - одна з ключових цілей фонду. Цей напрям включає такі види допомоги: допомога з ліками, психологічна підтримка, юридична допомога, допомога з доглядом, консультація лікаря, реабілітаційна арт-програма, інформаційна допомога. З початку повномасштабної війни в Україні ми надаємо кризову допомогу родинам людей з деменцією, а також установам медичного профілю та соціальної підтримки. Вона включає допомогу з генераторами для установ, допомогу з переміщенням та поселенням та допомогу за кордоном.',
    link: '',
  },
  {
    src: '/assets/images/activities/activities-advocacy.png',
    title: 'Адвокація',
    text: 'Важливим напрямком діяльності фонду є адвокація прав людей з деменцією та їх доглядальників. Фонд “Незабутні” є учасником робочої групи при МОЗ щодо створення Національного плану з реагування на деменцію в Україні. У 2017 році Україна підписала Глобальний план дій з питань реагування на деменцію ВООЗ, де зобов’язалася розробити Національний план дій з реагування на деменцію.',
    link: '',
  },
  {
    src: '/assets/images/activities/activities-friendly-society.png',
    title: 'Дружнє суспільство',
    text: 'Суспільство, дружнє до людей з деменцією, - це концепція, що передбачає створення інклюзивного середовища, де люди з деменцію можуть почуватися безпечно та комфортно, мати доступ до усіх необхідних послуг та сервісів, реалізовувати свої права та свободи. Будуючи суспільство, дружнє до людей з деменцією, ми працюємо над підвищенням обізнаності про деменцію, зменшенням стигматизації та розширенням можливостей для людей з деменцією та їхніх родичів.',
    link: '',
  },

  {
    src: '/assets/images/activities/activities-education.png',
    title: 'Навчання',
    text: 'Ми підтримуємо родичів та доглядальників людей з деменцією, медичних фахівців, надавачів соціальних послуг та всіх зацікавлених у їх прагненні поглиблювати свої знання про деменцію, здобувати нові навички та вдосконалювати надання послуг для людей з деменцією. З цією метою ми організовуємо вебінари із запрошеними спікерами, проводимо тренінги для родичів та надавачів послуг на різну тематику і створюємо навчальні матеріали.',
    link: '',
  },
  {
    src: '/assets/images/activities/activities-investigation.png',
    title: 'Дослідження',
    text: 'Даних та пріоритетності деменції в наукових та дослідницьких середовищах. Тема деменції є мало досліджуваною в Україні, бракує релевантних статистичних даних та пріоритетності деменції в наукових та дослідницьких середовищах. Однак зміни неможливі без належної оцінки потреб та ситуації. Тому ми проводимо власні дослідження і співпрацюємо з національними та міжнародними партнерами, що займаються проведенням досліджень. ',
    link: '',
  },
];
