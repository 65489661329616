<div
  (click)="navigate(newsCard.id)"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false"
  class="w-82 md:w-210 xl:w-95 group cursor-pointer rounded overflow-hidden">
  <!--
  routerLink="{{-->
  <!--    newsCard.link === ''-->
  <!--      ? '/about-us/news-and-media/news/' + newsCard.id-->
  <!--      : newsCard.link-->
  <!--  }}"
    -->
  <img
    [src]="newsCard.picturelink"
    alt="image"
    class="hidden xl:flex xl:max-w-full" />
  <div
    class="h-87 md:h-73 xl:h-85.75 p-10 xl:p-0 flex flex-col items-start xl:items-center justify-between border-2 xl:border-t-0 rounded-30 xl:rounded-t-none xl:rounded-b-30 group-hover:border-secondary-base">
    <div>
      <div class="h-5 xl:mt-4.25 leading-5">
        <img
          alt="calender icon"
          class="inline-block"
          src="./assets/images/news/calendar.png" />
        <p class="pl-2 inline-block text-details text-gray-details">
          <time id="datetime">{{ newsCard.date }}</time>
        </p>
      </div>
      <h2 class="w-full xl:w-75 pt-3 text-main-text-bold text-gray-dark">
        {{ newsCard.title }}
      </h2>
      <p
        class="hidden md:flex w-full xl:w-75 pt-2.5 text-gray-dark text-smaller-main">
        {{ newsCard.shortText }}
      </p>
      <p
        class="flex md:hidden w-full xl:w-75 pt-2.5 text-gray-dark text-smaller-main">
        {{ newsCard.shortText | slice : 0 : 63 }}
      </p>
    </div>
    <app-link-button
      [isHovered]="isHovered"
      [linkButtonType]="'Primary'"
      [title]="'Детальніше'"
      class="w-62 md:w-75 xl:h-13 xl:m-10 xl:mt-0">
    </app-link-button>
  </div>
</div>
