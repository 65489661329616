import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  GetSupportApiService,
  GetSupportPageInterface,
} from 'src/app/services/api/getSupportPages/get-support-api.service';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NeedHelpComponent implements OnInit {
  needHelpPageData: GetSupportPageInterface = {
    bannerText: '',
    firstBlock: [{ title: '', text: '', link: '' }],
    secondBlockTitle: { title: '', text: '' },
    secondBlock: [{ title: '', text: '', link: '' }],
    tabsBlockTitle: { title: '', text: '' },
    needHelpDataTab: [{ title: '', text: '', active: false, linkTo: '' }],
    lastBlock: { title: '', text: '' },
  };
  bgImage = '';
  imgUrl = '/assets/images/banner/needHelpBanner.png';
  constructor(
    private pageService: GetSupportApiService,
    private cdr: ChangeDetectorRef,
  ) {}
  ngOnInit(): void {
    this.bgImage = `linear-gradient(90deg, rgba(255, 255, 255, 0) 47.55%, rgb(255, 255, 255) 100%), url("${this.imgUrl}")`;
    this.pageService.getPageData().subscribe((res: GetSupportPageInterface) => {
      this.needHelpPageData = res;
      this.cdr.markForCheck();
    });
  }
}
