import { Component, OnInit } from '@angular/core';
import { medicalAssistanceCard } from 'src/mockData/medicalAssistanceCardMockData';
import moreIInfo from 'src/app/models/moreInfo.interface';
import moreIInfoMain from 'src/app/models/moreInfoMainModel';
import { moreInfoHeaderLegalAid } from 'src/mockData/moreInfoMainMockData';
import IMedicalAssistance from 'src/app/models/medicalAssistance.interface';
import {
  MedicalAssistanceMockData,
  MedicalAssistanceWebinarMockData,
} from 'src/mockData/medicalAssistanceMockData';
import { accordionListMedicalAssistance } from 'src/mockData/accordionHelpList';

@Component({
  selector: 'app-doctor-consultation',
  templateUrl: './doctor-consultation.component.html',
  styleUrls: ['./doctor-consultation.component.css'],
})
export class DoctorConsultationComponent implements OnInit {
  consultations: IMedicalAssistance[] = MedicalAssistanceMockData;
  webinar: IMedicalAssistance[] = MedicalAssistanceWebinarMockData;
  medicalAssistanceCard: moreIInfo[] = medicalAssistanceCard;
  mainText: moreIInfoMain[] = moreInfoHeaderLegalAid;
  public accordionList: any = accordionListMedicalAssistance;

  constructor() {
    this.links = [
      {
        id: 'DoctorsConsultations',
        title: 'Консультації лікаря',
      },
      {
        id: 'WebinarsWithMedicalSpecialists',
        title: 'Вебінари з медичними фахівцями',
      },
      {
        id: 'AdviceAndInformationAboutMedicalCare',
        title: 'Поради та інформація щодо медичної допомоги',
      },
    ];
  }

  protected links: { readonly id: string; readonly title: string }[] = [];

  ngOnInit(): void {}
}
