<div
  class="flex mx-auto md:w-full h-[600px] md:h-[550px] xl:h-125 relative justify-center bg-primary-base">
  <div class="flex wide-container h-full relative">
    <img
      src="{{ imgUrl }}"
      alt="{{ title }}"
      class="absolute right-0 h-[440px] md:h-[550px] xl:h-125 object-cover" />
    <div
      class="mx-auto w-full h-full z-10 bg-[linear-gradient(0deg,#6E285F_40.16%,rgba(110,40,95,0.991615)_43.09%,rgba(110,40,95,0.967585)_45.48%,rgba(110,40,95,0.9296)_47.41%,rgba(110,40,95,0.879348)_48.95%,rgba(110,40,95,0.818519)_50.21%,rgba(110,40,95,0.7488)_51.25%,rgba(110,40,95,0.671881)_52.17%,rgba(110,40,95,0.589452)_53.04%,rgba(110,40,95,0.5032)_53.96%,rgba(110,40,95,0.414815)_55%,rgba(110,40,95,0.325985)_56.25%,rgba(110,40,95,0.2384)_57.8%,rgba(110,40,95,0.153748)_59.73%,rgba(110,40,95,0.0737185)_62.12%,rgba(110,40,95,0)_65.05%)] md:bg-[linear-gradient(90deg,#6E285F_40.16%,rgba(110,40,95,0.991615)_43.09%,rgba(110,40,95,0.967585)_45.48%,rgba(110,40,95,0.9296)_47.41%,rgba(110,40,95,0.879348)_48.95%,rgba(110,40,95,0.818519)_50.21%,rgba(110,40,95,0.7488)_51.25%,rgba(110,40,95,0.671881)_52.17%,rgba(110,40,95,0.589452)_53.04%,rgba(110,40,95,0.5032)_53.96%,rgba(110,40,95,0.414815)_55%,rgba(110,40,95,0.325985)_56.25%,rgba(110,40,95,0.2384)_57.8%,rgba(110,40,95,0.153748)_59.73%,rgba(110,40,95,0.0737185)_62.12%,rgba(110,40,95,0)_65.05%)]"></div>
  </div>
  <div class="content-container absolute z-20 pb-20 bottom-0">
    <h2
      class="text-h1-360 md:text-h1-heading text-gray-white uppercase mb-5 w-full px-4 md:px-0 md:w-[410px] xl:w-170">
      {{ title }}
    </h2>

    <ng-content select="[paymentTimeBlock]"></ng-content>

    <p
      class="text-gray-white text-h3-360 md:text-h3-subheading w-full px-4 md:px-0 md:w-[410px] xl:w-170">
      {{ text }}
    </p>

    <ng-content></ng-content>
  </div>
</div>
