<div *ngFor="let program of aboutUsOurProgramCards">
  <div class="w-295 mx-auto mb-5 group/arrow">
    <div
      class="flex flex-row border-2 border-gray-stroke rounded-30 hover:border-secondary-base cursor-pointer">
      <img class="rounded-l-30" [src]="program.src" />
      <div class="flex flex-col justify-between p-10">
        <h3 class="text-h3-subheading text-gray-dark pb-5">
          {{ program.title }}
        </h3>
        <p class="text-main-text text-gray-dark pb-5">
          {{ program.text }}
        </p>
        <div class="w-27.5">
          <app-link-button
            [linkButtonType]="'RightArrow'"
            [title]="'Перейти'"
            routerLink="./"></app-link-button>
        </div>
      </div>
    </div>
  </div>
</div>
