import { Component, OnInit } from '@angular/core';
import IAboutUsOurProgram from 'src/app/models/aboutUsOurProgram.interface';
import { aboutUsOurProgramConfig } from 'src/mockData/aboutUsOurProgramConfig';

@Component({
  selector: 'app-wide-card',
  templateUrl: './wide-card.component.html',
  styleUrls: ['./wide-card.component.css'],
})
export class WideCardComponent implements OnInit {
  constructor() {}
  protected aboutUsOurProgramCards: IAboutUsOurProgram[] =
    aboutUsOurProgramConfig;

  ngOnInit(): void {}
}
