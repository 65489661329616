import IGlossary from 'src/app/models/glossary.interface';

export const glossaryMockData: IGlossary[] = [
  {
    title: 'Image 1',
    imgLink: '/assets/images/glossary/images1.png',
  },
  {
    title: 'Image 2',
    imgLink: '/assets/images/glossary/images2.png',
  },
  {
    title: 'Image 3',
    imgLink: '/assets/images/glossary/images3.png',
  },
];
