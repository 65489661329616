import { Component, OnInit } from '@angular/core';
import ILearning from 'src/app/models/learning.interface';
import {
  learningDataMock,
  learningTextDataMock,
  learningInfoDataMock,
} from 'src/mockData/learningDataMock';

@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
  styleUrls: ['./study.component.css'],
})
export class StudyComponent implements OnInit {
  learningText: ILearning[] = learningTextDataMock;
  learning: ILearning[] = learningDataMock;
  learningInfo: ILearning[] = learningInfoDataMock;
  isHovered: boolean = false;

  constructor() {
    this.links = [
      {
        id: 'SchoolOfCare',
        title: '"Школа турботи"',
      },
      {
        id: 'Webinars',
        title: 'Вебінари',
      },
      {
        id: 'TrainingForService',
        title: 'Tренінги для надавачів послуг',
      },
      {
        id: 'UsefulMaterials',
        title: 'Корисні матеріали',
      },
    ];
  }

  protected links: { readonly id: string; readonly title: string }[] = [];

  ngOnInit(): void {}
}
