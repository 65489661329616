import { Component, OnInit, Input } from '@angular/core';
import { BreakpointObserverService } from 'src/app/services/breakpoint-observer.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  isMobile$ = this.breakpoinService.isMobile();
  isTablet$ = this.breakpoinService.isTablet();
  isLaptop$ = this.breakpoinService.isLaptop();
  constructor(private breakpoinService: BreakpointObserverService) {}
  @Input() headerButtonVisible: Boolean = true;

  ngOnInit(): void {}
  functionCall1(event: any) {
    console.log('functionCall', event);
  }
  functionCall2(event: any) {
    console.log('functionCall', event);
  }
}
