import { IHelpCard } from 'src/app/models/help-card.interface';

export const CardHelpVolunteerArray: IHelpCard[] = [
  {
    imageLink: './assets/images/cardvolonteer/take_part.png',
    text: 'Прийняти участь у дослідженні',
    route: '/about-us/our-programs/research',
  },
  {
    imageLink: './assets/images/help block/volunteer.png',
    text: 'Стати волонтером на майбутні заходи',
    route: '/join/become-volunteer',
  },
  {
    imageLink: './assets/images/cardvolonteer/monthly_subscribe.png',
    text: 'Щомісячна підписка',
    route: '/join/donate',
    queryParams: { type: 'monthly' },
  },
];
