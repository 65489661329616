<app-page-banner-item
  text="Ми Незабутні – спеціалізована благодійна організація допомоги людям із деменцією. Ми є піклуючою організацією та маємо дуже віддану та доброзичливу робочу силу. "
  title="Вакансії"
  [imgUrl]="'assets/images/banner/banner-vacancy.png'"></app-page-banner-item>

<section class="content-container pt-30 text-gray-dark" id="WhyDiagnosisNeed">
  <p class="text-main-text">
    Приєднавшись до Незабутні, ви станете частиною різноманітної та відданої
    команди людей, де вас підтримуватимуть і заохочуватимуть не лише
    використовувати, але й розвивати свої навички. У DНезабутні ми цінуємо наших
    людей, тому для нас важливо створити робоче середовище, яке піклується про
    нашу робочу силу, щоб вони могли повністю реалізувати свій потенціал.
  </p>
</section>

<section
  class="content-container mt-[40px] text-gray-dark"
  id="WhyDiagnosisNeed">
  <app-accordion
    [options]="{ multi: true }"
    [vacancy]="true"
    [AccordionsList]="menuItems"></app-accordion>
</section>
<app-help-block [type]="'primary'" class="pt-30 block"></app-help-block>
