import IInfoPage from 'src/app/models/infoPage.interface';

export const mediaCentreCardsConfig: IInfoPage[] = [
  {
    title: 'Як говорити про деменцію у медіа',
    text: 'Медіа відіграє надзвичайно важливу роль у розповсюдженні ідей та створенні відповідних...',
    link: '/about-us/news-and-media/media-center/how-to-talk-about-dementia-in-the-media',
  },
  {
    title: 'Ключові факти про деменцію',
    text: 'Деменція — це синдром, що вражає мозок та призводить до стійкого та прогресивного порушення його роботи.',
    link: '/about-us/news-and-media/media-center/key-facts-about-dementia',
  },
  {
    title: 'Глосарій рекомендованих термінів',
    text: 'Це список термінів, які варто або не варто використовувати, говорячи про деменцію',
    link: '/about-us/news-and-media/media-center/glossary',
  },
  {
    title: 'Пресрелізи',
    text: '“Незабутні” – це перша в Україні організація, яка спеціалізується виключно на питаннях деменції.',
    link: '/about-us/news-and-media/media-center/press-releases',
  },
];
