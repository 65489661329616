<div class="flex flex-col content-container py-30">
  <div
    class="h-32.5 md:h-12 flex flex-col md:flex-row px-4 md:px-0 items-start justify-between md:items-center xl:items-end mb-10">
    <h2 class="text-h1-heading text-primary-base uppercase">
      Новини та історії
    </h2>
    <div class="w-60 group/arrow">
      <app-link-button
        [linkButtonType]="'RightArrow'"
        [title]="'Переглянути всі новини'"
        routerLink="./about-us/news-and-media/news"></app-link-button>
    </div>
  </div>
  <app-adaptive-slider>
    <div
      class="flex justify-between items-center content-container mb-5 md:mb-10 xl:mb-0 gap-5">
      <app-card
        *ngFor="let newsCard of newsCards | slice : 0 : 3"
        [newsCard]="newsCard"></app-card>
    </div>
  </app-adaptive-slider>
</div>
