<div
  class="w-full z-0 h-205 flex bg-no-repeat bg-cover bg-center xl:pb-20"
  [ngStyle]="{ 'background-image': bgImage }">
  <div class="w-full my-15 flex justify-end">
    <div class="bg-gray-white/[0.6] w-187.5 rounded-30 flex justify-start">
      <div class="py-15 pl-10 opacity-100 text-main-text flex flex-col">
        <div [innerHTML]="needHelpPageData.bannerText"></div>
        <div class="mt-4 w-70" routerLink="/about-us/about-fund/contacts">
          <app-button
            [buttonType]="'Primary'"
            [title]="'Звернутись за допомогою'"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="py-30 w-full xl:w-295 mx-auto">
  <app-info-cards [cards]="needHelpPageData.firstBlock"></app-info-cards>
</div>

<div class="py-30 w-full bg-gray-card">
  <div class="w-full xl:w-295 mx-auto">
    <div class="mb-10 pl-10 xl:px-0">
      <h2 class="text-h1-heading text-primary-base uppercase mb-5">
        {{ needHelpPageData.secondBlockTitle.title }}
      </h2>
      <div [innerHTML]="needHelpPageData.secondBlockTitle.text"></div>
    </div>
    <app-info-cards [cards]="needHelpPageData.secondBlock"></app-info-cards>
  </div>
</div>

<div class="w-full bg-primary-base py-30" id="whatToDoIf">
  <div class="w-full xl:w-295 mx-auto px-2 md:pl-10 xl:px-0">
    <div
      class="flex flex-col md:flex-row justify-between mb-10 pl-10 xl:px-0 text-gray-white">
      <h3
        class="w-full xl:w-95 text-h3-subheading md:text-h1-heading uppercase">
        {{ needHelpPageData.tabsBlockTitle.title }}
      </h3>
      <p
        class="w-full xl:w-195"
        [innerHTML]="needHelpPageData.tabsBlockTitle.text"></p>
    </div>
    <app-tabs-horizontal
      [tabsArray]="needHelpPageData.needHelpDataTab"></app-tabs-horizontal>
  </div>
</div>
<div
  class="w-full xl:w-245 mx-auto text-center py-30 flex flex-col justify-center items-center">
  <h2 class="text-h2-heading text-primary-base uppercase mb-5">
    {{ needHelpPageData.lastBlock.title }}
  </h2>
  <div
    class="wide-container"
    [innerHTML]="needHelpPageData.lastBlock.text"></div>
  <div class="w-70 mt-10" routerLink="/about-us/about-fund/contacts">
    <app-button [title]="'Звернутись за допомогою'"></app-button>
  </div>
</div>
