import { Component, OnInit } from '@angular/core';
import IFundMember from 'src/app/models/fundMember.interface';
import IVolunteer from 'src/app/models/volunteer.interface';
import { fundMembersMock } from 'src/mockData/fundMembersMock';
import { volunteersMock } from 'src/mockData/volunteersMock';

type Volunteer = {
  title: string;
  text: string;
  imgUrl: string;
};
@Component({
  selector: 'app-fund-team',
  templateUrl: './fund-team.component.html',
  styleUrls: ['./fund-team.component.css'],
})
export class FundTeamComponent implements OnInit {
  isSeeMoreButtonVisible: boolean = false;
  showedVolunteers: Volunteer[] = [];
  showedVolunteerNumber = 3;
  fundMembers: IFundMember[] = fundMembersMock;

  volunteers: IVolunteer[] = volunteersMock;
  constructor() {}

  ngOnInit(): void {
    if (this.volunteers.length > this.showedVolunteerNumber) {
      this.showedVolunteers = this.volunteers.slice(
        0,
        this.showedVolunteerNumber,
      );
      this.isSeeMoreButtonVisible = true;
    } else {
      this.showedVolunteers = this.volunteers;
    }
  }

  seeMore() {
    this.showedVolunteers = this.volunteers;
    this.isSeeMoreButtonVisible = false;
  }
}
