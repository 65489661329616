import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
})
export class SliderComponent implements OnInit {
  @Input()
  SlidesArray!: any;
  @Input()
  slide: boolean = false;

  typeCard!: boolean;
  widthCard!: number;
  totalCards!: number;
  currentPage: number = 1;
  pagePosition: string = '0%';
  cardsPerPage!: number;
  totalPages!: number;
  overflowWidth!: string;
  cardWidth!: string;

  @Input()
  title!: string;
  @ViewChild('container', { static: true, read: ElementRef })
  container!: ElementRef;
  @HostListener('window:resize') windowResize() {
    let newCardsPerPage = this.getCardsPerPage();
    if (newCardsPerPage != this.cardsPerPage) {
      this.cardsPerPage = newCardsPerPage;
      if (this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
        this.populatePagePosition();
      }
    }
  }

  ngOnInit() {
    if (this.SlidesArray[0][0] === 'main') {
      this.typeCard = true;
      this.widthCard = 580;
    }
    if (this.SlidesArray[0].type === 'alternative') {
      this.typeCard = false;
      this.widthCard = 380;
    }
    this.totalPages = this.SlidesArray.length - 1;
    this.cardsPerPage = this.getCardsPerPage();
  }

  getCardsPerPage() {
    return Math.floor(
      this.container.nativeElement.offsetWidth / this.widthCard,
    );
  }

  changePage(incrementor: number) {
    this.currentPage += incrementor;
    this.populatePagePosition();
  }

  populatePagePosition() {
    this.pagePosition = `calc(${-50 * (this.currentPage - 1)}% - ${
      5 * (this.currentPage - 1)
    }px)`;
  }
}
