import { TabsHorizontal } from 'src/app/models/tabs-horizontal';

export const TabDataMainPageMock: TabsHorizontal[] = [
  {
    title: '5 фактів про деменцію',
    text:
      '<div><p class="mb-7">Деменція – це синдром, у якому відбувається деградація когнітивної функції (тобто здатності мислити) більшою мірою, ніж очікується при нормальному старінні. Відбувається деградація пам\'яті, мислення, розуміння, мовлення та здатності орієнтуватися, рахувати, пізнавати та розмірковувати. Деменція не впливає на свідомість. Порушення когнітивної функції часто супроводжується, інколи ж передує погіршенням контролю за емоційним станом.\n' +
      'Деменція не впливає на свідомість. </p></div>',
    linkTo: '/about-dementia/info/five-things-about-dementia-to-know',
  },
  {
    title: 'Причини виникнення',
    text:
      '<div><p class="mb-7">Встановлення діагнозу деменції може бути шоком, який супроводжується страхом та незрозумінням. Але важливо пам\'ятати, що ви не самі, і існують різні ресурси та спеціалісти, щоб допомогти вам.</p>\n' +
      "  <p>Пам'ятайте, що важливо брати до уваги свої потреби та потреби вашого родича чи пацієнта з деменцією, і допомагати їм жити повноцінним життям, як це можливо.</p></div>",
    linkTo: '/about-dementia/info/what-dementia-is',
  },
  {
    title: 'Симптоми',
    text: '<div><p class="mb-7">Якщо у вашого родича діагностовано деменцію, то важливо надавати їм додаткову підтримку та догляд.</p></div>',
    linkTo: '/about-dementia/info/symptoms',
  },
  {
    title: 'Отримання діагнозу',
    text:
      '<div><p class="mb-7">Якщо у вашого родича діагностовано деменцію, то важливо надавати їм додаткову підтримку та догляд.</p>\n' +
      '<p>Також, не забувайте про свій власний догляд та підтримку, зокрема звертайтеся до спеціалістів, якщо вам здається, що ви не можете впоратися самі з доглядом за родичем з деменцією.</p>\n</div>',
    linkTo: '/about-dementia/diagnosis',
  },
];
