<div class="content-container mb-8">
  <div class="flex text-main-text text-dark relative">
    <input type="text" class="hidden" id="select" value="{{ choosedElem }}" />
    <div class="flex relative h-10">
      <div
        class="flex flex-col border-2 border-gray-stroke rounded-10 z-10 absolute overflow-hidden">
        <div
          for="select"
          class="flex flex-row border-gray-stroke py-2 px-5 cursor-pointer w-74 text-gray-details"
          (click)="openDropDown()">
          <!--          <app-iconfilter class="mr-3 flex items-center"></app-iconfilter>-->

          <h3 class="w-full">
            {{ choosedElem }}
          </h3>
          <app-downcheck
            class="flex items-center transition-all duration-500"
            [ngClass]="{
              'rotate-180': showList
            }"></app-downcheck>
        </div>

        <ng-container *ngIf="showList">
          <div
            class="flex flex-row border-t-2 bg-white border-gray-stroke cursor-pointer hover:text-secondary-base py-2 px-5"
            [@fadeInOut]
            *ngFor="
              let opt of ListArray | slice : 1 : ListArray.length;
              let i = index
            "
            (click)="changeValue(opt)">
            {{ opt }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
