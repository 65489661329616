import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AgreementConfig } from 'src/mockData/agreement-docs-config';

@Injectable({
  providedIn: 'root',
})
export class OpenPdf {
  pdf: any;
  constructor(
    private config: AgreementConfig,
    private sanitazer: DomSanitizer,
  ) {}

  onOpenOferta(): void {
    this.config.getPublicOffer().subscribe((basePdf: any) => {
      let objectURL = 'data:application/pdf;base64,' + basePdf.pdf;
      this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(objectURL);

      const iframe = document.createElement('iframe');
      iframe.src = objectURL;
      iframe.width = '100%';
      iframe.height = '100%';
      iframe.style.border = 'none';

      const newWindow = window.open('', '_blank');
      newWindow!.document.body.appendChild(iframe);
      newWindow!.document.title = 'Public Offer';
    });
  }
  onOpenTermsAndConditions(): void {
    this.config.getTermsAndConditions().subscribe((basePdf: any) => {
      let objectURL = 'data:application/pdf;base64,' + basePdf.pdf;
      this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(objectURL);

      const iframe = document.createElement('iframe');
      iframe.src = objectURL;
      iframe.width = '100%';
      iframe.height = '100%';
      iframe.style.border = 'none';

      const newWindow = window.open('', '_blank');
      newWindow!.document.body.appendChild(iframe);
      newWindow!.document.title = 'Terms And Conditions';
    });
  }
  onOpenFundPolicy(): void {
    this.config.getFundPolicy().subscribe((basePdf: any) => {
      let objectURL = 'data:application/pdf;base64,' + basePdf.pdf;
      this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(objectURL);

      const iframe = document.createElement('iframe');
      iframe.src = objectURL;
      iframe.width = '100%';
      iframe.height = '100%';
      iframe.style.border = 'none';

      const newWindow = window.open('', '_blank');
      newWindow!.document.body.appendChild(iframe);
      newWindow!.document.title = 'FundPolicy';
    });
  }

  onOpenTestFinancialReport(): void {
    this.config.getTestFinancialReport().subscribe((basePdf: any) => {
      let objectURL = 'data:application/pdf;base64,' + basePdf.pdf;
      this.pdf = this.sanitazer.bypassSecurityTrustResourceUrl(objectURL);

      const iframe = document.createElement('iframe');
      iframe.src = objectURL;
      iframe.width = '100%';
      iframe.height = '100%';
      iframe.style.border = 'none';

      const newWindow = window.open('', '_blank');
      newWindow!.document.body.appendChild(iframe);
      newWindow!.document.title = 'Terms And Conditions';
    });
  }
}
