import { AbstractControl, ValidationErrors } from '@angular/forms';

function isEmptyInputValue(value: any): boolean {
  /**
   * Check if the object is a string or array before evaluating the length attribute.
   * This avoids falsely rejecting objects that contain a custom length attribute.
   * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
   */
  return (
    value == null ||
    ((typeof value === 'string' || Array.isArray(value)) && value.length === 0)
  );
}
export function nameValidator(
  control: AbstractControl,
): ValidationErrors | null {
  const NAME_REGEXP = /^[0-9A-Za-zА-Яа-яЇїЄєҐґІі'.-\s]+$/;
  if (isEmptyInputValue(control.value)) {
    return null; // don't validate empty values to allow optional controls
  }
  return NAME_REGEXP.test(control.value) ? null : { invalidName: true };
}
