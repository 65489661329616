<app-page-banner-item
  text="Що таке деменція, як поставити діагноз і як зменшити ризик розвитку деменції або відстрочити її вплив"
  title="Команда фонду"
  [imgUrl]="'assets/images/banner/banner-fund-team.jpg'"></app-page-banner-item>

<section class="content-container pt-30 text-gray-dark" id="WhyDiagnosisNeed">
  <h2 class="text-h2-heading text-primary-base uppercase">Про нашу команду</h2>
  <p class="mt-5 text-main-text">
    Деменція – це загальний термін, який використовується для опису ряду
    прогресуючих неврологічних розладів, тобто станів, що впливають на мозок.
    Існує понад 200 підтипів деменції, але п’ять найпоширеніших: хвороба
    Альцгеймера, судинна деменція, деменція з тільцями Леві, лобно-скронева
    деменція та змішана деменція. Деякі люди можуть мати комбінацію різних типів
    деменції, і їх зазвичай називають змішаною деменцією.
  </p>
</section>

<section class="content-container pt-30 text-gray-dark" id="WhyDiagnosisNeed">
  <h2 class="text-h2-heading text-primary-base uppercase">Команда фонду</h2>
  <p class="mt-5 text-main-text">
    Це понад 30 співробітників, яких об’єднала спільна мрія: допомогти людям із
    деменцією по всьому світу.
  </p>
  <p class="mt-7 text-main-text">
    Ми працюємо, щоб кожна родина, в якій родич хворіє на деменцію, мала
    підтримку на всьому шляху життя із синдромом. Щоб благодійність в Україні
    ставала нормою. Щоб супроводження деменції відбувалось в Україні за
    світовими стандартами. І щоб кожна людина мала повноцінне життя.
  </p>
</section>

<section class="pt-15">
  <div
    class="grid grid-cols-[380px_380px_380px] place-content-center gap-5 mb-15">
    <app-fund-member-card-item
      *ngFor="let member of fundMembers"
      text="{{ member.position }}"
      title="{{ member.title }}"
      [imgUrl]="member.img"
      [url]="
        '/about-us/our-people/fund-team/' + member.memberID
      "></app-fund-member-card-item>
  </div>
</section>
<section class="content-container pt-15 text-gray-dark" id="WhyDiagnosisNeed">
  <h2 class="text-h2-heading text-primary-base uppercase">Волонтери фонду</h2>
  <p class="mt-5 text-main-text">
    Досягти змін у суспільстві і створити дружнє до деменції середовище простіше
    разом. Тож давате об’єднуватись, вчитися, ділитися ідеями та планами! Ми
    дуже хочемо, щоб виникла дружня, ідейна і весела спільнота людей, які разом
    змінюють цей світ на краще!
  </p>
</section>
<section class="pt-15">
  <div class="grid grid-cols-[380px_380px_380px] place-content-center gap-5">
    <app-volunteer-card-item
      *ngFor="let member of showedVolunteers"
      text="{{ member.text }}"
      title="{{ member.title }}"
      [imgUrl]="member.imgUrl"></app-volunteer-card-item>
  </div>
</section>
<section
  class="content-container pt-15 pb-30 text-gray-dark flex gap-5 justify-center"
  id="WhyDiagnosisNeed">
  <app-button
    *ngIf="isSeeMoreButtonVisible"
    title="Дивитись більше"
    buttonType="Secondary"
    class="w-55"
    (click)="seeMore()"></app-button>

  <app-link-button
    class="w-55"
    [title]="'Стати волонтером'"
    [linkButtonType]="'Primary'"
    routerLink="/join/become-volunteer">
  </app-link-button>
</section>
