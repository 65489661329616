<div class="block py-10 content-container rounded-30 bg-gray-card">
  <h2 class="pb-5 uppercase px-4 md:pl-25 text-h2-heading text-primary-base">
    Зміст сторінки
  </h2>
  <ul class="flex flex-col gap-5 px-4 md:pl-25">
    <li *ngFor="let link of links">
      <a
        appScrollToSection
        scrollToSection="{{ link.id }}"
        class="underline underline-offset-4 cursor-fancy cursor-pointer text-gray-dark text-main-text hover:text-secondary-additional hover:no-underline duration-300"
        >{{ link.title }}</a
      >
    </li>
  </ul>
</div>
