<main>
  <section>
    <app-page-banner-item
      [imgUrl]="'assets/images/media_centre/banner.jpg'"
      [text]="'Бажаєте звʼязатись з нами? Напишіть нам на пошту.'"
      [title]="'Медіацентр'">
      <div
        (click)="changeIcon()"
        cdkCopyToClipboard="{{ textValue }}"
        class="flex gap-1 shrink-0 mt-5 w-70 h-11 py-2 px-9 bg-gray-white rounded-30 cursor-pointer group">
        <ng-container *ngIf="!isCopy">
          <button
            aria-label="copyEmail"
            class="w-6 h-6 md:w-7 md:h-7 duration-300">
            <svg
              class="fill-accent-base group-hover:fill-secondary-base group-active:fill-secondary-additional w-full h-full duration-300"
              height="24px"
              icon="copy"
              iconCollection="button-icons-sprite"
              width="20px"></svg>
          </button>
        </ng-container>
        <ng-container *ngIf="isCopy">
          <button
            aria-label="copyEmail"
            class="w-6 h-6 md:w-7 md:h-7 relative duration-300">
            <svg
              class="fill-secondary-base w-full h-full duration-300"
              height="24px"
              icon="checkBox"
              iconCollection="button-icons-sprite"
              width="20px"></svg>
          </button>
          <div
            class="bg-gray-white absolute -translate-x-10 translate-y-8 text-gray-medium w-28.5 h-6 rounded-30 text-details flex justify-center items-center z-10"
            id="pop-over">
            Скопійовано
          </div>
        </ng-container>
        <a
          class="md:pointer-events-none pt-0.5 text-accent-base duration-300 group-hover:text-secondary-base group-active:text-secondary-additional"
          href="mailto:info@nezabutni.org"
          >info@nezabutni.org</a
        >
      </div>
    </app-page-banner-item>
  </section>

  <section class="mt-30 mb-29.5 content-container">
    <div [innerHTML]="mediaCenterText"></div>

    <div class="mt-15">
      <app-info-cards [cards]="mediaCentreCards"></app-info-cards>
    </div>
  </section>

  <section>
    <app-subscribe-news></app-subscribe-news>
  </section>
</main>
