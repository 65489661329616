import moreIInfo from 'src/app/models/moreInfo.interface';
export const medicalAssistanceCard: moreIInfo[] = [
  {
    header: 'Психологічна підтримка',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду різних станів.',
    img: './assets/images/moreInfo/nurse.jpg',
    link: '/get-support/psychological-support',
  },
  {
    header: 'Юридична допомога',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду...',
    img: './assets/images/moreInfo/old_man.jpg',
    link: '/get-support/legal-assistance',
  },
  {
    header: 'Допомога з ліками',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду різних станів.',
    img: './assets/images/moreInfo/pills.jpg',
    link: '/get-support/help-with-medication',
  },
];
