import IInfoPage from 'src/app/models/infoPage.interface';

export const keyDementiaCardsConfig: IInfoPage[] = [
  {
    title: 'Як говорити про деменцію у медіа',
    text: 'Медіа відіграє надзвичайно важливу роль у розповсюдженні ідей та створенні відповідних...',
    link: '/about-us/news-and-media/media-center/how-to-talk-about-dementia-in-the-media',
  },

  {
    title: 'Глосарій рекомендованих термінів',
    text: 'Це список термінів, які варто або не варто використовувати, говорячи про деменцію',
    link: '/about-us/news-and-media/media-center/glossary',
  },
];
