import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsApiService } from 'src/app/services/api/aboutUsPages/NewsAndMedia/news-api.service';
import { Subscription } from 'rxjs';
import newsCardInfo from 'src/app/models/newsCard.interface';
import { newsCardConfig } from 'src/mockData/newsCardConfig';

@Component({
  selector: 'app-detailed-news',
  templateUrl: './detailed-news.component.html',
  styleUrls: ['./detailed-news.component.css'],
})
export class DetailedNewsComponent implements OnInit, OnDestroy {
  detailedNews!: newsCardInfo;
  newsId!: string;
  subscript!: Subscription;
  constructor(
    private route: ActivatedRoute,
    private newsService: NewsApiService,
  ) {
    this.newsId = this.route.snapshot.params['id'];
  }
  ngOnInit(): void {
    this.subscript = this.newsService.getNewsById(this.newsId).subscribe(el => {
      if (el === null) {
        this.detailedNews = newsCardConfig[0];
      } else {
        this.detailedNews = el;
      }
    });
  }

  public ngOnDestroy() {
    this.subscript.unsubscribe();
  }
}
