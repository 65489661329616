import { Component, OnInit } from '@angular/core';
import { becomePartnerDataMock } from 'src/mockData/becomePartnerDataMock';

@Component({
  selector: 'app-become-partner',
  templateUrl: './become-partner.component.html',
  styleUrls: ['./become-partner.component.css'],
})
export class BecomePartnerComponent implements OnInit {
  becomePatnerData = becomePartnerDataMock;
  constructor() {}

  ngOnInit(): void {}
  copyProps(data: string) {
    navigator.clipboard.writeText(data).then(() => {
      console.log(`Text ${data} was copied!`);
      //todo вспливаючі підказки
      alert(`Text ${data} was copied!`);
    });
  }
}
