<div>
  <div
    class="w-10 h-10 ml-2 md:ml-0 py-0 md:py-[7.5px] transition-all duration-500 relative"
    (click)="openClose()">
    <div
      class="w-full h-[2px] md:h-[2.5px] rounded-[2px] bg-accent-base transition-all duration-200 delay-100 absolute top-[7.5px] overflow-hidden"
      [ngClass]="{
        'top-[18px] rotate-45 w-10 translate-y-[2px] origin-center':
          !isHamburguer
      }"></div>
    <div
      class="w-full h-[2.5px] rounded-[2px] bg-accent-base absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-500 delay-200"
      [ngClass]="{ hidden: !isHamburguer, flex: isHamburguer }"></div>
    <div
      class="w-full h-[2.5px] rounded-[2px] bg-accent-base transition-all duration-200 delay-100 absolute bottom-[7.5px]"
      [ngClass]="{ 'bottom-[18px] -rotate-45 w-10': !isHamburguer }"></div>
  </div>
  <nav
    class="w-full top-15 md:top-18 left-0 px-1 sm:px-15 pt-10 pb-20 fixed bg-gray-white h-full transition-opacity opacity-0 duration-500 delay-100 overflow-y-scroll flex flex-col justify-between"
    [ngClass]="{
      'opacity-100': !isHamburguer,
      hidden: isHamburguer
    }">
    <app-accordion
      [options]="{ multi: false }"
      [mobile]="false"
      [mobileMenu]="true"
      [AccordionsList]="menuItems"
      (accordionToggle)="openClose()"></app-accordion>
    <ul
      class="flex gap-y-4.5 gap-x-5 mb-20 flex-wrap justify-start md:justify-start w-full">
      <li *ngFor="let socialLink of socialLink">
        <app-social-link
          class="inline-block w-12 h-12 shrink-0"
          [socialLink]="socialLink"></app-social-link>
      </li>
    </ul>
  </nav>
</div>
