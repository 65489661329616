<app-page-banner-item
  [imgUrl]="'assets/images/banner/banner-help-with-medicine.jpg'"
  title="Допомога з ліками"
  text="На цій сторінці ви знайдете інформацію, як отримати ліки для людини з деменцією або для закладу, де надаються послуги людям з деменцію та людям літнього віку">
</app-page-banner-item>

<section class="content-container text-start flex mt-30 px-4 md:px-0">
  <p class="text-main-text text-gray-dark">
    З 2022 року фонд Незабутні надає безкоштовні ліки при деменції та різних
    захворюваннях. Ви можете замовити знеболюючі, антипсихотичні, неврологічні,
    антидіабетичні, дерматологічні, антибактеріальні та інші препарати з нашого
    переліку. Перелік ліків постійно оновлюється в залежності від наявності
    препаратів на складі та нових надходжень. Ліки можна забрати самостійно в
    Інституті геронтології ім. Д.Ф.Чеботарьова у Києві, або отримати поштою.
    Гортайте нижче, щоб дізнатися умови замовлення.
  </p>
</section>

<section class="grid mt-30">
  <app-table-of-content
    class="flex mx-auto w-full px-4 md:px-0"
    [links]="links"></app-table-of-content>
</section>

<section class="content-container">
  <div class="mt-30" *ngFor="let page of medicine">
    <h2
      id="MedicineForPersonWithDementia"
      class="text-h2-heading text-primary-base uppercase mb-5"
      [innerHTML]="page.title"></h2>
    <ol
      class="list-decimal pl-7 flex flex-col gap-2.5 mb-10"
      [innerHTML]="page.text"></ol>
    <app-link-button
      [routerLink]="'/about-us/about-fund/contacts'"
      class="w-55 flex"
      [title]="'Залишити заявку'"
      [linkButtonType]="'Primary'"
      [isHovered]="isHovered">
    </app-link-button>
    <app-carousel class="mt-15 flex" (changeSlide)="onChangeSlide($event)">
      <app-carousel-slide *ngFor="let ListOfMedicine of arrayListOfMedicine">
        <img class="rounded-3xl max-w-none" src="{{ ListOfMedicine.image }}" />
      </app-carousel-slide>
    </app-carousel>
  </div>

  <div class="mt-32" *ngFor="let page of institution">
    <h2
      id="MedicineForTheInstitution?"
      class="text-h2-heading text-primary-base uppercase">
      {{ page.title }}
    </h2>
    <p class="mt-5">{{ page.info }}</p>
    <div class="bg-gray-card rounded-30 p-10 mt-15">
      <ol
        class="list-decimal pl-7 flex flex-col gap-2.5 mb-10"
        [innerHTML]="page.text"></ol>
      <app-link-button
        url="/about-us/about-fund/reports"
        [linkButtonType]="'Primary'"
        [title]="'Залишити заявку'"
        class="w-55 flex">
      </app-link-button>
    </div>
  </div>

  <div class="mt-30" *ngFor="let page of project">
    <h3 id="MoreAboutTheProject" [innerHTML]="page.title"></h3>
    <ol [innerHTML]="page.text"></ol>
  </div>

  <app-image-slider></app-image-slider>

  <p class="text-h3-subheading text-primary-base mt-30 mb-10">
    Перегляньте відео про проєкт “Допомога з ліками”
  </p>

  <div class="div-round">
    <youtube-player
      videoId="QZAH7F4QHVM"
      suggestedQuality="highres"
      [height]="665"
      [width]="1180"
      [startSeconds]="4"
      [endSeconds]="8">
    </youtube-player>
  </div>
</section>
<div class="py-30 bg-primary-base">
  <app-info-cards [cards]="cards"></app-info-cards>
</div>
