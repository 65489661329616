<div class="container flex flex-col items-center mx-auto mb-2">
  <div class="flex items-start gap-5 mb-20">
    <h3 class="text-primary-base text-h1-heading w-95">
      {{ title | uppercase }}
    </h3>
    <p class="w-195 text-gray-dark text-main">
      {{ textInfo }}
    </p>
  </div>
</div>
