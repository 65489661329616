<section class="py-30 relative">
  <div
    class="flex justify-start xl:justify-between flex-wrap gap-5 bg-gray-card rounded-t-30 px-4 md:px-7.5 xl:px-25 py-10 md:pt-10 xl:pt-20 md:pb-15 mx-2 md:mx-7.5 xl:mx-auto xl:w-295">
    <h2
      class="w-70 text-primary-base text-h2-heading md:text-h1-heading uppercase">
      Про ФОНД
    </h2>

    <p class="xl:w-170 text-gray-dark text-smaller-main md:text-main-text">
      Фонд “Незабутні” був створений волонтерами в 2021 році, і з того часу став
      найбільшою організацією в Україні, яка допомагає людям з деменцією та
      бореться за їх права. Фонд “Незабутні” є членом двох міжнародних
      організацій - Alzheimer’s Disease International та Alzheimer Europe.
    </p>
  </div>
  <div class="bg-about3 bg-contain bg-center bg-no-repeat xl:max-w-[100%]">
    <img
      src="/assets/images/about block/about_image@360.jpg"
      alt="Про нас"
      class="md:hidden mx-auto w-full" />
    <img
      src="/assets/images/about block/about_image.jpg"
      alt="Про нас"
      class="hidden md:block" />
  </div>
  <div
    class="w-full xl:content-container my-0 mx-auto flex flex-col justify-between">
    <div
      class="bg-gray-card rounded-b-30 pt-10 md:pt-15 pb-4 md:pb-10 xl:pb-20 px-4 md:px-7.5 xl:px-25 mx-2 md:mx-7.5 xl:mx-0">
      <div class="flex">
        <span
          class="mr-2.5 text-main-text-bold md:text-h3-subheading text-primary-base whitespace-nowrap">
          Місія
        </span>
        <span class="text-smaller-main xl:text-main-text text-gray-dark mr-2.5">
          –
        </span>
        <p class="text-gray-dark text-smaller-main md:text-main-text">
          надавати допомогу та підтримку людям з деменцією та їх родичам,
          підвищувати обізнаність та об'єднувати зусилля всіх зацікавлених
          сторін задля створення суспільства, дружнього до людей з деменцією.
        </p>
      </div>
      <div class="flex mt-10">
        <span
          class="mr-2.5 text-main-text-bold md:text-h3-subheading text-primary-base">
          Візія
        </span>
        <span class="text-main-text text-gray-dark mr-2.5"> – </span>
        <p class="text-gray-dark text-smaller-main md:text-main-text">
          люди з деменцією та їх родичі живуть наповненим, комфортним життям,
          сповненим сенсів.
        </p>
      </div>
    </div>
  </div>
</section>
