<div class="flex flex-row justify-between mb-10 items-start">
  <h3 class="text-h2-heading text-primary-base w-145">
    {{ title | uppercase }}
  </h3>
  <div
    *ngIf="slide"
    class="arrow-block flex flex-row justify-between items-start w-34">
    <button
      type="button"
      class="text-accent-base cursor-pointer mt-[-6px] hover:text-secondary-base active:text-secondary-additional disabled:text-gray-gray2 disabled:cursor-not-allowed"
      *ngIf="totalPages > 1"
      (click)="currentPage !== 1 && changePage(-1)"
      [disabled]="currentPage === 1">
      <svg
        class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional"
        [icon]="'left-arrow'"
        iconCollection="button-icons-sprite"
        width="48px"
        height="48px"></svg>
    </button>
    <button
      type="button"
      class="text-accent-base cursor-pointer mt-[-6px] hover:text-secondary-base active:text-secondary-additional disabled:text-gray-gray2 disabled:cursor-not-allowed"
      *ngIf="totalPages > 1"
      (click)="currentPage !== totalPages && changePage(+1)"
      [disabled]="currentPage === totalPages">
      <svg
        class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional"
        [icon]="'right-arrow'"
        iconCollection="button-icons-sprite"
        width="48px"
        height="48px"></svg>
    </button>
  </div>
</div>
<div #container>
  <div class="overflow-hidden">
    <div
      class="flex flex-row relative transition-[left] duration-500 ease-in-out"
      [ngStyle]="{ width: overflowWidth, left: pagePosition }">
      <ng-container *ngFor="let i of SlidesArray; let index = index">
        <app-bills-card *ngIf="typeCard" [card]="i"></app-bills-card>
        <app-card-alternative
          *ngIf="!typeCard"
          [card]="i"></app-card-alternative>
      </ng-container>
      <ng-content></ng-content>
    </div>
  </div>
</div>
