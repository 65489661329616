<div class="w-295 mx-auto">
  <div class="items-center mb-10">
    <h2 class="text-h2-heading text-primary-base uppercase mb-5">
      Партнери Фонду
    </h2>
    <p class="text-main-text text-gray-dark">
      Консультативний комітет Незабутні є національною платформою для людей, які
      живуть з деменцією, щоб спільно з Незабутні виступати з важливих для них
      питань.
    </p>
  </div>
  <div class="grid grid-cols-4 gap-x-5 mb-10">
    <div
      *ngFor="
        let item of sanitizedImages | slice : 0 : showCountOfLogos;
        index as i
      ">
      <div *ngFor="let line of websiteLinks; index as j">
        <div *ngIf="i === j">
          <a href="{{ line.link }}" target="blank">
            <div
              class="w-70 h-70 flex items-center justify-center hover:border-2 rounded-30 border-gray-stroke">
              <img [src]="item.image" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center text-gray-dark gap-5 mb-30">
    <app-button
      *ngIf="showCountOfLogos < sanitizedImages.length"
      (click)="showMore()"
      title="Дивитись більше"
      buttonType="Secondary"
      class="w-55"></app-button>

    <app-link-button
      class="w-55"
      [title]="'Стати партнером'"
      [linkButtonType]="'Primary'"
      routerLink="/">
    </app-link-button>
  </div>
</div>
