import { Component, OnInit } from '@angular/core';
import { IMenuItem, ISubMenu } from 'src/app/models/menu.interface';
import { MenuItemsMock } from 'src/mockData/menuMockData';

@Component({
  selector: 'app-submenu-need-help',
  templateUrl: './submenu-need-help.component.html',
  styleUrls: ['./submenu-need-help.component.css'],
})
export class SubmenuNeedHelpComponent implements OnInit {
  menu: IMenuItem[];
  submenuWithSubMenuItems: ISubMenu[];
  submenuWithoutSubMenuItems: ISubMenu[];
  constructor() {
    this.menu = MenuItemsMock;
    this.submenuWithSubMenuItems = (
      this.menu.find(item => item.link === '/get-support')
        ?.subMenu as ISubMenu[]
    ).filter(item =>
      Object.prototype.hasOwnProperty.call(item, 'subMenuItems'),
    );
    this.submenuWithoutSubMenuItems = (
      this.menu.find(item => item.link === '/get-support')
        ?.subMenu as ISubMenu[]
    ).filter(
      item => !Object.prototype.hasOwnProperty.call(item, 'subMenuItems'),
    );
  }

  ngOnInit(): void {}
}
