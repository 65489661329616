import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import IFundMember from '../models/fundMember.interface';
import { fundMembersMock } from 'src/mockData/fundMembersMock';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbFundMemberResolver implements Resolve<any> {
  isHovered = 'false';
  fundMembers: IFundMember[] = fundMembersMock;
  constructor() {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const memberID = route.paramMap.get('id');
    const filteredMembers = this.fundMembers.filter(
      member => member.memberID === memberID,
    );
    if (filteredMembers.length > 0) {
      return of(filteredMembers[0].title);
    } else {
      return of('');
    }
  }
}
