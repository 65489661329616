<div
  class="wrapper-item w-145 h-173 rounded-30 p-10 odd:mr-4 border-[3px] border-gray-stroke flex flex-col justify-between"
  #cardWidth>
  <div class="h-125">
    <h4 class="text-primary-base text-h2-heading">
      {{ card[1].name | uppercase }}
    </h4>
    <div
      class="flex flex-row w-125 py-5 items-center border-b-2 border-gray-stroke last:border-b-0"
      *ngFor="let el of card | slice : 2 : card.length">
      <div
        class="w-30 text-left text-details text-gray-details"
        *ngIf="el.name">
        {{ el.name }}
      </div>
      <div class="w-80.5 pl-3 text-gray-dark text-left" *ngIf="el.value">
        {{ el.value }}
      </div>
      <div
        *ngIf="el.value && el.name"
        class="cursor-pointer text-accent-base hover:text-secondary-base active:text-secondary-additional"
        title="Скопіювати"
        (click)="copyProps(el.value)">
        <svg
          width="28px"
          height="28px"
          icon="copy"
          iconCollection="button-icons-sprite"
          class="svg fill-accent-base hover:fill-secondary-additional active:fill-secondary-additional"></svg>
      </div>
    </div>
  </div>
  <app-button
    [title]="'Завантажити реквізити'"
    [buttonType]="'Secondary'"
    [svg]="true">
    <svg
      width="24px"
      height="24px"
      icon="download"
      iconCollection="button-icons-sprite"
      class="svg fill-accent-base hover:fill-secondary-additional active:fill-secondary-additional"></svg>
  </app-button>
</div>
