import { Component, OnInit } from '@angular/core';
import IFooter from '../../models/footer.interface';
import { ISocialLink } from 'src/app/models/social-link.interface';
import { socialLinksMockData } from 'src/mockData/socialLinksMockData';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  textValue: string = 'info@nezabutni.org';
  isCopy: boolean = false;

  footerLinks: IFooter[] = [
    {
      text: 'Головна',
      link: '/',
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Звіти та документи',
      link: '/about-us/about-fund/reports',
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Напрями діяльності',
      link: 'about-us/our-programs',
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Стати волонтером',
      link: 'join/become-volunteer',
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Новини',
      link: 'about-us/news-and-media/news',
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Стати партнером',
      link: '/join/become-partner',
      class: 'p-2 hover:text-accent-base',
    },
    {
      text: 'Повідомити про проблему\nз сайтом',
      link: 'about-us/about-fund/contacts',
      class: 'p-2 hover:text-accent-base',
    },
    // {
    //   text: 'Долучитись',
    //   link: '/join',
    //   class: 'p-2 hover:text-accent-base',
    // },
    {
      text: 'Контакти',
      link: 'about-us/about-fund/contacts',
      class: 'p-2 hover:text-accent-base',
    },
  ];
  footerSocialLinks: ISocialLink[] = socialLinksMockData;
  footerLink!: IFooter;
  constructor() {}

  ngOnInit(): void {}

  changeIcon() {
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
    }, 1000);
  }
}
