import { Component, Input, OnInit } from '@angular/core';

import { IHelpCard } from '../../models/help-card.interface';

@Component({
  selector: 'app-help-block',
  templateUrl: './help-block.component.html',
  styleUrls: ['./help-block.component.css'],
})
export class HelpBlockComponent implements OnInit {
  protected helpCards: IHelpCard[];

  @Input()
  public type: string = '';

  protected get BackgroundColorType(): string {
    return this.type === 'primary'
      ? 'bg-primary-base'
      : this.type === 'secondary'
      ? 'bg-gray-white'
      : '';
  }

  protected get HeadingColorType(): string {
    return this.type === 'primary'
      ? 'text-gray-white'
      : this.type === 'secondary'
      ? 'text-primary-base'
      : '';
  }

  protected get ParagraphColorType(): string {
    return this.type === 'primary'
      ? 'text-gray-white'
      : this.type === 'secondary'
      ? 'text-gray-dark'
      : '';
  }

  constructor() {
    this.helpCards = [
      {
        imageLink: '../../../../assets/images/help block/donate.png',
        text: 'Зробити благодійний внесок',
        route: '/join/donate',
      },
      {
        imageLink: '../../../../assets/images/help block/volunteer.png',
        text: 'Стати волонтером фонду',
        route: '/join/become-volunteer',
      },
      {
        imageLink: '../../../../assets/images/help block/partners.png',
        text: 'Стати партнером фонду',
        route: '/join/become-partner',
      },
    ];
  }

  ngOnInit(): void {}
}
