<div class="bg-primary-base flex flex-col items-center mx-auto py-30">
  <div *ngFor="let main of mainText">
    <div class="flex justify-between gap-5 text-white w-295 h-21 mb-10">
      <h2 class="uppercase text-h2-heading w-120">
        {{ main.mainHeader }}
      </h2>
      <p class="text-main-text w-170">
        {{ main.mainContent }}
      </p>
    </div>
  </div>
  <div class="grid grid-cols-[380px_380px_380px] place-content-center gap-5">
    <div *ngFor="let moreInfo of moreInfos">
      <app-more-info-card [moreInfo]="moreInfo"></app-more-info-card>
    </div>
  </div>
</div>
