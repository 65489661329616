<main>
  <section class="grid place-items-center">
    <div class="w-295">
      <div class="flex justify-between mb-20">
        <h1 class="text-h1-heading text-primary-base uppercase h-fit">
          Статистика
        </h1>
        <p class="w-195 text-main-text text-gray-dark h-fit">
          Статистика є потужним інструментом впливу на громадську думку та
          привернення уваги до проблеми деменції на різних рівнях. Тому ми
          ділимось актуальними даними щодо поширеності деменції в Україні та
          світі.
        </p>
      </div>
      <div class="py-10 px-25 bg-gray-low rounded-30 h-fit">
        <h2 class="text-h2-heading text-primary-base uppercase h-fit">
          Зміст сторінки
        </h2>
        <ul class="mt-5 text-main-text text-gray-dark">
          <li>
            <a
              appScrollToSection
              class="border-b-[1px] border-b-gray-dark cursor-pointer hover:text-secondary-additional hover:border-transparent duration-300"
              scrollToSection="Ukraine">
              Статистичні дані про деменцію в Україні
            </a>
          </li>
          <li class="mt-5.5">
            <a
              appScrollToSection
              class="border-b-[1px] border-b-gray-dark cursor-pointer hover:text-secondary-additional hover:border-transparent duration-300"
              scrollToSection="world">
              Статистичні дані про деменцію в світі
            </a>
          </li>
          <li class="mt-5">
            <a
              appScrollToSection
              class="border-b-[1px] border-b-gray-dark cursor-pointer hover:text-secondary-additional hover:border-transparent duration-300"
              scrollToSection="poll">
              Дані опитувань суспільної думки щодо деменції
            </a>
          </li>
        </ul>
      </div>
      <div class="mb-30 pt-30" id="Ukraine">
        <h2
          class="text-h2-heading text-primary-base uppercase w-145 mb-5 h-fit">
          Статистичні дані щодо деменції в Україні
        </h2>
        <div class="text-main-text text-gray-dark h-fit">
          <p class="mb-7">
            Дані офіційної статистики щодо поширеності деменції серед всього
            населення України основані на даних електронної системи охорони
            здоров’я.
          </p>
          <p class="mb-7">
            Станом на вересень 2022 року, в Україні під наглядом перебували
            73504 пацієнтів з діагнозом деменція (в яких згадувалися випадки по
            кодам F00-F06) з яких 67289 пацієнтів проходили амбулаторне
            лікування, 13918 стаціонарне
          </p>
          <p class="mb-7">
            Прогнозований рівень недодіагностики деменції в Україні може
            складати до 94 %.
          </p>
          <p class="mb-7">
            Такий рівень недодіагностування збігається з оцінками Міжнародної
            асоціації Альцгеймера. За їх даними, недодіагностика деменції у
            світі складає близько 75% - 41 млн. З них 60% випадків
            недодіагностики у країнах з високим рівнем доходу, та більше 90% - у
            країнах з низьким та середнім доходом.
          </p>
          <p>
            Також, за даними щодо структури деменцій в Україні можна помітити
            різницю зі світовими трендами: тоді як у світі домінуючою причиною
            деменції є хвороба Альцгеймера, в Україні переважає діагноз «судинна
            деменція» (62,59 %), а хвороба Альцгеймера складає лише 12,0 %. Це
            може бути також пов’язане з недостатнім рівнем діагностики та
            схильністю до більш «широкого» діагнозу.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="py-30 bg-gray-low grid place-items-center" id="world">
    <div class="w-295">
      <h2 class="w-145 text-h2-heading text-primary-base uppercase mb-5 h-fit">
        Статистичні дані про деменцію в світі
      </h2>
      <div class="text-main-text text-gray-dark h-fit">
        <p class="mb-7">
          Сьогодні більше 55 млн. людей у світі живуть з деменцією, при цьому
          щороку реєструють близько 10 млн. нових випадків. Кожні 3 секунди у
          однієї людини в світі починається розвиток деменції.
        </p>
        <p class="mb-7">
          Найбільш поширеною причиною деменції (60-80% випадків у світі) є
          хвороба Альцгеймера. На другому місці за поширеністю васкулярна
          деменція (10-25%) та деменція з тільцями Леві (7-25%). Найчастіше
          зустрічаються змішані форми – хвороба Альцгеймера та супутні
          патології.
        </p>
        <p class="mb-7">
          Деменція на 7 місці у світі серед найбільш поширених причин смерті
          серед всіх захворювань та одна з провідних причин обмеження
          можливостей серед старшого покоління.
        </p>
        <p class="mb-7">
          Найбільшим фактором ризику розвитку деменції є вік: в середньому 1 з 9
          людей (10.7%), старших за 65 років, має хворобу Альцгеймера.
        </p>
        <p class="mb-7">
          Серед пацієнтів будинків людей похилого віку частка людей з деменцією
          становить 39 до 87%.
        </p>
        <p class="mb-7">
          Деменція має значно більший вплив на жінок, ніж на чоловіків. 65% від
          всіх випадків смертей від деменції стаються у жінок; кількість днів
          життя з обмеженими можливостями на 60% більша у жінок, ніж у
          чоловіків. Також, жінки складають переважну більшість (70%)
          піклувальників людей з деменцією.
        </p>
        <p>
          За даними 2019 р. непрофесійні піклувальники (найчастіше – члени
          сім'ї) присвячують піклуванню про людей з деменцією в середньому 5
          годин на день.
        </p>
      </div>
      <div class="mt-5 flex justify-between">
        <div class="w-145 text-main-text text-gray-dark h-fit">
          <p class="mb-7">
            За даними про поширеність деменції в країнах Європи, станом на 2018
            р. відсоток людей з деменцією складав у середньому 1,57% населення
            європейських країн (9,780,678 чол.). Через старіння населення
            передбачають, що цей показник зросте до 1.80% (11,379,701 чол.) у
            2025 році та до 3.00% (18,846,286 чол.) у 2050 році.
          </p>
          <p class="mb-7">
            Згідно прогнозів, найбільш стрімко кількість людей з деменцією
            зростатиме у країнах з низьким та середнім рівнем доходу: у 2050,
            68% усіх людей з деменцією житиме у країнах цієї групи.
          </p>
          <p class="mb-7">
            Прогноз щодо кількості людей з деменцією у світі до 2050 р.
          </p>
        </div>
        <img
          alt="graph"
          src="../../../../../assets/images/statistics/statistics_graph.png" />
      </div>
    </div>
  </section>

  <section class="pt-30 pb-31 grid place-items-center" id="poll">
    <div class="w-295 flex flex-col">
      <h2 class="w-145 text-h2-heading text-primary-base uppercase mb-5 h-fit">
        Дані опитувань суспільної думки щодо деменції
      </h2>
      <div class="mb-15 h-fit">
        <p class="mb-7">
          Згідно опитувань, проведених Міжнародною асоціацією Альцгеймера в 2019
          р.:
        </p>
        <ul class="list-disc block pl-7">
          <li class="mb-2.5">
            80% людей стурбовані можливим розвитком деменції у них самих, при
            цьому 1 з 4 опитаних вважав, що не існує ніяких заходів профілактики
            деменції;
          </li>
          <li class="mb-2.5">
            47% людей з деменцією та піклувальників вважали бар’єром до вчасного
            отримання діагнозу відсутність доступу до досвідчених лікарів, 47% -
            страх діагнозу та стигми;
          </li>
          <li class="mb-2.5">
            33% опитаних медичних працівників у світі вважали, що для лікування
            пацієнтів з деменцією не може бути зроблено нічого;
          </li>
          <li class="mb-2.5">
            35% піклувальників відзначили, що вони приховували діагноз
            «деменція» свого близького принаймні від 1 людини в їх оточенні;
          </li>
          <li class="mb-2.5">
            62% медичних працівників були переконаними, що деменція є складовою
            нормального процесу старіння;
          </li>
          <li class="mb-2.5">
            40 % респондентів відмітили, що на їх думку медичні працівники часто
            ігнорують присутність людини з деменією під час прийому у лікаря
            (спілкуються лише з піклувальником);
          </li>
          <li>
            85% респондентів серед людей з деменцією повідомили, що після
            отримання діагнозу їх думка більше не сприймається оточуючими
            всерйоз.
          </li>
        </ul>
      </div>
      <div class="w-70 self-center mb-29.25">
        <app-link-button
          (click)="downloadPdfFile()"
          [svg]="true"
          [title]="'Завантажити PDF'">
          <svg
            class="svg fill-gray-white mt-1 mr-2"
            height="20px"
            icon="download"
            iconCollection="button-icons-sprite"
            width="20px"></svg>
        </app-link-button>
      </div>
      <app-accordion
        *ngFor="let link of linksOnSourceOfInformation"
        [AccordionsList]="linksOnSourceOfInformation"></app-accordion>
    </div>
  </section>
</main>
