<div *ngIf="cardMonthly" class="mt-10 content-container flex flex-col">
  <div class="card">
    <div class="flex flex-row mb-20">
      <div
        class="flex justify-between rounded-30 w-145 h-145 bg-no-repeat mr-10 overflow-hidden float-right">
        <div
          class="w-full bg-gray-details"
          [ngStyle]="{
            'background-image': 'url(' + cardMonthly.imgUrl + ')'
          }"></div>
      </div>
      <div class="flex flex-col w-140 pt-5">
        <div class="text-primary-base text-h2-heading uppercase pb-5">
          {{ cardMonthly.title }}
        </div>
        <div class="flex flex-row pb-15">
          <div class="w-[269px] mr-10">
            підтримано підопічних фонду<br />
            <span class="text-h1-narrow text-accent-additional">{{
              cardMonthly.helped
            }}</span>
          </div>
          <div class="">
            Надано допомогу на суму<br />
            <span class="text-h1-narrow text-accent-additional">{{
              cardMonthly.summ
            }}</span>
            <span
              class="ml-2 mt-[8px] text-smaller-main text-accent-additional align-bottom"
              >UAH</span
            >
          </div>
        </div>
        <div [innerHTML]="cardMonthly.blockFirst"></div>
      </div>
    </div>
    <div class="" [innerHTML]="cardMonthly.blockSecond"></div>
  </div>
</div>
