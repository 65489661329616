<div class="container">
  <app-information></app-information>
  <app-filter
    [ListArray]="['Всі публікації', 'Відео', 'Статтi']"
    (chosenValue)="changeValueFilter($event)"></app-filter>
  <div class="grid gap-5 grid-cols-2 mb-15">
    <app-products
      *ngFor="
        let productsCard of productsCard
          | paginate : { itemsPerPage: 4, currentPage: p }
      "
      [productsCard]="productsCard"></app-products>
  </div>
  <app-pagination class="mt-20" (pageChange)="p = $event"></app-pagination>
</div>

