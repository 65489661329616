import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crisis-assistance',
  templateUrl: './crisis-assistance.component.html',
  styleUrls: ['./crisis-assistance.component.css'],
})
export class CrisisAssistanceComponent implements OnInit {
  protected links: { readonly id: string; readonly title: string }[] = [];
  arrayListImage: any = [
    { src: '../../../../../assets/images/crisis-assistance/generator.jpg' },
    { src: '../../../../../assets/images/our_idea/our_values.png' },
    { src: '../../../../../assets/images/image/3.jpg' },
  ];

  onChangeSlide(value: string) {
    if (value === 'nextSlide') {
      const deletedElement = this.arrayListImage.shift();
      console.log(deletedElement);
      this.arrayListImage.push(deletedElement);
    }
    if (value === 'prevSlide') {
      const deletedElement = this.arrayListImage.pop();
      this.arrayListImage.unshift(deletedElement);
    }
  }

  constructor() {
    this.links = [
      {
        id: 'help_with_generators',
        title: 'Допомога з генераторами',
      },
      {
        id: 'assistance_with_relocation',
        title: 'Допомога з переміщенням та поселенням',
      },
      {
        id: 'help_abroad',
        title: 'Допомога за кордоном',
      },
    ];
  }

  ngOnInit(): void {}
}
