import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { alternativePaidCard } from 'src/app/models/alternativePaidCard.interface';

@Component({
  selector: 'app-card-alternative',
  templateUrl: './card-alternative.component.html',
  styleUrls: ['./card-alternative.component.css'],
})
export class CardAlternativeComponent implements OnInit {
  @ViewChild('cardWidth', { static: true, read: ElementRef })
  cardWidth!: ElementRef;
  @Input()
  card!: alternativePaidCard;
  constructor() {}

  ngOnInit(): void {}
}
