import { Component, OnInit, Input } from '@angular/core';
import newsCardInfo from 'src/app/models/newsCard.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent implements OnInit {
  @Input() newsCard!: newsCardInfo;
  isHovered: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public navigate(id: number) {
    if (this.newsCard.link === '') {
      this.router.navigate(['/about-us/news-and-media/news/' + id]);
    } else {
      if (this.newsCard.link) {
        this.redirectToOtherSite(this.newsCard.link);
      }
    }
  }

  redirectToOtherSite(link: string) {
    window.open(link, '_blank');
  }
}
