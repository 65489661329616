<section class="pb-36">
  <!-- School -->
  <div class="flex flex-col items-center">
    <div class="pt-30 relative content-container my-0">
      <!-- wrapper -->
      <div
        class="flex justify-between md:items-center xl:flex-row flex-col px-4 md:px-0">
        <div
          class="flex flex-col pb-2.5 md:flex-row flex-wrap md:justify-between xl:flex-1">
          <h2
            class="text-h2-heading md:text-h1-heading uppercase text-primary-base flex-1 xl:basis-145">
            Наша діяльність
          </h2>
          <app-link-button
            url="/about-us/about-fund/reports"
            [linkButtonType]="'RightArrow'"
            [title]="'Переглянути більше'"
            class="w-55 mt-5 md:mt-0 flex md:justify-end">
          </app-link-button>
          <div class="md:order-1">
            <p
              class="text-smaller-main md:text-main-text text-gray-dark w-full mt-5">
              Будуючи проєкти фонду, ми спираємося на актуальні потреби людей,
              що звертаються до фонду. Ми враховуємо найкращий світовий досвід
              та намагаємося об’єднати всіх зацікавлених осіб для подолання
              викликів, пов’язаних з деменцією в Україні.
            </p>

            <p class="mt-7">Приклади проєктів фонду:</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Help with medicine -->
    <div class="py-15 xl:pt-15 xl:pb-20 w-full flex justify-center">
      <div
        class="content-container flex flex-col md:flex-row items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
        <!-- wrapper -->
        <div class="flex flex-col items-center md:items-start">
          <p class="text-h3-360 md:text-h2-heading-bold text-primary-base">
            {{ 'Допомога з ліками' | uppercase }}
          </p>
          <p
            class="w-full md:w-86 xl:w-[580px] pb-10 mt-2.5 xl:mt-7.5 text-smaller-main md:text-main-text">
            З початком повномасштабної війни фонд створив напрямок кризової
            допомоги, надаючи гуманітарну допомогу людям з деменцією, а також з
            іншими неврологічними захворюваннями. За допомогою міжнародних
            донорів, фонд “Незабутні” з 2022 року направив ліки на 5 млн.
            доларів у 8 областей України у заклади геріатричного та
            психоневрологічного профілю. Ми відправляємо ліки як закладам, так і
            фізичним особам по всій Україні.
          </p>

          <app-link-button
            url="/get-support/help-with-medication"
            [linkButtonType]="'Secondary'"
            [title]="'Детальніше'"
            class="w-70 md:w-55 flex">
          </app-link-button>
        </div>
        <img
          src="../assets/images/our-projects-block/help-with-medecine@960w.jpg"
          alt="Допомога з ліками"
          class="rounded-15 md:w-102.5 xl:w-120 mx-auto" />
      </div>
    </div>
  </div>

  <!-- help-with-power -->
  <div
    class="flex justify-center items-center bg-gray-card pt-18 xl:pt-14 pb-20 rounded-30">
    <div
      class="content-container flex flex-col md:flex-row-reverse items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
      <!-- wrapper -->
      <div class="flex flex-col items-center md:items-start">
        <p class="text-h3-360 md:text-h2-heading-bold text-primary-base">
          {{ 'Допомога з генераторами' | uppercase }}
        </p>
        <p
          class="w-full md:w-86 xl:w-[580px] pb-10 mt-2.5 xl:mt-7.5 text-smaller-main md:text-main-text">
          Зважаючи на проблеми з електропостачанням в Україні, спричиненими
          руйнуванням інфраструктури загарбником, фонд “Незабутні” допомагає з
          генераторами. З 2022 року фонд надав 27 генераторів психоневрологічні
          інтернати та геріатричні пансіонати. Зазвичай подібні заклади мають
          автономні водо- та теплопостачання, а тому майже повністю залежать від
          електроенергії. Ми постійно шукаємо гранти та можливості для
          постачання дизельних генераторів різної потужності у заклади, де вони
          найнеобхідніші.
        </p>

        <app-link-button
          url="get-support/crisis-assistance"
          [linkButtonType]="'Secondary'"
          [titleLinkId]="'help_with_generators'"
          [title]="'Детальніше'"
          class="w-70 md:w-55 flex">
        </app-link-button>
      </div>
      <img
        src="../assets/images/our-projects-block/help-with-power@960w.jpg"
        alt="Школа турботи"
        class="rounded-15 md:w-102.5 xl:w-120 mx-auto" />
    </div>
  </div>

  <!-- Psychological support -->
  <div
    class="flex justify-center items-center bg-gray-card pt-18 xl:pt-14 pb-20 rounded-30">
    <div
      class="content-container flex flex-col md:flex-row items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
      <!-- wrapper -->
      <div class="flex flex-col items-center md:items-start">
        <p class="text-h3-360 md:text-h2-heading-bold text-primary-base">
          {{ 'Психологічна підтримка' | uppercase }}
        </p>

        <p
          class="w-full md:w-86 xl:w-[580px] pb-10 mt-2.5 xl:mt-7.5 text-smaller-main md:text-main-text">
          Коли хтось із рідних починає хворіти на деменцію, це змінює спосіб
          життя всієї родини. І найбільший тягар лягає саме на людину, яка
          доглядає за хворим. Це важкий, виснажливий процес, який потребує
          підтримки! Адже благополуччя людини з деменцією безпосередньо
          пов’язано зі станом, у якому перебуває його доглядальник. З цією
          метою  у фонді “Незабутні” проводяться регулярні зустрічі груп
          підтримки для родичів. Групи дають можливість ділитися своїми
          переживаннями, підтримувати свій емоційний стан та вчитися будувати
          відносини з близькою людиною з деменцією у колі однодумців.<br /><br />Зустрічі
          проводяться на платформі ZOOM та модерують психологами фонду.
          Долучитися можна родичам та доглядальникам людей з деменцією за
          попереднім записом.
        </p>
        <app-link-button
          url="/get-support/psychological-support"
          [linkButtonType]="'Secondary'"
          [title]="'Детальніше'"
          class="w-70 md:w-55 flex">
        </app-link-button>
      </div>
      <img
        src="../assets/images/our-projects-block/help-with-psyco.jpg"
        alt="Школа турботи"
        class="rounded-15 md:w-102.5 xl:w-120 mx-auto" />
    </div>
  </div>

  <!-- cafe -->
  <div
    class="flex justify-center items-center bg-primary-base pt-18 xl:pt-14 pb-20 rounded-30 bg-clip-text">
    <div
      class="content-container flex flex-col md:flex-row-reverse items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
      <!-- wrapper -->
      <div class="flex flex-col items-center md:items-start">
        <p class="text-h3-360 md:text-h2-heading-bold bg-clip-text">
          {{ 'Кафе “Незабутні”' | uppercase }}
        </p>
        <p
          class="w-full md:w-86 xl:w-[580px] pb-10 mt-2.5 xl:mt-7.5 text-smaller-main md:text-main-text">
          Кафе “Незабутні” — це захід формату “альцгеймер-кафе” або memory café
          (кафе пам’яті), поширеного по всьому світу. Це подія для людей з
          деменцією та їхніх родичів і доглядальників з метою спілкування,
          корисного відпочинку та зменшення відчуття ізольованості. Доведено, що
          кафе мають значний позитивний вплив на якість життя людей з деменцією
          та їхніх родичів, які здійснюють догляд за ними. <br /><br />Ми
          проводимо Кафе “Незабутні” з 2021 року в затишному закладі в центрі
          Києва. Фахівці адаптують програму під потреби учасників і може
          включати музику, танці, співи, арт-терапію, вправи та ігри, а також
          бесіди і чаювання.
        </p>

        <app-link-button
          url="/get-support/psychological-support"
          [linkButtonType]="'Primary'"
          [title]="'Детальніше'"
          class="w-70 md:w-55 flex">
        </app-link-button>
      </div>
      <img
        src="../assets/images/our-projects-block/help-with-rehabilitee.jpg"
        alt="Школа турботи"
        class="rounded-15 md:w-102.5 xl:w-120 mx-auto" />
    </div>
  </div>

  <!-- plan -->

  <div class="flex justify-center items-center pt-18 xl:pt-14 pb-20 rounded-30">
    <div
      class="content-container flex flex-col md:flex-row items-start xl:justify-start justify-center relative px-4 mx-auto md:px-0 gap-10 md:gap-2.5 xl:gap-30">
      <!-- wrapper -->
      <div class="flex flex-col items-center md:items-start">
        <p class="text-h3-360 md:text-h2-heading-bold text-primary-base">
          {{ 'Національний план з деменції' | uppercase }}
        </p>

        <p
          class="w-full md:w-86 xl:w-[580px] pb-10 mt-2.5 xl:mt-7.5 text-smaller-main md:text-main-text">
          Одним з найважливіших напрямків діяльності фонду є адвокація та захист
          прав людей з деменцією та їх доглядальників. В 2022 році фонд
          ініціював створення “Національного плану з деменції”. На сьогоднішній
          день ведеться активна робота за участі міністерств та важливих
          стейкхолдерів над створенням стратегії.
        </p>
        <app-link-button
          url="/about-us/about-fund/reports"
          [linkButtonType]="'Secondary'"
          [title]="'Детальніше'"
          class="w-70 md:w-55 flex">
        </app-link-button>
      </div>
      <img
        src="../assets/images/our-projects-block/help-with-plan.jpg"
        alt="Школа турботи"
        class="rounded-15 md:w-102.5 xl:w-120 mx-auto" />
    </div>
  </div>
  <app-link-button
    url="/about-us/about-fund/reports"
    [linkButtonType]="'Primary'"
    [title]="'Переглянути всі проєкти'"
    class="w-70 mx-auto flex">
  </app-link-button>
</section>
