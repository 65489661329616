<app-page-banner-item
  [imgUrl]="'assets/images/banner/banner-reports.png'"
  title="Звіти"
  text="У цьому розділі ви побачите, яку саме допомогу отримали підопічні завдяки благодійникам та зможете ознайомитися з нашими установчими документами."></app-page-banner-item>
<div class="items-center content-container mt-30 mb-30">
  <h2 class="text-h2-heading text-primary-base uppercase mb-5">
    Звіти та установчі документи
  </h2>
  <p class="text-main-text text-gray-dark mb-10">
    «Незабутні» регулярно та вчасно звітують про свою діяльність перед усіма
    благодійниками, державними органами та українським суспільством.
  </p>
  <app-info-cards [cards]="cards"></app-info-cards>
</div>
<app-report-not-found></app-report-not-found>
