<div class="flex md:content-container mx-4 md:mx-auto overflow-hidden">
  <div class="duration-400 transition-all" #sliderTrack>
    <ng-content></ng-content>
  </div>
</div>

<div class="flex xl:hidden items-center justify-center">
  <div class="w-30 flex justify-between">
    <button (click)="onPrevSlide()">
      <svg
        class="cursor-pointer"
        [ngClass]="currentIndex > 0 ? 'fill-accent-base' : 'fill-gray-gray2'"
        [icon]="'left-arrow'"
        iconCollection="button-icons-sprite"
        width="40px"
        height="40px"></svg>
    </button>
    <button (click)="onNextSlide()">
      <svg
        class="cursor-pointer fill-accent-base"
        [ngClass]="{
          'fill-gray-gray2': currentIndex > this.lastEl - 1
        }"
        [icon]="'right-arrow'"
        iconCollection="button-icons-sprite"
        width="40px"
        height="40px"></svg>
    </button>
  </div>
</div>
