import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { IAccordionData } from 'src/app/models/accordionModel.interface';
import { AccordionApiService } from 'src/app/services/api/accordionApi.service';
import { accordionList } from 'src/mockData/accordionHelpList';
import { AlternativePaidArray } from 'src/mockData/alternativePaid';
import { requisitesPaid } from 'src/mockData/requisitesPaid';

interface IAccordionList {
  title: string;
  HTML: SafeHtml;
}
@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.css'],
})
export class DonateComponent implements OnInit, AfterViewInit {
  public AlternativePaidArray: any = AlternativePaidArray;
  public requisitesPaid: any = requisitesPaid;
  public accordionList: IAccordionList[] = accordionList;
  test: any = 'test';
  constructor(
    private cdRef: ChangeDetectorRef,
    private apiFaQService: AccordionApiService,
    private sanitizer: DomSanitizer,
  ) {}
  ngOnInit(): void {
    this.fetchAccordionList().subscribe(data => {
      this.accordionList = data.map(faq => ({
        title: faq.title,
        HTML: this.sanitizer.bypassSecurityTrustHtml(faq.html),
      }));
    });
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }
  fetchAccordionList(): Observable<IAccordionData[]> {
    return this.apiFaQService.getData('Help');
  }
}
