import IMedicalAssistance from '../app/models/medicalAssistance.interface';

export const MedicalAssistanceMockData: IMedicalAssistance[] = [
  {
    title: 'Консультації лікаря',
    text: 'Якщо ви маєте деменцію або ви представляєте інтереси людини з деменцією як родич чи доглядальник, ви можете отримати безкоштовну індивідуальну консультацію з юристом від фонду “Незабутні”. Консультації проходять в онлайн-режимі, тому зареєструватися можна з будь-якого куточку України чи світу. У рамках програми “Юридична допомога” протягом 2023 року буде надано 100 безкоштовних консультацій з юридичних питань. Запишіться на консультацію, натиснувши на кнопку нижче.',
  },
];
export const MedicalAssistanceWebinarMockData: IMedicalAssistance[] = [
  {
    title: 'Вебінари з медичними фахівцями',
    text: '<p class="mb-7">З 2023 року ми проводимо вебінари з юридичних питань та запрошуємо доєднуватись усіх бажаючих, кого цікавить інформація щодо прав людей з деменцією та їхніх доглядальників, державних гарантій, оформлення спадщини, інвалідності чи опікунства та інших юридичних аспектів. Вебінари проходять в онлайн форматі.  Долучитися може кожен бажаючий за попередньою реєстрацією. </p>Ви можете переглянути вебінари <a class="text-accent-base hover:text-accent-additional underline" href="https://www.youtube.com/"> у записі на YouTube сторінці фонду.  </a>;',
    video: '../assets/images/Video.jpg',
  },
];
