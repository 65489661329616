import { Component, OnInit } from '@angular/core';
import { annualReportsMockData } from 'src/mockData/annualReportsConfig';
import { ReportsCardInterface } from '../reports-card/reports-card.component';

@Component({
  selector: 'app-annual-reports',
  templateUrl: './annual-reports.component.html',
  styleUrls: ['./annual-reports.component.css'],
})
export class AnnualReportsComponent implements OnInit {
  protected annualReportsData: ReportsCardInterface = annualReportsMockData;
  constructor() {}

  ngOnInit(): void {}
}
