import { Component, OnInit } from '@angular/core';
import { PartnersConfig } from 'src/mockData/partnersConfig';
import { DomSanitizer } from '@angular/platform-browser';
import IPartner from 'src/app/models/partner.interface';
import { forkJoin, Observable } from 'rxjs';
import IPartnersLinks from 'src/app/models/partners.links.interface';
import { websitelinks } from 'src/mockData/partners.linksMockData';

@Component({
  selector: 'app-fund-partners-block',
  templateUrl: './fund-partners-block.component.html',
  styleUrls: ['./fund-partners-block.component.css'],
})
export class FundPartnersBlockComponent implements OnInit {
  showCountOfLogos = 8;
  showMore() {
    this.showCountOfLogos += 8;
  }
  images: Observable<any[]>[] = [];
  image: any;
  sanitizedImages: IPartner[] = [];
  websiteLinks: IPartnersLinks[] = websitelinks;

  constructor(
    private config: PartnersConfig,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.images.push(this.config.getDalgakiran());
    this.images.push(this.config.getLetigationGroup());
    this.images.push(this.config.getGenerator());
    this.images.push(this.config.getAlzheimer());
    this.images.push(this.config.getMedikom());
    this.images.push(this.config.getSocialPolicy());
    this.images.push(this.config.getBrainHealth());
    this.images.push(this.config.getGerontology());
    this.images.push(this.config.getTest());

    forkJoin(this.images).subscribe(items => {
      items.forEach((baseImage: any) => {
        let objectURL = 'data:image/jpeg;base64,' + baseImage.image;
        this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.sanitizedImages.push({ image: objectURL });
      });
    });
  }
}
