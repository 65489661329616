import ILearning from 'src/app/models/learning.interface';

export const learningDataMock: ILearning[] = [
  {
    title: 'Школа турботи',
    text: '<p class="text-main-text text-gray-dark mb-7">“Школа турботи” - це безкоштовні одноденні тренінги для родичів та доглядальників людей з деменцією. Їх мета - сформувати розуміння деменції, отримати знання про догляд та підтримку людини з деменцією, напрацювати нові навички, необхідні для організації комфортного життя людини з деменцією та її родини. Тренінги проводяться фахівцями фонду, спільно із запрошеними спікерами: лікарями та фахівцями з догляду. Перед проведенням кожного тренінгу ми збираємо перелік питань від учасників та готуємо відповіді на них, щоб зустріч була максимально корисною для кожного.</p> <p class="text-main-text text-gray-dark mb-7">Тренінги “Школи турботи” проходять щоквартально, в останній тиждень лютого, травня, серпня та листопада. Наразі тренінги проходять в онлайн режимі на платформі ZOOM, однак плануються і офлайн формати в місті Київ.</p>',
    src: '../assets/images/learning/1.jpg',
    data: 'Дата найближчого тренінгу: 27.05 ',
    link: 'Читайте детальніше про кожен тренінг та реєструйтеся для участі в розділі <a class= "text-accent-base hover:text-accent-additional underline" href="/activities"> Заходи </a>',
  },
];

export const learningTextDataMock: ILearning[] = [
  {
    title: 'Вебінари',
    text: 'Фонд “Незабутні” організовує тематичні вебінари про деменцію. Вебінари проходять в онлайн форматі. Долучитися може кожен бажаючий за попередньою реєстрацією. На кожному вебінарі розкривається одна тема, що стосується медичних, правових питань, соціальних послуг, підтримки людей з деменцією, допомоги родичам та доглядальникам та інших питань. На вебінарах виступають запрошені спікери та фахівці фонду, а слухачі можуть відразу ставити питання, що їх цікавлять.</p>',
    src: '../assets/images/learning/2.jpg',
    link: 'Слідкуйте за оновленнями в розділі <a class= "text-accent-base hover:text-accent-additional underline" href="/activities">Заходи</a> та долучайтеся до найближчого вебінару',
  },
];

export const learningInfoDataMock: ILearning[] = [
  {
    title: 'Тренінги для надавачів медико-соціальних послуг',
    text: ' <p class="text-main-text text-gray-dark mb-7">Ми підтримуємо фахівців, що працюють з людьми з деменцією і пропонуємо короткотермінове навчання у форматі онлайн чи офлайн тренінгів. На тренінгах ми розповідаємо загальну інформацію про деменцію, говоримо про принципи та особливості роботи з людьми, що живуть з деменцією, та їхніми родичами, а також готуємо додаткові теми, актуальні для конкретної сфери чи діяльності. </p> <p class="text-main-text text-gray-dark mb-7">Якщо ви представляєте організацію, що надає медичні чи соціальні послуги для людей з деменцією, і ви б хотіли організувати тренінг для співробітників - напишіть нам на пошту (значок копії) <a class="text-accent-base text-main-text hover:text-accent-additional underline" href=" ">info@nezabutni.org </a> та зазначте в темі листа “Тренінг для співробітників”</p>',
  },
];
