<div class="container my-5">
  <pagination-template
    class="flex justify-center items-center mb-30 gap-7.5"
    #p="paginationApi"
    (pageChange)="pageChange.emit($event)">
    <div
      class="fill-accent-base cursor-pointer hover:fill-secondary-base focus:fill-secondary-base">
      <app-left (click)="p.previous()"> </app-left>
    </div>

    <div
      class="cursor-pointer"
      *ngFor="let page of p.pages"
      [class.current]="p.getCurrent() === page.value">
      <a
        class="inline-block py-1 text-gray-dark underline hover:font-bold hover:no-underline hover:text-primary-base focus:font-bold focus:no-underline focus:text-primary-base mx-1"
        (click)="p.setCurrent(page.value)"
        *ngIf="p.getCurrent() !== page.value">
        <span>{{ page.label }}</span>
      </a>
      <div *ngIf="p.getCurrent() === page.value">
        <span>{{ page.label }}</span>
      </div>
    </div>

    <div
      class="fill-accent-base cursor-pointer hover:fill-secondary-base focus:fill-secondary-base">
      <app-right *ngIf="!p.isLastPage()" (click)="p.next()"> </app-right>
    </div>
  </pagination-template>
</div>
