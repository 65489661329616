import { Component } from '@angular/core';
import IInfoPage from '../../models/infoPage.interface';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
})
export class AboutUsComponent {
  bannerData: any = {
    title: 'Про нас',
    text: 'Ми допомагаємо людям, що живуть з деменцією, та їх рідним, а також працюємо над створенням суспільства, дружнього до людей з деменцією.',
    imgUrl: './assets/images/banner/about-us.png',
  };

  aboutUsTextConfig: IInfoPage[] = [
    {
      title: 'Що таке деменція?',
      text: 'Деменція – це загальний термін, який використовується для опису ряду прогресуючих неврологічних розладів, тобто станів, що впливають на мозок. Існує понад 200 підтипів деменції, але п’ять найпоширеніших: хвороба Альцгеймера, судинна деменція, деменція з тільцями Леві, лобно-скронева деменція та змішана деменція. Деякі люди можуть мати комбінацію різних типів деменції, і їх зазвичай називають змішаною деменцією.',
    },
  ];

  aboutUsCardConfig: IInfoPage[] = [
    {
      title: 'Про фонд',
      text: 'Що таке деменція, як поставити діагноз і як зменшити ризик розвитку деменції або відстрочити її вплив.',
      link: '/about-us/about-fund',
    },
    {
      title: 'Наші люди',
      text: 'Дізнайтеся більше про симптоми деменції з нашої сторінки.',
      link: '/about-us/our-people',
    },
    {
      title: 'Наші програми',
      text: 'Дізнайтеся, чому важливо пройти обстеження, щоб перевірити, чи є у вас деменція, і про переваги отримання діагнозу.',
      link: '/about-us/our-programs',
    },
    {
      title: 'Новини та медіа',
      text: 'Ми створили для Вас зручний каталог у PDF, який можна переглянути за завантажити на сторінці.',
      link: '/about-us/news-and-media',
    },
  ];

  block: IInfoPage[] = this.aboutUsTextConfig;
  cards: IInfoPage[] = this.aboutUsCardConfig;
}
