<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="subscribe-wrap flex flex-col justify-between h-full">
  <div class="flex flex-col pt-1">
    <h3 class="text-main-text-bold mb-5">
      Оберіть платформу щомісячного внеску
    </h3>
    <p class="text-main-text mb-7 w-155">
      Наразі ми маємо декілька платформ для оформлення щомісячної підписки на
      донат. Оберіть платформу щоб ознайомитись із інструкцією.
    </p>
    <div class="flex flex-row justify-start items-center flex-wrap">
      <label
        for="{{ item.bankName }}"
        class="w-75 h-25 my-2.5 odd:mr-5 cursor-pointer flex justify-center items-center border-2 border-gray-stroke rounded-30"
        [ngClass]="{
          'border-primary-base': itemBank.checked,
          'hover:border-secondary-base': !itemBank.checked
        }"
        *ngFor="let item of subscribeArray"
        (click)="bankChecked = true">
        <img src="{{ item.logo }}" alt="{{ item.bankName }}" />
        <input
          #itemBank
          id="{{ item.bankName }}"
          class="hidden"
          type="radio"
          formControlName="bankName"
          name="bankName"
          value="{{ item.bankName }}" />
      </label>
    </div>
  </div>
  <ng-container *ngIf="bankChecked">
    <div class="flex flex-col">
      <div class="text-main-text">
        Ви можете відкрити готову інструкцію або одразу перейти на сайт
        платформи для оформлення підписки.
      </div>
      <div class="flex flex-row items-center justify-between mt-5">
        <app-button
          class="mr-2.5 w-75"
          [buttonType]="'Secondary'"
          type="button"
          [title]="'Відкрити інструкцію'"></app-button>
        <app-button
          class="ml-2.5 w-75"
          [buttonType]="'Primary'"
          [disabled]="!form.valid"
          type="submit"
          [title]="'Оформити підписку'"></app-button>
      </div>
    </div>
  </ng-container>
</form>
