import { Component, OnInit, OnDestroy } from '@angular/core';
import newsCardInfo from 'src/app/models/newsCard.interface';
import { NewsApiService } from 'src/app/services/api/aboutUsPages/NewsAndMedia/news-api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit, OnDestroy {
  newsCards!: newsCardInfo[];
  methodOfSort!: string;
  subscr!: Subscription;
  p: number = 1;
  constructor(private newsApiService: NewsApiService) {}

  ngOnInit(): void {}

  public valueForFilter(value: string) {
    this.methodOfSort = value;
    if (this.methodOfSort === 'Спочатку нові') {
      this.getNews('new');
    }
    if (this.methodOfSort === 'Спочатку старі') {
      this.getNews('old');
    }
  }

  getNews(val: string) {
    this.subscr = this.newsApiService.getNewsArray(val).subscribe(news => {
      this.newsCards = news;
      this.p = 1;
    });
  }

  public ngOnDestroy() {
    this.subscr.unsubscribe();
  }
}
