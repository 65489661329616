<section class="my-30">
  <div class="container">
    <div class="mb-30 text-gray-dark text-details">
      <h2 class="text-primary-base uppercase text-h2-heading mb-5">
        {{ text.title }}
      </h2>
      <p class="text-main-text">{{ text.text }}</p>
      <ng-container *ngIf="text.whatIsDementiaParagraph">
        <p class="text-main-text mt-8">{{ text.whatIsDementiaParagraph }}</p>
      </ng-container>
      <ng-container *ngIf="text.whatIsDementiaParagraphSecond">
        <p class="text-main-text mt-8">
          {{ text.whatIsDementiaParagraphSecond }}
        </p>
      </ng-container>
    </div>
  </div>
</section>
