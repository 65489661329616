import { Component, OnInit } from '@angular/core';
import {
  ReportsCardInterface,
  ReportsPrewInterface,
} from '../reports-card/reports-card.component';

@Component({
  selector: 'app-tax-reports',
  templateUrl: './tax-reports.component.html',
  styleUrls: ['./tax-reports.component.css'],
})
export class TaxReportsComponent implements OnInit {
  taxReportsData: ReportsCardInterface = {
    title: 'фінансові звіти',
    text: [
      'Благодійний фонд “Незабутні” - це неприбуткова організація. Ми дбаємо про прозорість нашої роботи, тому регулярно та вчасно звітуємо перед державою, благодійниками, партнерами та суспільством загалом.',
      'Тут ми публікуємо скан-копії податкових звітів.Звіт здається в затвердженому державою форматі раз на рік, і демонструє загальну суму надходжень і витрат організації. Обирайте рік, щоб переглянути звіт.',
    ],
    reportsLinks: [
      { title: 'Податковий звіт 2021', link: '/' },
      { title: 'Податковий звіт 2022', link: '/' },
    ],
  };

  constructor() {}

  ngOnInit(): void {}
}
