import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css'],
})
export class InformationComponent implements OnInit {
  title = 'Змі про нас';
  textInfo =
    'Ми з радістю відповімо на ваші запитання та допоможемо підготувати тексти й відео про роботу фонду й наших підопічних. Також ми можемо надати коментарі для матеріалів на тему благодійності або деменції.';

  constructor() {}

  ngOnInit(): void {}
}
