import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IHelpCard } from 'src/app/models/help-card.interface';
import { CardHelpVolunteerArray } from 'src/mockData/CardHelpVolunteerArray';

@Component({
  selector: 'app-result-payment',
  templateUrl: './result-payment.component.html',
  styleUrls: ['./result-payment.component.css'],
})
export class ResultPaymentComponent implements OnInit {
  id: string = '';
  protected paymentTime: Date = new Date();
  protected success: boolean = false;
  protected error: boolean = false;
  protected cardArray: IHelpCard[] = CardHelpVolunteerArray;

  constructor(
    private route: ActivatedRoute, // private liqPay: LiqPayService
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    // this.liqPay
    //   .checkStatus(this.id)
    // .pipe(
    //   tap(el => {
    //     if (el) {
    //       this.success = true;
    //     }
    //   }),
    //   catchError(e => {
    //     if (e) {
    //       this.error = true;
    //       setTimeout(() => {
    //         this.error = false;
    //         this.success = true;
    //       }, 5000);
    //     }
    //     throw e;
    //   }),
    // )
    // .subscribe();
    // this.route.queryParams.subscribe(params => {
    //   if (params['result'] === 'ok') {
    //     this.success = true;
    //   }
    //   if (params['result'] === 'error') {
    //     this.error = true;
    //   }
    // });
  }
}
