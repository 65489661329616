import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
  selector: 'app-i-worry-about-memory',
  templateUrl: './i-worry-about-memory.component.html',
  styleUrls: ['./i-worry-about-memory.component.css'],
})
export class IWorryAboutMemoryComponent implements OnInit, OnDestroy {
  lightText = '';
  darkText = '';
  cards: any[] = [
    {
      id: 'i-worry-about-memory-4047',
      title: 'Як зрозуміти, чи в мене деменція?',
      link: '/get-support/what-to-do-if/i-worry-about-memory',
      dementiaMenuId: 2023,
    },
    {
      id: 'i-worry-about-memory-4048',
      title: 'Діагностування деменції',
      link: '/get-support/what-to-do-if/i-worry-about-memory',
      dementiaMenuId: 2023,
    },
    {
      id: 'i-worry-about-memory-4049',
      title: 'Чому погіршується пам’ять?',
      link: '/get-support/what-to-do-if/i-worry-about-memory',
      dementiaMenuId: 2023,
    },
    {
      id: 'i-worry-about-memory-4050',
      title: "Як впоратися з погіршенням пам'яті?",
      link: '/get-support/what-to-do-if/i-worry-about-memory',
      dementiaMenuId: 2023,
    },
  ];
  sectionCards: any[] = [];
  currentBreadcrumb: string | null = 'second';
  isLoaded = false;
  private routeSubscription: Subscription | null = null;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private scrollService: ScrollService,
  ) {}

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(params => {
      const routeId = params['secondId'];
      this.router.navigate([], { state: { id: routeId } });
      this.fetchCardList()
        .pipe(
          tap((data: any[]) => {
            this.lightText = data[0].contentLight;
            this.darkText = data[0].contentDark;

            this.sectionCards = data[0].menuSubTitle.map((el: any) => ({
              subMenuCards: [
                ...el.SubMenuCards.map((card: any) => ({
                  link:
                    '/get-support/what-to-do-if/i-worry-about-memory/' +
                    card.cardId,
                  cardTitle: card.cardTitle,
                })),
              ],
            }));

            this.currentBreadcrumb = data[0].menuTitle;
            const element = document.querySelector(
              '[data-breadcrumb="second"]',
            );
            if (element) {
              element.innerHTML = data[0].menuTitle;
            }
            this.scrollService.setLoaded(true);
          }),
        )
        .subscribe();
    });
  }

  fetchCardList(): Observable<any[]> {
    const url = `https://nezabutni-backend.vercel.app/api/second-page?id=i-worry-about-memory`;
    return this.http.get<any[]>(url);
  }
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
