<section class="flex flex-wrap justify-center md:grid md:grid-cols-2 gap-5">
  <div *ngFor="let partner of partners">
    <div
      class="w-145 h-136.5 overflow-hidden border-2 border-gray-stroke rounded-30 flex flex-col items-center justify-between p-10 cursor-pointer">
      <img class="mx-12" [src]="partner.image" alt="infopulse" />
      <div class="w-125" [innerHTML]="partner.paragraph"></div>
      <button
        class="w-125 h-13 text-menu text-gray-white rounded-10 font-bold bg-accent-base focus:bg-secondary-additional hover:bg-secondary-base disabled:bg-gray-dark bg-accent">
        <a
          class="items-center mx-auto leading-7 w-26.5"
          target="_blank"
          href="{{ partner.link }}"
          >Детальніше</a
        >
      </button>
    </div>
  </div>
</section>
