<div
  class="w-full h-25 md:h-30 xl:h-39 bg-gray-white rounded-30 shadow-prevention-card flex items-center pl-5">
  <svg
    width="64px"
    height="64px"
    [icon]="card.icon"
    iconCollection="additional-icons-sprite"
    class="fill-primary-base"></svg>
  <!-- <img [src]="card?.imageLink" [alt]="card?.text" class="w-16 h-16 ml-5" /> -->
  <p class="ml-4 xl:w-12.5 text-smaller-main md:text-main-text">
    {{ card.text }}
  </p>
</div>
