<app-send-request *ngIf="show" (click)="closePopUp()"> </app-send-request>
<form
  *ngIf="showForm"
  class="w-145 border-2 border-gray-stroke rounded-30 p-10"
  (ngSubmit)="onSubmit()"
  [formGroup]="contactForm">
  <h2 class="text-h2-heading text-primary-base uppercase mb-10">
    Напишіть нам
  </h2>

  <div class="text-main-text text-gray-dark">
    <div class="mb-5">
      <app-input
        [control]="contactForm.controls.name"
        [labelName]="'Ваше імʼя'"
        [maxLength]="'30'"
        inputID="name"
        class="w-full"></app-input>
    </div>
    <div class="mb-5">
      <app-input
        [control]="contactForm.controls.email"
        [labelName]="'E-mail'"
        type="email"
        inputID="email"
        class="w-full"></app-input>
    </div>

    <div class="mb-10 w-125">
      <app-textarea
        [control]="contactForm.controls.message"
        [maxLength]="'2000'"
        [labelName]="'Текст повідомлення'"
        [textAreaID]="'message'"></app-textarea>
    </div>
  </div>

  <div class="flex mb-5">
    <label class="flex">
      <input
        type="checkbox"
        name=""
        class="appearance-none peer"
        formControlName="policy" />
      <span
        class="w-8 h-8 grid place-items-center border-2 border-gray-stroke rounded-8 cursor-pointer peer-checked:bg-primary-additional peer-checked:border-0 peer-focus:border-0 peer-focus:shadow-checkbox peer-checked:peer-focus:shadow-none peer-invalid:border-attention-base">
        <svg
          width="26px"
          height="26px"
          [icon]="'checkBox'"
          iconCollection="button-icons-sprite"
          class="fill-gray-white"></svg>
      </span>
      <span class="w-113.25 ml-3.75 text-details text-gray-dark">
        Натискаючи Відправити я погоджуюся з тим, що введена мною особиста
        інформація буде використана згідно з
        <a
          (click)="onOpenFundPolicy()"
          class="text-accent-base outline-0 cursor-pointer active:text-secondary-additional hover:text-secondary-base duration-300">
          Політикою приватності Фонду </a
        >.
      </span>
    </label>
  </div>
  <app-button
    (click)="openPopUp()"
    [title]="'Відправити'"
    [type]="'submit'"></app-button>
</form>
