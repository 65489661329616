import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informational-assistance',
  templateUrl: './informational-assistance.component.html',
  styleUrls: ['./informational-assistance.component.css'],
})
export class InformationalAssistanceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
