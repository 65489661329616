import { Component, OnInit } from '@angular/core';
import IResearchCard from 'src/app/models/researchCard.interface';
import { researchCardMockDate } from 'src/mockData/researchCardMockDate';
@Component({
  selector: 'app-research-card',
  templateUrl: './research-card.component.html',
  styleUrls: ['./research-card.component.css'],
})
export class ResearchCardComponent implements OnInit {
  researchCards: IResearchCard[] = researchCardMockDate;
  constructor() {}

  ngOnInit(): void {}
}
