import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visionary-card',
  templateUrl: './visionary-card.component.html',
  styleUrls: ['./visionary-card.component.css'],
})
export class VisionaryCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  partners = [
    {
      image: './assets/images/partners/infopulse.png',
      paragraph:
        '<p class="text-gray-dark text-main-text">Співпраця Незабутні та Infopulse розпочалась у 2021 році під час розробки нашого сайту.</p>\n' +
        '        <p class="text-gray-dark text-main-text">З того часу ми активно проводимо спільні заходи та акції.</p>',
      link: 'https://www.infopulse.com/',
    },
  ];
}
