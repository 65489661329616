import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GetSupportApiService {
  url = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getPageData(): Observable<any> {
    return this.http.get<any>(this.url + '/Support/support-page').pipe(
      map(el => {
        return {
          bannerText: el.bannerText.text,
          firstBlock: el.firstBlock.map((item: any) => {
            const { bodyText, ...rest } = item;
            return { ...rest, text: bodyText };
          }),
          secondBlockTitle: {
            title: el.secondBlockTitle.title,
            text: el.secondBlockTitle.bodyText,
          },
          secondBlock: el.secondBlock.map((item: any) => {
            const { bodyText, ...rest } = item;
            return { ...rest, text: bodyText };
          }),
          tabsBlockTitle: {
            title: el.tabsBlockTitle.title,
            text: el.tabsBlockTitle.bodyText,
          },
          lastBlock: {
            title: el.lastBlock.title,
            text: el.lastBlock.bodyText,
          },
          needHelpDataTab: el.needHelpDataTab.map(
            (el: { bodyText: string; link: string; title: string }) => {
              return {
                text: el.bodyText,
                linkTo: el.link,
                title: el.title,
              };
            },
          ),
        };
      }),
    );
  }
}

export interface GetSupportPageInterface {
  bannerText: string;
  firstBlock: { title: string; text: string; link: string }[];
  secondBlockTitle: { title: string; text: string };
  secondBlock: { title: string; text: string; link: string }[];
  tabsBlockTitle: { title: string; text: string };
  needHelpDataTab: {
    title: string;
    text: string;
    active?: boolean;
    linkTo: string;
  }[];
  lastBlock: { title: string; text: string };
}
