import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import IInfoPage from 'src/app/models/infoPage.interface';
import { ROUTE_NAMES } from 'src/app/constants/route_names';

interface IData {
  id: number;
  menuTitle: string;
  shortContent: string;
  linkUrl: string;
}

@Component({
  selector: 'app-about-dementia',
  templateUrl: './about-dementia.component.html',
  styleUrls: ['./about-dementia.component.css'],
})
export class AboutDementiaComponent implements OnInit {
  constructor(private http: HttpClient) {}
  cards: IInfoPage[] = [];
  ngOnInit(): void {
    this.fetchCardList().subscribe((data: IData[]) => {
      this.cards = data.map(record => ({
        title: record.menuTitle,
        text: record.shortContent,
        link: ROUTE_NAMES.aboutDementia + record.linkUrl,
        id: record.id,
      }));
      console.log(data);
    });
  }

  fetchCardList(): Observable<any[]> {
    const url = 'https://nezabutni-backend.vercel.app/api/dementia-card-list';
    return this.http.get<any[]>(url);
  }
}
