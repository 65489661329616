<div
  class="h-41.5 md:h-50 p-7.5 md:p-10 bg-white text-primary-base w-82 md:w-102.5 xl:w-95 rounded-30">
  <div class="flex items-center gap-1.5 h-12 md:h-15 mb-0 md:mb-3">
    <svg
      width="46px"
      height="46px"
      [icon]="influence.icon"
      iconCollection="mdi-icons-sprite"
      class="fill-accent-base"></svg>
    <p class="text-h2-heading md:text-h1-narrow-big text-primary-base">
      {{ influence.title
      }}<span class="text-h2-heading md:text-impact-card-special">{{
        influence.subtitle
      }}</span>
    </p>
  </div>
  <div class="text-base text-gray-dark">
    {{ influence.content }}
  </div>
</div>
