<ng-container *ngIf="error">
  <div
    class="wide-container h-162 bg-primary-base flex flex-col justify-end pl-87.75 pb-19.75">
    <h2 class="text-h1-heading text-gray-white uppercase">
      Щось пішло не так :(
    </h2>
    <p class="text-gray-white text-h3-subheading mt-2.25">
      Будь ласка, спробуйте ще раз.
    </p>
    <div class="w-55 mt-10">
      <app-link-button
        [linkButtonType]="'Donate'"
        [title]="'СПРОБУВАТИ ЗНОВУ'"
        [url]="'../../'">
      </app-link-button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="success">
  <app-page-banner-item
    [imgUrl]="'assets/images/bannerPicture/successPayment.png'"
    text="Ваша допомога – це підтримка людей, що страждають на деменцію та фонду, який кожного дня працює задля покращення життя людей із деменцією."
    title="Дякуємо за ваш внесок!">
    <p
      *ngIf="paymentTime"
      class="text-main-text-bold text-gray-white mb-4.75"
      paymentTimeBlock>
      {{ paymentTime | date : 'dd.MM.yyyy HH:mm' }}
    </p>
    <div class="w-55 mt-4.75">
      <app-link-button
        [linkButtonType]="'Primary'"
        [title]="'Переглянути звіти'"
        [url]="'../../../../about-us/about-fund/reports'">
      </app-link-button>
    </div>
  </app-page-banner-item>
  <div class="pt-30.5 pb-28 grid place-items-center">
    <div>
      <h2 class="text-primary-base text-h1-heading uppercase mb-9.75">
        Інші способи долучитись
      </h2>
      <div class="flex content-container justify-between">
        <app-help-card
          *ngFor="let card of cardArray"
          [card]="card"
          [type]="'secondary'"></app-help-card>
      </div>
    </div>
  </div>
</ng-container>
