import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SubscribeItem } from 'src/app/models/subscribeItem.interface';
import { subscribeArray } from 'src/mockData/subscribeArray';

@Component({
  selector: 'app-subscribe-help',
  templateUrl: './subscribe-help.component.html',
  styleUrls: ['./subscribe-help.component.css'],
})
export class SubscribeHelpComponent implements OnInit {
  subscribeArray: SubscribeItem[] = subscribeArray;
  bankChecked: boolean = false;
  form!: FormGroup;
  constructor() {}

  ngOnInit(): void {
    this.form = new FormGroup({
      bankName: new FormControl('', Validators.required),
    });
  }

  redirectToSubscribe(item: SubscribeItem) {
    console.log('Method for redirect on page subscribe', item);
  }

  onSubmit() {
    console.log(this.form.value);
    this.form.reset();
    this.bankChecked = false;
  }
}
