import { Component, Injectable, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import IFundMember from 'src/app/models/fundMember.interface';
import { fundMembersMock } from 'src/mockData/fundMembersMock';
@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-fund-member',
  templateUrl: './fund-member.component.html',
  styleUrls: ['./fund-member.component.css'],
})
export class FundMemberComponent implements OnInit {
  fundMembers: IFundMember[] = fundMembersMock;
  currentMember!: IFundMember;
  correctMember: boolean = false;
  currentBreadcrumb: string = 'Ірина Шевченко';
  constructor(private route: ActivatedRoute, private router: Router) {}
  memberID = this.route.snapshot.params['id'];
  ngOnInit(): void {
    const filteredMembers = this.fundMembers.filter(
      member => member.memberID === this.memberID,
    );
    if (filteredMembers.length > 0) {
      this.currentMember = filteredMembers[0];
      this.correctMember = true;
      this.currentBreadcrumb = filteredMembers[0].title;
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
}
