import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.css'],
})
export class ResearchComponent implements OnInit {
  protected links: { readonly id: string; readonly title: string }[] = [];

  constructor() {
    this.links = [
      {
        id: 'fund_research',
        title: 'Дослідження фонду Незабутні',
      },
      {
        id: 'other_publications',
        title: 'Інші публікації',
      },
      {
        id: 'take_part_in_research',
        title: 'Взяти участь у дослідженні',
      },
    ];
  }

  ngOnInit(): void {}
}
