<app-page-banner-item
  [imgUrl]="'assets/images/banner/medical-assistance.jpg'"
  title="Медична допомога"
  text="Ми підтримуємо людей, що здійснюють догляд за своїми близькими з деменцією. Ви можете отримати консультацію фахівця або знайти відповіді на питання в інформаційних матеріалах"></app-page-banner-item>
<app-table-of-content
  class="flex mx-auto w-full m-30"
  [links]="links"></app-table-of-content>

<div
  id="DoctorsConsultations"
  class="container mt-30"
  *ngFor="let textConsultations of consultations">
  <h3 class="text-h2-heading text-primary-base uppercase">
    {{ textConsultations.title }}
  </h3>
  <p class="mt-5">{{ textConsultations.text }}</p>
  <app-link-button
    url="/about-us/about-fund/reports"
    [linkButtonType]="'Primary'"
    [title]="'Записатися на консультацію'"
    class="w-75 flex mt-10 mb-30">
  </app-link-button>
</div>
<div id="WebinarsWithMedicalSpecialists" class="bg-gray-card">
  <div class="container py-30" *ngFor="let textWebinar of webinar">
    <h3 class="text-h2-heading text-primary-base uppercase">
      {{ textWebinar.title }}
    </h3>
    <p class="mt-5 mb-10" [innerHTML]="textWebinar.text"></p>
    <div class="div-round">
      <youtube-player
        videoId="QZAH7F4QHVM"
        suggestedQuality="highres"
        [height]="665"
        [width]="1180"
        [startSeconds]="4"
        [endSeconds]="8">
      </youtube-player>
    </div>
  </div>
</div>
<div id="AdviceAndInformationAboutMedicalCare" class="container">
  <div class="mb-10">
    <h3 class="text-h2-heading text-primary-base uppercase mt-30">
      Поради та інформація з медичних питань
    </h3>
    <p class="text-gray-dark text-main-text mt-10">
      Обирайте пункт, що стосується вас, та читайте інформацію від фахівців
      фонду.
    </p>
  </div>
  <app-accordion
    [AccordionsList]="accordionList"
    [options]="{ multi: false }"
    [classList]="'mb-30'"></app-accordion>
</div>

<app-more-info
  [moreInfos]="medicalAssistanceCard"
  [mainText]="mainText"></app-more-info>
