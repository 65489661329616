<section class="content-container text-start">
  <ul class="mt-10 px-4">
    <li class="mb-30">
      <h3 class="text-h1-heading text-primary-base mb-5">
        {{ title | uppercase }}
      </h3>
      <div class="flex flex-col md:flex-row items-center md:items-start">
        <div
          class="text-main-text text-gray-dark main-text"
          [innerHTML]="text"></div>
        <img
          class="w-[328px] h-[262px] ml-6 float-right rounded-30 md:w-[410px] md:h-[282px] xl:w-[580px] xl:h-[400px] object-cover]"
          [src]="image"
          alt="люди посміхаються" />
      </div>
    </li>
    <li class="mb-30" *ngFor="let card of cards">
      <h3 class="text-h2-heading text- mb-5 text-primary-base">
        {{ card.title | uppercase }}
      </h3>
      <div
        class="text-lg text-gray-dark main-text"
        [innerHTML]="card.text"></div>
    </li>
  </ul>
</section>
<div class=""></div>
