import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css'],
})
export class ImageSliderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  arrayListImage: any = [
    { src: '../../../../../assets/images/advocacy/4.jpg' },
    { src: '../../../../../assets/images/advocacy/1.jpg' },
    { src: '../../../../../assets/images/advocacy/2.jpg' },
  ];

  onChangeSlide(value: string) {
    if (value === 'nextSlide') {
      const deletedElement = this.arrayListImage.shift();
      console.log(deletedElement);
      this.arrayListImage.push(deletedElement);
    }
    if (value === 'prevSlide') {
      const deletedElement = this.arrayListImage.pop();
      this.arrayListImage.unshift(deletedElement);
    }
  }
}
