import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-programs',
  templateUrl: './our-programs.component.html',
  styleUrls: ['./our-programs.component.css'],
})
export class OurProgramsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
