<div class="h-full flex flex-col md:flex-row mb-10 text-lg text-gray-dark">
  <!--  <img class="object-scale-down" src="./assets/images/news/sort.png" />-->
  <!--  <p class="font-semibold mr-5">Впорядкувати</p>-->
  <ul class="flex flex-row text-main-text leading-7">
    <li class="ml-5 border-b-2 border-accent-base text-accent-base">
      спочатку нові
    </li>
    <li class="ml-5">спочатку старі</li>
  </ul>
</div>
