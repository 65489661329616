<section class="flex justify-center">
  <h2 class="text-primary-base uppercase text-h1-heading w-full md:w-95 mr-5">
    Глосарій рекомендованих термінів
  </h2>
  <p class="text-gray-dark text-main-text w-full md:w-195">
    Це список термінів, які варто або не варто використовувати, говорячи про
    деменцію або людей, які зіштовхнулися з нею. Цей список не є вичерпним і
    висвітлює лише деякі теми.
  </p>
</section>

<section class="mt-20">
  <div class="m-auto relative w-[780px] overflow-hidden" #carousel>
    <!-- Зображення каруселі -->
    <div class="carousel-inner">
      <img
        *ngFor="let image of imageList"
        [src]="image.imgLink"
        [alt]="image.title"
        class="carousel-item w-195 h-[1133px] rounded-[40px] border-2 border-gray-card" />
    </div>
    <!-- Кнопки каруселі -->
    <div class="mt-11.5 flex justify-center gap-[36px] items-center">
      <button
        class="cursor-pointer mt-[-6px] disabled:cursor-not-allowed"
        (click)="prevSlide()"
        [disabled]="currentIndex === 0"
        [ngClass]="{
          'pointer-events-none': currentIndex === 0
        }">
        <svg
          class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional"
          [icon]="'left-arrow'"
          iconCollection="button-icons-sprite"
          width="40px"
          height="40px"
          [ngClass]="{
            'fill-gray-gray2 pointer-events-none': currentIndex === 0
          }"></svg>
      </button>
      <div class="text-main-text-bold">
        {{ currentIndex + 1 }} / {{ imageList.length }}
      </div>
      <button
        class="cursor-pointer mt-[-6px] disabled:cursor-not-allowed"
        (click)="nextSlide()"
        [disabled]="currentIndex === imageList.length - 1"
        [ngClass]="{
          'pointer-events-none': currentIndex === imageList.length - 1
        }">
        <svg
          class="fill-accent-base cursor-pointer hover:fill-secondary-base active:fill-secondary-additional"
          [icon]="'right-arrow'"
          iconCollection="button-icons-sprite"
          width="40px"
          height="40px"
          [ngClass]="{
            'fill-gray-gray2 pointer-events-none':
              currentIndex === imageList.length - 1
          }"></svg>
      </button>
    </div>
  </div>
</section>

<section class="mt-15">
  <div class="w-70 mx-auto">
    <a href="/assets/pdf/glossary.pdf" download>
      <app-button
        [title]="'Завантажити PDF'"
        [buttonType]="'Primary'"
        [svg]="true">
        <svg
          width="20px"
          height="20px"
          icon="download"
          iconCollection="button-icons-sprite"
          class="svg fill-gray-white mt-1 mr-2"></svg>
      </app-button>
    </a>
  </div>
</section>
<section class="flex my-30 items-center content-container">
  <div
    class="bg-primary-base w-[10px] h-[68px] rounded rounded-tl-[10px] rounded-bl-[10px] mr-5"></div>
  <p class="text-h3-subheading text-gray-dark">
    Говорячи чи пишучи про деменцію, звертайтеся до сторінки
    <a
      routerLink="/about-us/news-and-media/media-center/how-to-talk-about-dementia-in-the-media"
      class="text-accent-base underline underline-offset-5"
      >“Як говорити про деменцію в медіа”</a
    >
  </p>
</section>
