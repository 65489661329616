<ul *ngFor="let otherPublicationsCard of otherPublicationsCards">
  <li
    class="py-10 px-10 mx-auto w-295 rounded-30 border-2 border-gray-stroke mb-4">
    <div class="flex mb-6">
      <svg
        width="21px"
        height="19px"
        icon="book"
        iconCollection="button-icons-sprite"
        class="svg text-gray-details"></svg>
      <span class="text-gray-details text-details px-3">{{
        otherPublicationsCard.text
      }}</span>
      <span class="text-gray-details text-details">{{
        otherPublicationsCard.data
      }}</span>
    </div>
    <h2 class="text-h3-subheading text-gray-dark mb-5">
      {{ otherPublicationsCard.title }}
    </h2>
    <div class="flex">
      <p class="text-smaller-bold text-accent-base pr-3">
        {{ otherPublicationsCard.link }}
      </p>
      <svg
        width="28px"
        height="16px"
        icon="arrow-right-medium"
        iconCollection="button-icons-sprite"
        class="mt-1 flex justify-items-center fill-accent-base group-hover/help:fill-secondary-additional group-active/help:fill-secondary-additional w-7"></svg>
    </div>
  </li>
</ul>
