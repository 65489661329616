import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { Observable, of } from 'rxjs';
import newsCardInfo from 'src/app/models/newsCard.interface';
import { newsCardConfig } from 'src/mockData/newsCardConfig';

@Injectable({
  providedIn: 'root',
})
export class NewsApiService {
  newsCardConfigArray: newsCardInfo[] = newsCardConfig;
  urlNewsApi: string = environment.apiUrl + 'news/';
  constructor(private http: HttpClient) {}

  getNewsArray(val: string): Observable<newsCardInfo[]> {
    let newsArray: newsCardInfo[];
    if (val === 'new') {
      newsArray = this.newsCardConfigArray;
      // return this.http.get<newsCardInfo[]>(this.urlNewsApi)
    } else if (val === 'old') {
      newsArray = this.newsCardConfigArray.slice().reverse();
      // return this.http.get<newsCardInfo[]>(this.urlNewsApi)
    } else {
      throw new Error(`Something went wrong(`);
    }
    return of(newsArray);
  }

  getNewsById(id: string): Observable<newsCardInfo> {
    const cardNews = this.newsCardConfigArray.find(el => el.id === +id);
    if (cardNews) {
      return of(cardNews);
    } else {
      throw new Error(`Card news with id ${id} not found.`);
    }
    // return this.http.get<newsCardInfo>(this.urlNewsApi + id);
  }
}
