import { Component, Input, OnInit } from '@angular/core';
import { IContact } from 'src/app/models/contact.interface';

@Component({
  selector: 'app-contact-item',
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.css'],
})
export class ContactItemComponent implements OnInit {
  @Input()
  public contact!: IContact;

  constructor() {}

  ngOnInit(): void {}
}
