import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-submenu-join',
  templateUrl: './submenu-join.component.html',
  styleUrls: ['./submenu-join.component.css'],
})
export class SubmenuJoinComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
