import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-request',
  templateUrl: './send-request.component.html',
  styleUrls: ['./send-request.component.css'],
})
export class SendRequestComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
