import IVolunteer from 'src/app/models/volunteer.interface';

export const volunteersMock: IVolunteer[] = [
  {
    title: 'Ірина Шевченко',
    text: 'Венчурна інвесторка, українська медіа-експертка.',
    imgUrl: 'assets/images/volunteers/volunteers-shevchenko.jpg',
  },
  {
    title: 'Ігор Литвиненко',
    text: 'Підприємець, меценат, голова холдингу Global Spirits',
    imgUrl: 'assets/images/volunteers/volunteers-lytvynenko.jpg',
  },
  {
    title: 'Марта Лебеденко',
    text: 'Голова Опікунської ради НДСЛ "Охматдит"',
    imgUrl: 'assets/images/volunteers/volunteers-lebedenko.jpg',
  },
  {
    title: 'Ігор Литвиненко',
    text: 'Підприємець, меценат, голова холдингу Global Spirits',
    imgUrl: 'assets/images/volunteers/volunteers-lytvynenko.jpg',
  },
  {
    title: 'Марта Лебеденко',
    text: 'Голова Опікунської ради НДСЛ "Охматдит"',
    imgUrl: 'assets/images/volunteers/volunteers-lebedenko.jpg',
  },
];
