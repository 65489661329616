import { Component, OnInit } from '@angular/core';
import IInfoPage from '../../../../models/infoPage.interface';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  cards: IInfoPage[] = [
    {
      title: 'щомісячні звіти',
      text: 'До кінця поточного місяця ми готуємо фінансовий звіт за попередній місяць, де зазначаємо всі внески благодійників і те, яку допомогу отримали діти.',
      link: '/about-us/about-fund/reports/reports-monthly',
    },
    {
      title: 'річні звіти',
      text: 'Щороку ми готуємо звіт за попередній рік. У цьому звіті видно масштаб допомоги благодійників і те, скільки дитячих життів змінила ця допомога.',
      link: '/about-us/about-fund/reports/reports-yearly',
    },
    {
      title: 'фінансові звіти',
      text: 'У цьому розділі ви можете побачити скан-копії податкових звітів за попередні роки.',
      link: '/about-us/about-fund/reports/reports-tax',
    },
    {
      title: 'Установчі документи',
      text: 'У цьому розділі ми зібрали установчі документи, які фонд повинен мати згідно із законодавством.',
      link: '/about-us/about-fund/reports/reports-docs',
    },
  ];
}
