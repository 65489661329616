<app-page-banner-item
  title="Про деменцію"
  text="Що таке деменція, як поставити діагноз і як зменшити ризик розвитку деменції або відстрочити її вплив"
  [imgUrl]="
    './assets/images/banner/banner-about-dementia.jpg'
  "></app-page-banner-item>

<ng-container *ngIf="cards.length === 0; else content">
  <div
    class="my-10 content-container flex justify-center items-center h-100 rounded-30 relative bg-gray-200 overflow-hidden">
    <div class="absolute top-0 left-0 w-full h-full">
      <div class="w-full h-full gradient-animation"></div>
    </div>
  </div>
</ng-container>

<ng-template #content>
  <app-info-cards [classList]="'flex my-30'" [cards]="cards"></app-info-cards>
</ng-template>
