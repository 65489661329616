<div
  class="flex flex-col w-95 h-87 p-10 border-[3px] border-gray-stroke rounded-30 odd:mr-5"
  #cardWidth>
  <div class="w-75 h-10 object-contain mb-5">
    <img src="{{ card.logo }}" alt="whiteBit" />
  </div>
  <div class="w-75 h-26.5 mb-12 text-gray-dark text-main-text">
    {{ card.description }}
  </div>
  <div>
    <app-button
      [buttonType]="'Secondary'"
      [title]="'Допомогти'"
      [routerLink]="card.linkTo"></app-button>
  </div>
</div>
