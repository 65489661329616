<section class="py-30 overflow-hidden" [ngClass]="BackgroundColorType">
  <div
    class="flex content-container justify-start flex-wrap gap-5 mb-5 md:mb-0 px-4 md:px-0">
    <h2
      class="text-h2-heading md:text-h1-heading uppercase xl:w-95 w-full text-start xl:text-left"
      [ngClass]="HeadingColorType">
      Як я можу допомогти?
    </h2>

    <p
      class="w-195 text-smaller-main md:text-main-text"
      [ngClass]="ParagraphColorType">
      Зміни починаються з мене, з тебе і з маленьких та великих кроків.
      Навчання, пошук та обмін інформацією про деменцію вже можуть стати першим
      зерном глобальних змін. Доєднуйтесь до створення дружнього до деменції
      середовища
    </p>
  </div>

  <app-adaptive-slider>
    <div
      class="flex justify-between items-center mt-10 mb-10 md:mb-15 flex-row gap-5">
      <app-help-card
        *ngFor="let helpCard of helpCards"
        [card]="helpCard"
        [type]="type"></app-help-card>
    </div>
  </app-adaptive-slider>
  <app-link-button
    [title]="'Переглянути всі варіанти'"
    [linkButtonType]="'Primary'"
    class="flex justify-center w-75 mx-auto mt-16 xl:mt-0"
    routerLink="/join">
  </app-link-button>
</section>
