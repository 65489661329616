import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  monthlyCardData,
  MonthlyDataArray,
} from 'src/mockData/monthlyReportsMock';
import {
  MonthlyCardData,
  MonthlyData,
  MonthData,
} from 'src/app/models/monthly-reports.interfaces';

@Component({
  selector: 'app-monthly-reports',
  templateUrl: './monthly-reports.component.html',
  styleUrls: ['./monthly-reports.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthlyReportsComponent implements OnInit {
  cardMonthView!: MonthData;
  monthlyData: MonthlyCardData = monthlyCardData;
  dataArray: MonthlyData[] = MonthlyDataArray;
  monthArray!: MonthData[];
  selectMonthValue = '';
  constructor() {}

  ngOnInit(): void {
    this.getAllYearsData(this.dataArray);
    const lastDataYear = this.dataArray.length - 1;
    const lastDataMonth = this.dataArray[lastDataYear].month.length - 1;
    this.monthArray = this.dataArray[lastDataYear].month;
    this.setCurrentData(lastDataYear, lastDataMonth);
  }

  public getAllYearsData(array: MonthlyData[]): number[] {
    const yearArray: number[] = [];
    array.forEach((year: MonthlyData) => {
      yearArray.push(year.year);
    });
    return yearArray;
  }

  public choseYearIndex(event: Event): number {
    const value = event.target as HTMLInputElement;
    this.selectMonthValue = '';
    this.monthArray = this.dataArray[+value.value].month;
    return +value.value;
  }

  public choseMonthIndex(event: Event): number {
    const value = event.target as HTMLInputElement;
    return +value.value;
  }

  public setCurrentData(year: number, month: number) {
    this.cardMonthView = this.dataArray[year].month[month];
    return this.cardMonthView;
  }
}
