<div
  class="flex flex-row wide-container relative h-100 bg-primary-base bg-[url('src/assets/images/bgPic/nezabutkaFlower.png')] bg-no-repeat bg-[top_right_13rem]">
  <div class="flex flex-row content-container">
    <div class="flex flex-col w-195 text-gray-white h-fit pt-25.75">
      <h2 class="text-h2-heading-bold h-fit mb-5">
        {{ bannerData.h2 | uppercase }}
      </h2>
      <p class="text-main-text h-fit">{{ bannerData.text }}</p>
    </div>
  </div>
</div>
