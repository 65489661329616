<section
  *ngIf="correctMember"
  class="container text-start text-gray-dark pb-30">
  <h3 class="text-h1-heading text-primary-base mb-5">
    {{ currentMember.title | uppercase }}
  </h3>
  <div class="h-135">
    <img
      alt="люди посміхаються"
      class="h-135 ml-6 mb-6 float-right rounded-30"
      src="{{ currentMember.img }}" />
    <h3 class="text-h3-subheading mb-5">
      {{ currentMember.position }}
    </h3>
    <div
      *ngFor="let paragraph of currentMember.text.split('/p')"
      [innerHTML]="paragraph"
      class="text-lg main-text pb-7"></div>
    <div class="pt-3 text-gray-dark flex gap-5" id="WhyDiagnosisNeed">
      <app-button
        *ngIf="true"
        buttonType="Secondary"
        class="w-55"
        routerLink="/about-us/our-people/fund-team"
        title="Вся команда"></app-button>

      <app-button
        buttonType="Primary"
        class="w-55"
        routerLink="/join/become-volunteer"
        title="Написати листа"></app-button>
    </div>
  </div>
</section>
<app-subscribe-news></app-subscribe-news>
