import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IVacanciesData } from 'src/app/models/vacancies.interface';
import { VacanciesApiService } from 'src/app/services/api/aboutUsPages/OurPeoplePages/vacanciesApi.service';

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.css'],
})
export class VacanciesComponent implements OnInit {
  constructor(private apiService: VacanciesApiService) {}
  menuItems: any[] = [];

  ngOnInit(): void {
    this.fetchVacancies().subscribe(data => {
      this.menuItems = data.map(vacancy => ({ ...vacancy, isCopy: false }));
    });
  }

  fetchVacancies(): Observable<IVacanciesData[]> {
    return this.apiService.getData();
  }
}
