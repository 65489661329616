import { Component, OnInit } from '@angular/core';
import { TabsHorizontal } from 'src/app/models/tabs-horizontal';
import { TabDataMainPageMock } from 'src/mockData/tabDataMainPageMock';

@Component({
  selector: 'app-about-dementia-content',
  templateUrl: './about-dementia-content.component.html',
  styleUrls: ['./about-dementia-content.component.css'],
})
export class AboutDementiaContentComponent implements OnInit {
  title = 'Про деменцію';
  textInfo =
    '<p class="mb-7">Деменція – це сукупність симптомів, спричинених певними розладами та хворобами, які впливають на роботу мозку. Це не є однією специфічною хворобою.</p><p>Деменція впливає на мислення, поведінку, а також на спроможність виконувати щоденні справи. Функціонування мозку погіршується до рівня, коли це впливає на нормальне соціальне та робоче життя людини.</p>';
  tabDataMainPage: TabsHorizontal[] = TabDataMainPageMock;
  constructor() {}

  ngOnInit(): void {}
}
