import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { linksOnSourceOfInformationMockData } from 'src/mockData/linksOnSourceOfInformationMockData';

@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticComponent implements OnInit, OnDestroy {
  public linksOnSourceOfInformation: any[] = linksOnSourceOfInformationMockData;
  private pdfSubscription!: Subscription;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.pdfSubscription && this.pdfSubscription.unsubscribe();
  }

  public downloadPdfFile(): void {
    this.pdfSubscription = this.http
      .get('/assets/pdf/statistics.pdf', {
        observe: 'response',
        responseType: 'blob',
      })
      .subscribe((res: any) => {
        let blob: Blob = res.body as Blob;
        console.log(blob);
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement('a');
        a.download = 'test';
        a.href = url;
        a.click();
      });
  }
}
