import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-care-consultation',
  templateUrl: './care-consultation.component.html',
  styleUrls: ['./care-consultation.component.css'],
})
export class CareConsultationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
