<div class="flex items-center justify-left content-container">
  <section class="flex pt-10 pb-20 gap-5 flex-wrap justify-left">
    <app-submenu-items-list
      class="flex w-95"
      [title]="'Регулярний внесок'"
      [queryParams]="{ subscribe: 'monthly' }"
      [titleLink]="'/join/donate'"></app-submenu-items-list>
    <app-submenu-items-list
      class="flex w-95"
      [title]="'Стати волонтером'"
      [titleLink]="'/join/become-volunteer'"></app-submenu-items-list>
    <app-submenu-items-list
      class="flex w-95"
      [title]="'Стати партнером фонду'"
      [titleLink]="'/join/become-partner'"></app-submenu-items-list>
    <app-submenu-items-list
      class="flex w-95"
      [title]="'Організувати збір коштів'"
      [titleLink]="'/join/become-partner'"></app-submenu-items-list>
  </section>
</div>
