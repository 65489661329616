import { Component, Input, OnInit } from '@angular/core';
import { IPreventionCard } from '../../../models/prevention-card.interface';

@Component({
  selector: 'app-prevention-card',
  templateUrl: './prevention-card.component.html',
  styleUrls: ['./prevention-card.component.css'],
})
export class PreventionCardComponent implements OnInit {
  @Input()
  public card!: IPreventionCard;

  constructor() {}

  ngOnInit(): void {}
}
