import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-more-info-card',
  templateUrl: './more-info-card.component.html',
  styleUrls: ['./more-info-card.component.css'],
})
export class MoreInfoCardComponent implements OnInit {
  @Input() moreInfo: any;
  constructor() {}

  ngOnInit(): void {}
}
