<app-page-banner-item
  [imgUrl]="'assets/images/banner/banner-crisis-assistance.jpg'"
  title="Кризова допомога"
  text="У цьому розділі ви побачите, яку саме допомогу отримали підопічні завдяки благодійникам та зможете ознайомитися з нашими установчими документами.">
</app-page-banner-item>

<section class="mb-30 container pt-30">
  <app-table-of-content [links]="links"></app-table-of-content>
</section>

<section id="help_with_generators" class="py-30 mb-10">
  <div class="w-295 mx-auto">
    <h2 class="text-h2-heading uppercase text-primary-base mb-5">
      Допомога з генераторами
    </h2>
    <div class="flex">
      <div class="w-147 mr-3">
        <p class="text-main-text text-gray-dark mb-7">
          Встановлення інвалідності відбувається за наявності хвороби, яка
          впливає на фізичні та розумові здібності людини, у тому числі, сюди
          відносять розлади психіки та поведінки, які тягнуть за собою часткову
          або повну втрату пам’яті, здатності мислення, нездатність керувати
          свідомістю, що перешкоджає виконанню найпростіших функцій.
        </p>
        <p class="text-main-text text-gray-dark mb-7">
          Інвалідність встановлюється відповідно до міри впливу хвороби на
          життєдіяльність та організм людини. Встановлення інвалідності
          відбувається за наявності хвороби, яка впливає на фізичні та розумові
          здібності людини, у тому числі, сюди відносять розлади психіки та
          поведінки, які тягнуть за собою часткову або повну втрату пам’яті,
          здатності мислення, нездатність керувати свідомістю, що перешкоджає
          виконанню найпростіших функцій.
        </p>
        <app-link-button
          [linkButtonType]="'Primary'"
          [title]="'Залишити заявку'"
          class="w-55 flex mt-10"></app-link-button>
      </div>

      <div class="w-140">
        <app-carousel (changeSlide)="onChangeSlide($event)">
          <app-carousel-slide *ngFor="let ListOfImage of arrayListImage">
            <img class="rounded-3xl max-w-none" src="{{ ListOfImage.src }}" />
          </app-carousel-slide>
        </app-carousel>
      </div>
    </div>
  </div>
</section>

<section id="assistance_with_relocation" class="bg-gray-card">
  <div class="w-295 mx-auto container pt-28 flex pb-20">
    <div class="w-147">
      <h2 class="text-h2-heading uppercase text-primary-base mb-5">
        Допомога з переміщенням та поселенням
      </h2>
      <p class="text-main-text text-gray-dark mb-7">
        Встановлення інвалідності відбувається за наявності хвороби, яка впливає
        на фізичні та розумові здібності людини, у тому числі, сюди відносять
        розлади психіки та поведінки, які тягнуть за собою часткову або повну
        втрату пам’яті, здатності мислення, нездатність керувати свідомістю, що
        перешкоджає виконанню найпростіших функцій.
      </p>
      <p class="text-main-text text-gray-dark mb-7">
        Інвалідність встановлюється відповідно до міри впливу хвороби на
        життєдіяльність та організм людини.
      </p>
      <h3 class="mt-15 text-h3-subheading text-primary-base">
        Фонди, що допомагають з евакуацією:
      </h3>
      <ul class="list-disc text-main-text text-accent-base ml-7 my-5">
        <li class="mb-2 underline underline-offset-4">
          <a class="cursor-pointer">"Назва фонду"</a>
        </li>
        <li class="mb-3 underline underline-offset-4">
          <a class="cursor-pointer">"Назва фонду"</a>
        </li>
        <li class="underline underline-offset-4">
          <a class="cursor-pointer">"Назва фонду"</a>
        </li>
      </ul>
    </div>
    <div class="ml-5">
      <app-crissis-assistance-form-help></app-crissis-assistance-form-help>
    </div>
  </div>
  <div class="flex w-295 mx-auto container pb-30">
    <div class="w-147">
      <h2 class="text-h3-subheading text-primary-base mb-5">Пансіонати</h2>
      <p class="text-main-text text-gray-dark mb-7">
        Встановлення інвалідності відбувається за наявності хвороби, яка впливає
        на фізичні та розумові здібності людини, у тому числі, сюди відносять
        розлади психіки та поведінки, які тягнуть за собою часткову або повну
        втрату пам’яті, здатності мислення, нездатність керувати свідомістю, що
        перешкоджає виконанню найпростіших функцій.
      </p>
      <p class="text-main-text text-gray-dark mb-7">
        Інвалідність встановлюється відповідно до міри впливу хвороби на
        життєдіяльність та організм людини.
      </p>
      <span
        ><a
          class="cursor-pointer text-main-text text-accent-base underline underline-offset-4"
          >Приватні пансіонати</a
        ></span
      >
    </div>
    <div>
      <img
        class="ml-4"
        src="../../../../../assets/images/crisis-assistance/map.jpg"
        alt="" />
    </div>
  </div>
</section>

<section id="help_abroad" class="container pt-30">
  <h2 class="text-h2-heading uppercase text-primary-base mb-5">
    Допомога за кордоном
  </h2>
  <div class="w-295 mx-auto flex">
    <div class="w-147">
      <p class="text-main-text text-gray-dark">
        Ми зібрали інформацію про країни Європи, що приймають українців. Тут ви
        знайдете інформацію про:
      </p>
      <ul class="list-disc text-main-text text-gray-dark ml-7">
        <li class="mb-2">права та можливості шукачів притулку</li>
        <li class="mb-2">медичну допомогу</li>
        <li class="mb-2">допомогу людям з деменцією та їх родинам</li>
        <li class="mb-2">допомогу людям з інвалідністю</li>
        <li class=" ">психологічну підтримку.</li>
      </ul>
    </div>
    <div>
      <img
        class="ml-4"
        src="../../../../../assets/images/crisis-assistance/rectangle.jpg"
        alt="" />
    </div>
  </div>
</section>

<section class="mt-30 container pb-20">
  <div class="bg-gray-card rounded-30 p-10 mt-15">
    <p class="">
      Якщо ви перебуваєте за кордоном і вам потрібна допомога, то зв’язуйтесь з
      нами – посилання на форму. І ми допоможемо вам, зв’яжемось з місцевою
      організацією та знайдемо рішення. Або можете зв’язатись з місцевою
      організацією самостійно.
    </p>
    <app-link-button
      url="/about-us/about-fund/reports"
      [linkButtonType]="'Primary'"
      [title]="'Залишити заявку'"
      class="w-55 flex mx-auto mt-10">
    </app-link-button>
  </div>
</section>
