<main class="content-container">
  <h1 class="text-h1-heading uppercase text-primary-base">Контакти</h1>

  <div class="flex justify-between">
    <address class="not-italic">
      <app-contact-item
        *ngFor="let contact of contacts"
        [contact]="contact"
        [ngClass]="contactItemMap.get(contact.id)">
      </app-contact-item>

      <section class="mt-30">
        <h2 class="text-h2-heading text-primary-base uppercase mb-10">
          Ми у соціальних мережах
        </h2>
        <div class="flex">
          <app-social-link
            class="w-12 mr-5"
            *ngFor="let socialLink of socialLinks"
            [socialLink]="socialLink"></app-social-link>
        </div>
      </section>
    </address>

    <section class="mb-30">
      <app-contact-form></app-contact-form>
    </section>
  </div>
</main>

<app-subscribe-news></app-subscribe-news>
