import IInfoPage from '../app/models/infoPage.interface';

export const joinCardConfig: IInfoPage[] = [
  {
    title: 'Регулярний платіж',
    text: 'Внески наших благодійників перетворюються на програми допомоги та ліки для людей з деменцією, я...',
    link: '/join/donate',
  },
  {
    title: 'Організувати збір коштів',
    text: 'Ви можете самостійно організувати збір коштів для людей з деменцією. Присвятіть збір своєму дню...',
    link: '/join/become-partner',
  },
  {
    title: 'Стати партнером',
    text: 'Ставайте компанією-партнером фонду і допомагайте людям з деменцією з нами. Разом ми створимо країн...',
    link: '/join/become-partner',
  },
  {
    title: 'Стати волонтером',
    text: 'Завдяки волонтерам фонд зможе надавати допомогу людям з деменцією ефективніше та швидше....',
    link: '/join/become-volunteer',
  },
];
