import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class PartnersConfig {
  constructor(private http: HttpClient) {}

  getDalgakiran(): Observable<any[]> {
    return this.http.get<any[]>('/assets/images/partners/dalgakiran.json');
  }
  getLetigationGroup(): Observable<any[]> {
    return this.http.get<any[]>(
      '/assets/images/partners/letigation_group.json',
    );
  }
  getGenerator(): Observable<any[]> {
    return this.http.get<any[]>('/assets/images/partners/generator.json');
  }
  getAlzheimer(): Observable<any[]> {
    return this.http.get<any[]>('/assets/images/partners/alzheimer.json');
  }
  getMedikom(): Observable<any[]> {
    return this.http.get<any[]>('/assets/images/partners/medikom.json');
  }
  getSocialPolicy(): Observable<any[]> {
    return this.http.get<any[]>('/assets/images/partners/social_policy.json');
  }
  getBrainHealth(): Observable<any[]> {
    return this.http.get<any[]>('/assets/images/partners/brain_health.json');
  }
  getGerontology(): Observable<any[]> {
    return this.http.get<any[]>('/assets/images/partners/gerontology.json');
  }
  getTest(): Observable<any[]> {
    return this.http.get<any[]>('/assets/images/partners/gerontology.json');
  }
}
