import { PressRelaseCardInterface } from 'src/app/models/press-relase-card.interface';

export const pressReleasesMockData: PressRelaseCardInterface[] = [
  {
    id: 1,
    date: '22.09.2021',
    title:
      'Благодійний фонд «Незабутні» запустив ініціативу для допомоги людям з деменцією в умовах війни',
    text: 'Благодійний фонд «Незабутні» за підтримки Фундації MSF, що є частиною міжнародної незалежної медичної організації "Лікарі без кордонів" (Médecins Sans Frontières) запустив ініціативу для допомоги людям з деменцією та літнім людям в умовах війни. На платформі Незабутні UNITED зібрані ресурси для надання інформаційної, медичної і психологічної допомоги в Україні та за кордоном.',
  },
  {
    id: 2,
    date: '22.09.2021',
    title:
      'Деменція є найбільшою проблемою для здоров’я: посилює потребу у взаємопов’язаній інформованій системі охорони здоров’я',
    text: 'У зв’язку з тим, що деменція стає найбільшою проблемою для здоров’я австралійців віком від 65 років, Dementia Australia посилює потребу у взаємопов’язаній системі охорони здоров’я, яка базується на деменції, щоб гарантувати, що кожен, хто постраждав від деменції, отримує відповідну підтримку та догляд протягом усього періоду хвороби.',
  },
];
