<div class="content-container flex flex-col text-gray-dark">
  <div class="flex flex-row justify-between pt-0.5 pl-0.5">
    <div class="flex w-95 h-42.5">
      <h2 class="text-primary-base text-h1-heading-bold h-fit">
        {{ reportsData.title | uppercase }}
      </h2>
    </div>
    <div class="flex flex-col w-195 h-fit">
      <p *ngFor="let p of reportsData.text" class="mb-6.75">{{ p }}</p>
    </div>
  </div>
  <div class="grid grid-cols-3 gap-5 mt-8.5">
    <div *ngFor="let card of reportsData.reportsLinks" class="group">
      <div
        (click)="onOpenFinancialReport()"
        class="flex flex-col w-95 h-39 justify-between border-2 border-gray-stroke cursor-pointer py-7.75 pl-10 mr-5 rounded-30 group-hover:border-secondary-base duration-300">
        <div class="text-main-text-bold w-75">{{ card.title }}</div>
        <div
          class="flex flex-row justify-start text-accent-base cursor-pointer group-hover:text-secondary-base active:text-secondary-additional duration-300"
          routerLink="{{ card.link }}">
          <p class="text-smaller-bold pr-3">Відкрити</p>
          <svg
            class="fill-accent-base group-hover:fill-secondary-base active:fill-secondary-additional duration-300"
            [icon]="'right-arrow-small'"
            iconCollection="button-icons-sprite"
            height="26"
            width="30"></svg>
        </div>
      </div>
    </div>
  </div>
</div>
