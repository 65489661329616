import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-bills-card',
  templateUrl: './bills-card.component.html',
  styleUrls: ['./bills-card.component.css'],
})
export class BillsCardComponent implements OnInit {
  @ViewChild('cardWidth', { static: true, read: ElementRef })
  cardWidth!: ElementRef;

  @Input()
  card!: any[];
  constructor() {}

  ngOnInit(): void {}

  copyProps(data: string) {
    navigator.clipboard.writeText(data).then(() => {
      console.log(`Text ${data} was copied!`);
      //todo вспливаючі підказки
      alert(`Text ${data} was copied!`);
    });
  }
}
