import ILetter from 'src/app/models/letterForDonation.interface';

export const letterForDonationMockData: ILetter[] = [
  {
    textHTML: `<img src="../../../../assets/images/letters/logo.jpg" />
  <div class="px-8">
    <p class="font-sans text-sm font-normal text-gray-dark mt-10 mb-2">
      Дорогий благодійнику, 
    </p>
    <p class="font-sans text-sm font-normal text-gray-dark mb-10">
      Дякуємо Вам за допомогу та вітаємо у спільноті, дружній до людей з
      деменцією!
    </p>
    <p class="font-sans text-sm font-normal text-gray-dark mb-2">
      Ваш внесок буде спрямовано на підтримку людей з деменцією згідно з
      програмами фонду. Ви можете дізнатися про напрямки допомоги, а також
      переглянути звіти фонду на нашому сайті 
      <a
        class="text-accent-base hover:text-accent-additional underline cursor-pointer"
        href="/about-us/about-fund/reports"
        >nezabutni.org</a
      >. 
    </p>
    <p class="font-sans text-sm font-normal text-gray-dark mb-10">
      Ось деякі приклади нашої роботи. Так, з початку повномасштабного
      вторгнення ми поставили в Україну 14 тонн ліків та 27 генераторів для
      геріатричних пансіонатів та психоневрологічних інтернатів. Ми проводимо
      безкоштовні групові та індивідуальні сесії з психологами. Наші спеціалісти
      проводять консультації з медичних та юридичних питань. 
    </p>
  </div>

  <div class="bg-gray-card pt-2 pb-1 px-8">
    <p class="font-sans text-sm font-normal text-gray-dark">
      Якщо Вам цікаво, яким ще чином можна допомогти людям з деменцією,
      переходьте на сторінку сайту
      <a
        class="text-accent-base hover:text-accent-additional underline cursor-pointer font-sans text-sm font-normal"
        href="/join"
        >Долучитися</a
      >, напишіть нам на електронну адресу   
      <a 
      href="mailto:info@nezabutni.org"
        class="text-accent-base hover:text-accent-additional underline cursor-pointer font-sans text-sm font-normal"
        > info@nezabutni.org</a
      > або зателефонуйте за номером
      <a
        class="text-accent-base hover:text-accent-additional underline cursor-pointer font-sans text-sm font-normal"
        href="https://nezabutni.org/"
        >+380676278926.</a
      >
    </p>
  </div>

  <div class="px-8 mt-12">
    <p class="font-sans text-sm font-normal text-gray-dark">
      Ще раз дякуємо Вам за підтримку! Разом ми зможемо полегшити життя людей з
      деменцією, а також зробити його комфортним, гідним та сповненим сенсу!
    </p>
  </div>
  <div class="flex px-8 my-10">
    <img src="../../../../assets/images/letters/foto.jpg" />
    <p class="font-sans text-sm font-bold text-gray-dark ml-5 mt-5">
      З повагою <br />Ірина Шевченко <br />
      Засновниця і директорка  фонду “Незабутні”
    </p>
  </div>

  <p class="text-gray-medium font-sans text-xs font-normal px-8 mb-10">
    Ви отримали цей лист, тому що зробили благодійний внесок для допомоги людям
    з деменцією та підтримки діяльності фонду “Незабутні”. <br />Уповноваженим
    відправником цього електронного листа є благодійний фонд
    <a
      class="underline cursor-pointer font-sans text-xs font-normal"
      href="https://nezabutni.org/"
      >“Незабутні”.</a
    >
  </p>`,
  },
];

export const EmailForSubscriptionMockData: ILetter[] = [
  {
    textHTML: `   <img src="../../../../assets/images/letters/emailLogo.jpg" />
  <div class="px-8">
    <p class="font-sans text-sm font-normal text-gray-dark my-10">
      Ми будемо ділитися з Вами історіями людей з деменцією та їхніх родин,
      результатами досліджень, новинами про доступні послуги та ресурси, а також
      інформацією про те, як Ви можете долучитися до покращення життя людей
      з деменцією в Україні.
    </p>
    <h3 class="font-sans text-sm font-bold text-primary-base mb-2">Про нас</h3>
    <p class="font-sans text-sm font-normal text-gray-dark mb-10">
      Благодійний фонд “Незабутні” існує з 2021 року. Наша мета – надавати
      допомогу та підтримку понад 500 тисячам людей з деменцією в Україні та
      їхнім родинам, боротися за їхні права та підвищувати обізнаність
      суспільства про деменцію.
    </p>
    <h3 class="font-sans text-sm font-bold text-primary-base mb-2">
      Підтримати людей з деменцією
    </h3>
    <p class="font-sans text-sm font-normal text-gray-dark mb-10">
      Уже скоро Ви отримаєте перший лист з новинами від фонду “Незабутні”.
      А поки можете долучитися до допомоги родинам людей з деменцією, зробивши

      <a
        class="text-accent-base hover:text-accent-additional underline cursor-pointer"
        href="/about-us/about-fund/reports"
        >благодійний внесок.</a
      >. 
    </p>
    <h3 class="font-sans text-sm font-bold text-primary-base mb-2">
      Отримати допомогу
    </h3>
    <p class="font-sans text-sm font-normal text-gray-dark mb-10">
      Якщо Вам потрібна будь-яка допомога чи інформація щодо деменції,
      відвідайте наш сайт
      <a
        class="text-accent-base hover:text-accent-additional underline cursor-pointer font-sans text-sm font-normal"
        href="/join"
        >nezabutni.org</a
      >, пишіть нам на електронну адресу
      <a
        href="mailto:info@nezabutni.org"
        class="text-accent-base hover:text-accent-additional underline cursor-pointer font-sans text-sm font-normal">
        info@nezabutni.org</a
      >
      або телефонуйте за номером
      <a
        class="text-accent-base hover:text-accent-additional underline cursor-pointer font-sans text-sm font-normal"
        href="https://nezabutni.org/"
        >+380676278926.</a
      >
    </p>
  </div>

  <div class="flex px-8">
    <p class="font-sans text-sm font-bold text-gray-dark mb-10">
      Ми тут, щоб допомогти – <br />
      Команда фонду “Незабутні”
    </p>
  </div>

  <div class="bg-gray-card pt-7 px-8 py-8">
    <p class="font-sans text-sm font-bold text-primary-base text-center mb-4">
      Залишайтеся з нами на зв'язку в соціальних мережах
    </p>
    <ul class="flex justify-center">
      <li class="px-2.5">
        <a
          class="text-accent-base hover:text-accent-additional cursor-pointer"
          href="https://www.instagram.com/nezabutni_/"
          ><img src="../../../../assets/images/letters/Instagram.png"
        /></a>
      </li>
      <li class="px-2.5">
        <a
          class="text-accent-base hover:text-accent-additional cursor-pointer"
          href="https://www.facebook.com/nezabutni.org"
          ><img src="../../../../assets/images/letters/Facebook.png"
        /></a>
      </li>
      <li class="px-2.5">
        <a
          class="text-accent-base hover:text-accent-additional cursor-pointer"
          href="https://www.youtube.com/channel/UCIhdADy2Q8xzImxhzQK549w/featured"
          ><img src="../../../../assets/images/letters/YouTube.png"
        /></a>
      </li>
      <li class="px-2.5">
        <a
          class="text-accent-base hover:text-accent-additional cursor-pointer"
          href="https://t.me/NezabutniCF"
          ><img src="../../../../assets/images/letters/Telegram.png"
        /></a>
      </li>
      <li class="px-2.5">
        <a
          class="text-accent-base hover:text-accent-additional cursor-pointer"
          href="https://invite.viber.com/?g2=AQBrcrOiC1eYD0%2FwbncaUKw%2BDHJik%2FgYMT8bUv%2F0H3d28l1XNHIZLBdrDQKEoglQ&lang=en"
          ><img src="../../../../assets/images/letters/Viber.png"
        /></a>
      </li>
    </ul>
  </div>

  <p class="text-gray-medium font-sans text-xs font-normal px-8 my-10">
    Ви отримали цей лист, тому що підписалися на розсилку новин від фонду
    “Незабутні”. Якщо ви хочете повністю припинити отримувати від нас новини, ви
    можете відписатися від розсилки,
    <a
      class="underline cursor-pointer font-sans text-xs font-normal"
      href="https://nezabutni.org/"
      >натиснувши тут.</a
    >
    <br />
    Уповноваженим відправником цього електронного листа є благодійний фонд
    <a
      class="underline cursor-pointer font-sans text-xs font-normal"
      href="https://nezabutni.org/"
      >“Незабутні”.</a
    >
  </p>`,
  },
];
