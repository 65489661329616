import { Component, OnInit } from '@angular/core';
import { advocacyInterface } from 'src/app/models/advocacy.interface';
import { AdvocacyMockData } from 'src/mockData/advocacyMockData';
@Component({
  selector: 'app-support-for-families',
  templateUrl: './support-for-families.component.html',
  styleUrls: ['./support-for-families.component.css'],
})
export class SupportForFamiliesComponent implements OnInit {
  advocacyInfo: advocacyInterface[] = AdvocacyMockData;
  constructor() {
    this.links = [
      {
        id: 'NationalDementiaResponse',
        title: 'Національний план з реагування на деменцію в Україні',
      },
      {
        id: 'RepresentationInternational',
        title: 'Представництво в міжнародних та національних спільнотах',
      },
    ];
  }

  protected links: { readonly id: string; readonly title: string }[] = [];
  arrayListImage: any = [
    { src: '../../../../../assets/images/advocacy/3.jpg' },
    { src: '../../../../../assets/images/advocacy/3.jpg' },
    { src: '../../../../../assets/images/advocacy/3.jpg' },
  ];

  arrayListImage2: any = [
    { src: '../../../../../assets/images/advocacy/4.jpg' },
    { src: '../../../../../assets/images/advocacy/1.jpg' },
    { src: '../../../../../assets/images/advocacy/2.jpg' },
  ];

  onChangeSlide(value: string) {
    if (value === 'nextSlide') {
      const deletedElement = this.arrayListImage.shift();
      console.log(deletedElement);
      this.arrayListImage.push(deletedElement);
    }
    if (value === 'prevSlide') {
      const deletedElement = this.arrayListImage.pop();
      this.arrayListImage.unshift(deletedElement);
    }
  }

  onChangeSlide2(value: string) {
    if (value === 'nextSlide') {
      const deletedElement = this.arrayListImage2.shift();
      console.log(deletedElement);
      this.arrayListImage2.push(deletedElement);
    }
    if (value === 'prevSlide') {
      const deletedElement = this.arrayListImage2.pop();
      this.arrayListImage2.unshift(deletedElement);
    }
  }

  ngOnInit(): void {}
}
