import IPartnersLinks from 'src/app/models/partners.links.interface';

export const websitelinks: IPartnersLinks[] = [
  {
    link: 'https://dalgakiran.ua/uk/',
  },
  {
    link: 'https://llg.com.ua/en/home-page/',
  },
  {
    link: 'https://generator.ua/',
  },
  {
    link: 'https://www.alzint.org/',
  },
  {
    link: 'https://medikom.ua/',
  },
  {
    link: 'https://www.msp.gov.ua/',
  },
  {
    link: 'https://www.gbhi.org/',
  },
  {
    link: 'http://geront.kiev.ua/en',
  },
  {
    link: 'http://geront.kiev.ua/en',
  },
];
