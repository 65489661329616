import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { BreakpointObserverService } from 'src/app/services/breakpoint-observer.service';

@Component({
  selector: 'app-adaptive-slider',
  templateUrl: './adaptive-slider.component.html',
  styleUrls: ['./adaptive-slider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdaptiveSliderComponent implements OnInit, AfterViewInit {
  @ViewChild('sliderTrack') sliderTrack!: ElementRef;
  public currentIndex: number = 0;
  private sliderWidth!: number;
  private slideWidth!: number;
  public slidesPerPage!: number;
  public lastEl!: number;
  private isMobile$ = this.points.isMobile();
  private mobile: boolean = false;

  constructor(private points: BreakpointObserverService) {}

  ngOnInit() {
    this.isMobile$.subscribe(el => {
      if (el) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.sliderWidth = this.sliderTrack.nativeElement.children[0].offsetWidth;
    this.slideWidth =
      this.sliderTrack.nativeElement.children[0].children[0].offsetWidth;
    this.slidesPerPage = Math.floor(this.sliderWidth / this.slideWidth);
    this.lastEl =
      this.sliderTrack.nativeElement.children[0].children.length - 1;
  }

  onNextSlide() {
    if (this.currentIndex < this.lastEl) {
      this.currentIndex++;
      this.sliderTrack.nativeElement.style.transform = `translateX(-${
        this.slideWidth * this.currentIndex + 20 * this.currentIndex
      }px)`;
    }
  }

  onPrevSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.sliderTrack.nativeElement.style.transform = `translateX(-${
        this.slideWidth * this.currentIndex + 20 * this.currentIndex
      }px)`;
    }
  }
}
