<app-page-banner-item
  [title]="becomePatnerData.banerTitle"
  [text]="becomePatnerData.banerText"
  [imgUrl]="becomePatnerData.bannerImg">
  <div class="flex mt-5">
    <div class="w-55 mr-11.75 cursor-pointer">
      <app-link-button [title]="becomePatnerData.buttonText"></app-link-button>
    </div>
    <div class="text-gray-white flex items-center">
      <svg
        (click)="copyProps(becomePatnerData.email)"
        width="28px"
        height="28px"
        icon="copy"
        iconCollection="button-icons-sprite"
        class="svg fill-gray-white cursor-pointer hover:fill-secondary-additional active:fill-secondary-additional pr-2.5"></svg>
      <a href="mailto:{{ becomePatnerData.email }}" class="w-fit h-fit">{{
        becomePatnerData.email
      }}</a>
    </div>
  </div>
</app-page-banner-item>
<div class="content-container my-30">
  <div class="text-gray-dark text-main-text">
    <div class="flex flex-col justify-between mb-10">
      <h2 class="text-primary-base uppercase text-h2-heading w-95 mb-5">
        {{ becomePatnerData.title }}
      </h2>
      <div class="w-full" [innerHTML]="becomePatnerData.text"></div>
    </div>
    <div class="mb-20">
      <app-info-cards [cards]="becomePatnerData.cardsWithLink"></app-info-cards>
    </div>
    <div class="w-55 mx-auto">
      <app-link-button
        [title]="'Партнери фонду'"
        [linkButtonType]="'Secondary'"
        routerLink="/about-us/our-people/our-partners">
      </app-link-button>
    </div>
  </div>
</div>
