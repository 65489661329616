<div class="flex items-center justify-between content-container">
  <section class="flex pt-10 pb-20 gap-5">
    <div class="block">
      <app-submenu-items-list
        *ngFor="let item of submenuWithoutSubMenuItems; let i = index"
        class="flex mx-auto w-95"
        [title]="item.title"
        [titleLink]="item.link"></app-submenu-items-list>
    </div>
    <app-submenu-items-list
      *ngFor="let item of submenuWithSubMenuItems; let i = index"
      class="flex mx-auto w-95"
      [title]="item.title"
      [links]="item.subMenuItems"
      [titleLinkId]="item.titleLinkId ? item.titleLinkId : ''"
      [titleLink]="item.link"></app-submenu-items-list>
  </section>
</div>
