<section class="flex justify-center">
  <h1 class="text-primary-base uppercase text-h1-heading w-full md:w-95 mr-5">
    Ключові факти про деменцію
  </h1>
  <p class="text-gray-dark text-main-text w-full md:w-195">
    Ми підготували ключові факти та тези, які доцільно висвітлювати, говорячи
    про деменцію. Тут ви знайдете інформацію про синдром, актуальні дані щодо
    деменції та профілактики, а також основну інформацію про фонд.
  </p>
</section>

<section class="mt-20">
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="links"></app-table-of-content>
</section>

<section id="WhatIsDementia" class="py-30 flex gap-5 content-container">
  <div>
    <h2 class="text-h2-heading text-primary-base">Що таке деменція?</h2>
    <p class="text-gray-dark text-main-text mt-5">
      Деменція — це синдром, що вражає мозок та призводить до стійкого та
      прогресивного  порушення його роботи. Терміном “Деменція” описують
      сукупність симптомів,  спричинених різними хворобами та станами, що
      призводять до порушення пам’яті,  мислення, мовлення, уваги, поведінки.
      Деменція впливає на мислення, поведінку, а також на спроможність
      виконувати щоденні справи. Функціонування мозку погіршується до рівня,
      коли це впливає на нормальне соціальне та професійне життя людини.
      Деменція — це не частина нормального процесу старіння і не одна специфічна
      хвороба. Деменція — це прогресуючий і на сьогоднішній день невиліковний
      синдром.
    </p>
  </div>
  <img
    src="assets/images/key-facts-dementia/whatIsDementia.jpg"
    alt="photo"
    class="w-145 h-100 rounded-30" />
</section>

<section id="DementiaInNumbers" class="bg-gray-card py-30">
  <div class="content-container">
    <h2 class="text-h2-heading text-primary-base">Деменція в цифрах</h2>
    <ul class="mt-5 text-gray-dark text-main-text list-disc">
      <li>
        У світі налічується більше 55 мільйонів людей з деменцією, і
        прогнозується 139 млн у 2050  році.
      </li>
      <li>В Україні більше 500 тисяч людей мають деменцію.</li>
      <li>Деменція — це сьома причина смертності в світі. </li>
      <li>
        90% людей, що живуть з деменцією, старші 65-ти років, однак серед людей
        літнього віку лише 10% мають деменцію.
      </li>
      <li>
        Деменція є мало продіагностованою. Недодіагностика деменції у світі
        складає близько 75%. У  країнах з низьким та середнім рівнем доходу
        більше 90% випадків деменції не продіагностовані.
      </li>
      <li>
        Найпоширеніша причина деменції у світі – хвороба Альцгеймера (60-70%
        захворювань). До інших причин належать: лобно-скронева деменція, судинна
        деменція, хвороба з Тільця Леві та інші.
      </li>
    </ul>
    <div class="w-70 mt-15">
      <app-button [title]="'Більше про статистику'" [buttonType]="'Secondary'">
      </app-button>
    </div>
  </div>
</section>

<section id="PreventionOfDementia" class="py-30 content-container">
  <div class="flex gap-5">
    <div>
      <h2 class="text-h2-heading text-primary-base">Профілактика деменції</h2>
      <p class="text-gray-dark text-main-text mt-5">
        Дбати про здоров’я мозку потрібно завжди, незалежно від віку. Особливо
        важливо це робити при досягненні середнього віку, оскільки саме в цей
        час у мозку починають відбуватися зміни.  Існують фактори ризику
        розвитку деменції, на які ми не можемо вплинути: вік, гени та стать.
        Так, ризик захворіти збільшується з віком, хоча деменція не є нормальним
        перебігом старіння. Наявність успадкованого гену збільшує ризик розвитку
        синдрому, але не свідчить про його появу на 100%. У жінок деменція
        зустрічається в 3 рази частіше, ніж у чоловіків, з невідомих причин.
        Разом з тим, існують фактори, на які ми можемо вплинути. Сформувавши
        певні щоденні звички можна знизити ризик розвитку деменції на 40%. 
      </p>
    </div>

    <img
      src="assets/images/key-facts-dementia/PreventionOfDementia.jpg"
      alt="photo"
      class="w-145 h-100 rounded-30" />
  </div>
  <ul class="mt-5 text-gray-dark text-main-text list-decimal">
    <p class="text-left">
      Згідно з ВООЗ, необхідно слідувати наступним 12 рекомендаціям:
    </p>
    <div class="ml-8">
      <li>Бути фізично активним,</li>
      <li>Не палити,</li>
      <li>Харчуватися збалансовано, по прикладу середземноморської дієти,</li>
      <li>Не зловживати алкоголем, </li>
      <li>
        Тренувати когнітивні функції: виконувати вправи та заняття на увагу,
        пам’ять, концентрацію, 
      </li>
      <li>
        Бути соціально активним: зустрічатися з друзями, відвідувати
        різноманітні суспільні заходи,
      </li>
      <li>Тримати вагу в нормі, </li>
      <li>
        Слідкувати за рівнем тиску та вчасно відвідувати лікаря, у разі
        наявності гіпертензії, 
      </li>
      <li>
        Слідкувати за рівнем цукру та вчасно відвідувати лікаря, у разі
        наявності діабету, 
      </li>
      <li>Контролювати рівень холестерину,</li>
      <li>У разі початку депресії працювати з психотерапевтом і психіатром,</li>
      <li>
        Дбати про свій слух і слідкувати за своїм станом у разі погіршення чи
        втрати слуху.
      </li>
    </div>
  </ul>
</section>

<section id="WhoAreUnforgettable" class="bg-gray-card py-30">
  <div class="content-container">
    <h2 class="text-h2-heading text-primary-base">Хто такі “Незабутні”?</h2>
    <p class="mt-5">
      Фонд “Незабутні” надає допомогу та підтримку людям з деменцією та їх
      доглядальникам, а також працює над створенням суспільства, дружнього до
      людей з деменцією. Ми є членами двох міжнародних організацій –
      <a
        target="_blank"
        href="https://www.alzint.org/"
        class="text-accent-base underline underline-offset-5"
        >Alzheimer’s Disease International</a
      >
      та
      <a
        target="_blank"
        href="https://www.alzheimer-europe.org/"
        class="text-accent-base underline underline-offset-5"
        >Alzheimer Europe.</a
      >
      Візія фонду – люди з деменцією та їх родичі живуть наповненим, комфортним
      життям, сповненим сенсів. Місія фонду – надавати допомогу та підтримку
      людям з деменцією та їх родичам, підвищувати обізнаність та об'єднувати
      зусилля всіх зацікавлених сторін задля створення суспільства, дружнього до
      людей з деменцією.
    </p>
  </div>
</section>

<section class="py-30 content-container">
  <app-accordion
    [AccordionsList]="linksDem"
    [classList]="'mb-30'"></app-accordion>
  <div class="w-[326px] mx-auto">
    <a href="/assets/pdf/glossary.pdf" download>
      <app-button
        [title]="'Завантажити ключові факти'"
        [buttonType]="'Primary'"
        [svg]="true">
        <svg
          width="20px"
          height="20px"
          icon="download"
          iconCollection="button-icons-sprite"
          class="svg fill-gray-white mt-1 mr-2"></svg>
      </app-button>
    </a>
  </div>
</section>

<section class="bg-primary-base py-30">
  <div class="content-container">
    <div class="mt-15">
      <app-info-cards [cards]="keyDementiaCards"></app-info-cards>
    </div>
  </div>
</section>
