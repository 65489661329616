import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  sumUAHArray,
  sumUSDArray,
  sumEURArray,
  typeOfCurrency,
} from 'src/mockData/summsArray';
import { emailValidator } from 'src/app/components/forms/validators/email-validator';
import { nameValidator } from 'src/app/components/forms/validators/name-validator';

import { OpenPdf } from 'src/app/services/pdfOpen.service';
import { AgreementConfig } from 'src/mockData/agreement-docs-config';
import { DomSanitizer } from '@angular/platform-browser';
import { LiqPayService } from 'src/app/services/liq-pay.service';

export interface sumDonate {
  value: string;
  checked?: boolean;
}

@Component({
  selector: 'app-single-help',
  templateUrl: './single-help.component.html',
  styleUrls: ['./single-help.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OpenPdf],
})
export class SingleHelpComponent implements OnInit {
  choosedArray: sumDonate[] = [];
  currency: string[] = typeOfCurrency;
  formOnTimeSubs!: FormGroup;
  name = new FormControl('', [Validators.required, nameValidator]);
  surname = new FormControl('', [Validators.required, nameValidator]);
  email = new FormControl('', [Validators.required, emailValidator]);
  phone = new FormControl('', [Validators.required]);
  countryCode = new FormControl('+380');
  chosedSum = new FormControl('', [
    Validators.maxLength(7),
    Validators.minLength(1),
    Validators.pattern(/^(?!0)[1-9]\d{0,6}$/),
    Validators.required,
  ]);
  private UahSumArray: sumDonate[] = sumUAHArray;
  private UsdSumArray: sumDonate[] = sumUSDArray;
  private EurSumArray: sumDonate[] = sumEURArray;
  private chosedCurrency: string = 'uah';

  constructor(
    private router: Router,
    public fb: FormBuilder,
    private config: AgreementConfig,
    private sanitazer: DomSanitizer, // private liqPay: LiqPayService,
  ) {}

  ngOnInit(): void {
    this.choosedArray = this.UahSumArray;
    this.formOnTimeSubs = this.fb.group({
      nameDonator: this.name,
      surnameDonator: this.surname,
      emailDonator: this.email,
      sumDonate: this.chosedSum,
      telDonator: this.phone,
      telCode: this.countryCode,
      read: [false, Validators.requiredTrue],
    });
  }

  onSubmit() {
    const res = this.formOnTimeSubs.value;
    res.chosedCurrency = this.chosedCurrency;
    // todo сервіс куди відправляємо форму, або редирект на форму оплати з обраними данними
    const data: any = {
      amount: this.formOnTimeSubs.get('sumDonate')?.value,
      currency: res.chosedCurrency,
      order_id:
        this.formOnTimeSubs.get('sumDonate')?.value +
        '-' +
        this.formatDate(new Date()),
      description:
        this.formOnTimeSubs.get('nameDonator')?.value +
        ' ' +
        this.formOnTimeSubs.get('surnameDonator')?.value +
        ' допомагає фонду "Незабутні" на ' +
        `${this.formOnTimeSubs.get('sumDonate')?.value + res.chosedCurrency}`,
    };
    if (this.formOnTimeSubs.valid) {
      // this.liqPay.openPaymentWindow(data);
      console.log('formOnTimeSub submitted', data.order_id);
      this.formOnTimeSubs.reset();
    } else {
      this.validateAllFields(this.formOnTimeSubs);
    }
  }

  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  public changeSelectedCurrency(value: string): void {
    if (value === 'uah') {
      this.choosedArray = this.UahSumArray;
      this.chosedCurrency = 'uah';
    }
    if (value === 'eur') {
      this.choosedArray = this.EurSumArray;
      this.chosedCurrency = 'eur';
    }
    if (value === 'usd') {
      this.choosedArray = this.UsdSumArray;
      this.chosedCurrency = 'usd';
    }
  }
  onOpenOfertaGeneral() {
    const openOferta = new OpenPdf(this.config, this.sanitazer);
    openOferta.onOpenOferta();
  }

  onOpenTermsAndConditionsGeneral() {
    const openTermsAndConditions = new OpenPdf(this.config, this.sanitazer);
    openTermsAndConditions.onOpenTermsAndConditions();
  }

  onOpenFundPolicyGeneral() {
    const openFundPolicy = new OpenPdf(this.config, this.sanitazer);
    openFundPolicy.onOpenFundPolicy();
  }

  formatDate(date: Date): string {
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());
    const day = this.padZero(date.getDate());
    const month = this.padZero(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${hours}-${minutes}-${seconds}-${day}-${month}-${year}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
}
