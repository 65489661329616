import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ROUTE_NAMES } from 'src/app/constants/route_names';
// import IInfoPage from 'src/app/models/infoPage.interface';
// interface IData {
//   id: number;
//   menuTitle: string;
//   shortContent: string;
//   menuSubTitle: any;
// }

export default interface IInfoPages {
  title: string;
  text: string;
  whatIsDementiaParagraph?: string;
  whatIsDementiaParagraphSecond?: string;
  link?: string;
  id?: number;
  src?: string;
  menuSubTitle: any;
  subMenuItems?: any[];
}

@Component({
  selector: 'app-submenu-about-dementia',
  templateUrl: './submenu-about-dementia.component.html',
  styleUrls: ['./submenu-about-dementia.component.css'],
})
export class SubmenuAboutDementiaComponent implements OnInit {
  constructor(private http: HttpClient) {}

  menuItems: any[] = [];

  cards: any[] = [
    {
      title: 'Що таке деменція?',
      link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.whatDementiaIs}`,
      subMenuItems: [
        {
          id: 'what-dementia-is-4047',
          title: 'Основне про деменцію',
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.whatDementiaIs}`,
          dementiaMenuId: 2023,
        },
        {
          id: 'what-dementia-is-4048',
          title: 'Стадії деменції',
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.whatDementiaIs}`,
          dementiaMenuId: 2023,
        },
      ],
    },
    {
      id: 2024,
      title: 'Типи деменції',
      link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.dementiaTypes}`,
      subMenuItems: [
        {
          id: 'dementia-types-4049',
          title: 'Поширені типи деменції',
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.dementiaTypes}`,
          dementiaMenuId: 2024,
        },
        {
          id: 'dementia-types-4050',
          title: 'Що таке рання деменція?',
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.dementiaTypes}`,
          dementiaMenuId: 2024,
        },
        {
          id: 'dementia-types-4051',
          title: "Пов'язані стани",
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.dementiaTypes}`,
          dementiaMenuId: 2024,
        },
      ],
    },
    {
      id: 2025,
      title: 'Діагностика та терапія',
      link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.diagnosis}`,
      subMenuItems: [
        {
          id: 'diagnosis-therapy-4052',
          title: 'Діагностика деменції',
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.diagnosis}`,
          dementiaMenuId: 2025,
        },
        {
          id: 'diagnosis-therapy-4053',
          title: 'Лікування та медична терапія',
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.diagnosis}`,
          dementiaMenuId: 2025,
        },
        {
          id: 'diagnosis-therapy-4054',
          title: 'Нефармакологічна терапія',
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.diagnosis}`,
          dementiaMenuId: 2025,
        },
        {
          id: 'diagnosis-therapy-4055',
          title: 'Альтернативні практики',
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.diagnosis}`,
          dementiaMenuId: 2025,
        },
      ],
    },
    {
      id: 2026,
      title: 'Зменшення ризику та профілактика',
      link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.prophylaxis}`,
      subMenuItems: [
        {
          id: 'prophylaxis-4047',
          title: 'Фактори ризику деменції',
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.prophylaxis}`,
          dementiaMenuId: 2026,
        },
        {
          id: 'prophylaxis-4048',
          title: 'Профілактика деменції',
          link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.prophylaxis}`,
          dementiaMenuId: 2026,
        },
      ],
    },
    {
      id: 2027,
      title: 'Часті питання',
      link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.faq}`,
      subMenuItems: [],
    },
  ];
  ngOnInit(): void {}

  fetchCardList(): Observable<any[]> {
    const url = 'https://nezabutni-backend.vercel.app/api/dementia-menu-list';
    return this.http.get<any[]>(url);
  }
}
