import { Component, Input, OnInit } from '@angular/core';
/**
 * @template
 *
 * ```
 * <app-page-banner-item
 *     [imgUrl]="'assets/images/banner/banner-help-with-medicine.jpg'"
 *     title="Допомога з ліками"
 *     text="Тут ви отримаєте інформацію про те, як отримати допомогу з ліками">
 *    <your-extra-content></your-extra-content> // you can put here all extra content in the end of banner
 *   </app-page-banner-item>
 *
 * ```
 */
@Component({
  selector: 'app-page-banner-item',
  templateUrl: './page-banner-item.component.html',
  styleUrls: ['./page-banner-item.component.css'],
})
export class PageBannerItemComponent implements OnInit {
  @Input() text: string = '';
  @Input() title: string = '';
  @Input() imgUrl: string = '';
  constructor() {}

  ngOnInit(): void {}
}
