<div class="content-container">
  <h2 class="text-primary-base uppercase text-h1-heading w-full md:w-95 mb-10">
    Пресрелізи
  </h2>
  <div class="mb-30">
    <ul
      *ngFor="
        let releasesCard of showAllReleases
          ? pressReleasesArray.slice(0, 8)
          : pressReleasesArray
      ">
      <app-press-release-card [PRCard]="releasesCard"></app-press-release-card>
    </ul>
    <app-button
      *ngIf="pressReleasesArray.length >= 8"
      type="submit"
      class="w-56 text-main-text-bold mx-auto mb-15 mt-10 flex justify-center"
      [buttonType]="'Secondary'"
      [title]="showAllReleases ? 'Дивитись більше' : 'Згорнути'"
      (click)="showAllReleases = !showAllReleases">
    </app-button>
  </div>
</div>
