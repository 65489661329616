import { FaqPageDataInterface } from 'src/app/models/faqPageDataInterface';

export const FaqPageMockData: FaqPageDataInterface = {
  title: 'Часті питання',
  titleText:
    '<p>На цій сторінці Ви можете знайти відповіді на часті запитання. Якщо Ви не знайшли інформацію, можете заповнити форму за посиланням.</p>',
  textContent:
    '<p class="mb-8">Існує більш ніж 200 видів деменції. Хвороба Альцгеймера – один з найбільш поширених видів. Кожен з видів деменції зупиняє роботу мозкових клітин людини у визначених зонах, впливаючи на її здатність пам\'ятати, мислити та говорити.</p><p class="mb-[38px]">Зазвичай лікарі використовують слово «деменція» аби описати сукупність поширених симптомів, що погіршуються з часом, зокрема:</p>',
  accordionListText: [
    {
      title: 'Як знайти ваші соціальні мережі?',
      text: [
        'Щомісяця в дату, яку ви обрали для списання коштів, у разі наявності коштів на картці, з вашої картки автоматично списуватиметься вказана вами сума.',
      ],
    },
    {
      title: 'Куди звертатися за допомогою?',
      text: [
        'Система робить до 4 спроб списань, якщо всі вони неуспішні, наступна спроба — наступного місяця.',
      ],
    },
    {
      title: 'Я думаю, що в мене деменція.',
      HTML: [
        '<p>Для початку Ви можете пройти <a class="text-accent-base hover:text-accent-additional underline" href="/about-dementia/diagnosis/pass-test">тест</a>. Також Ви можете ознайомитися із інформацією щодо <a class="text-accent-base hover:text-accent-additional underline" href="/about-dementia/info/symptoms">симптомів</a>. Якщо Ваш стан турбує Вас, зверніться до лікаря або <a class="text-accent-base hover:text-accent-additional underline" href="/about-us/about-fund/contacts">звʼяжіться з нами.</a></p>',
      ],
    },
    {
      title: 'Як я можу допомогти?',
      HTML: [
        '<ul class="list-disc ml-8 text-main-text">' +
          '<li class="mt-1">труднощі з підбором правильних слів</li>' +
          '<li class="mt-1">часті повтори вже сказаного</li>' +
          '<li class="mt-1">труднощі з початком та підтримкою розмови</li>' +
          '<li class="mt-1">труднощі при читанні та письмі</li>' +
          '<li class="mt-1">видозміна людина, де вона стає тихішою та відчуженою</li>' +
          '<li class="mt-1">втрата інтересу до соціалізації</li>' +
          '<li class="mt-1">втрата упевненості</li>' +
          '<li class="mt-1">зміни у особистості та поведінці</li>' +
          '<li class="mt-1">перепади настрою, тривожність та депресія</li>' +
          '</ul>',
      ],
    },
    {
      title: 'Я хочу стати волонтером.',
      text: [
        'Система робить до 4 спроб списань, якщо всі вони неуспішні, наступна спроба — наступного місяця.',
      ],
    },
    {
      title: 'Яка профілактика деменції?',
      text: [
        'Система робить до 4 спроб списань, якщо всі вони неуспішні, наступна спроба — наступного місяця.',
      ],
    },
    {
      title: 'Як знайти ваші соціальні мережі?',
      text: [
        'Система робить до 4 спроб списань, якщо всі вони неуспішні, наступна спроба — наступного місяця.',
      ],
    },
    {
      title: 'Куди звертатися за допомогою?',
      text: [
        'Система робить до 4 спроб списань, якщо всі вони неуспішні, наступна спроба — наступного місяця.',
      ],
    },
    {
      title: 'Як я можу допомогти?',
      text: [
        'Система робить до 4 спроб списань, якщо всі вони неуспішні, наступна спроба — наступного місяця.',
      ],
    },
    {
      title: 'Я хочу стати волонтером.',
      text: [
        'Система робить до 4 спроб списань, якщо всі вони неуспішні, наступна спроба — наступного місяця.',
      ],
    },
    {
      title: 'Яка профілактика деменції?',
      text: [
        'Система робить до 4 спроб списань, якщо всі вони неуспішні, наступна спроба — наступного місяця.',
      ],
    },
  ],
  cantFind: {
    title: 'Не знайшли відповідь?',
    text: '<p>Якщо Ви не знайшли інформацію, можете заповнити форму за <a class="text-accent-base hover:text-accent-additional underline" href="/about-us/about-fund/contacts">посиланням</a>, або надіслати питання на електронну адресу <a  class="text-accent-base hover:text-accent-additional underline" href="mailto:nezabutni@gmail.com">nezabutni@gmail.com.</a></p>',
  },
};
