<div class="content-container flex flex-col">
  <div class="flex flex-row justify-between">
    <div class="flex w-95 h-42.5">
      <h2 class="text-primary-base text-h1-heading">
        {{ monthlyData.title | uppercase }}
      </h2>
    </div>
    <div class="flex flex-col w-195">
      <div class="pb-10" [innerHTML]="monthlyData.text"></div>
    </div>
  </div>
  <div class="flex flex-row mt-13">
    <div class="relative">
      <select
        id="year"
        #year
        (input)="choseYearIndex($event)"
        class="select w-75 h-12 rounded-10 pl-5 border-2 border-gray-stroke outline-primary-base mr-5 appearance-none text-gray-details bg-[16rem] bg-no-repeat bg-[url('src/assets/icons/svg-sprites/button-icons-sprite.svg#chevron(viewBox(0,0,22,22))')]">
        <option value="" disabled selected>Оберіть рік</option>
        <option
          *ngFor="let item of getAllYearsData(this.dataArray); let i = index"
          value="{{ i }}">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="relative">
      <select
        #month
        [(ngModel)]="selectMonthValue"
        id="month"
        (input)="choseMonthIndex($event)"
        class="test w-75 h-12 rounded-10 pl-5 border-2 border-gray-stroke outline-primary-base mr-5 appearance-none text-gray-details bg-[16rem] bg-no-repeat bg-[url('src/assets/icons/svg-sprites/button-icons-sprite.svg#chevron(viewBox(0,0,22,22))')] fill-gray-dark">
        <option value="" disabled selected>Оберіть місяць</option>
        <option *ngFor="let month of monthArray; let i = index" value="{{ i }}">
          {{ month.month }}
        </option>
      </select>
    </div>
    <div class="w-55">
      <app-button
        class="w-55"
        type="button"
        [buttonType]="'Secondary'"
        (click)="setCurrentData(+year.value, +month.value)"
        [title]="'Застосувати'"></app-button>
    </div>
  </div>
  <app-monthly-reports-card
    *ngIf="cardMonthView; else loader"
    [cardMonthly]="cardMonthView"></app-monthly-reports-card>
  <ng-template #loader>
    <div class="loader mx-auto my-20">
      <svg
        class="animate-spin -ml-1 mr-3 h-5 w-5 text-accent-base"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  </ng-template>

  <div class="flex flex-row justify-center mx-auto mt-15 mb-30">
    <div class="w-55 mr-2.5" routerLink="/about-us/about-fund/reports">
      <app-button
        class="w-55"
        type="button"
        [buttonType]="'Secondary'"
        [title]="'Інші звіти'"></app-button>
    </div>
    <div class="w-55 ml-2.5" routerLink="/join/donate">
      <app-button
        class="w-55"
        type="button"
        [buttonType]="'Donate'"
        [title]="'Допомогти'"></app-button>
    </div>
  </div>
</div>
