import { Component, OnInit } from '@angular/core';
import IProducts from 'src/app/models/productsCard.interface';
import { productsCardConfig } from 'src/mockData/productsCardConfig';

@Component({
  selector: 'app-media-about-us',
  templateUrl: './media-about-us.component.html',
  styleUrls: ['./media-about-us.component.css'],
})
export class MediaAboutUsComponent implements OnInit {
  productsCard: IProducts[] = productsCardConfig;
  selectedFilter: string = 'Всі публікації';
  p: number = 1;

  ngOnInit(): void {}
  public changeValueFilter(value: string): void {
    if (value === 'Відео') {
      this.productsCard = productsCardConfig.filter(
        product => product.title === 'Відео',
      );
      this.selectedFilter = 'Відео';
    }
    if (value === 'Статтi') {
      this.productsCard = productsCardConfig.filter(
        product => product.title === 'Стаття',
      );
      this.selectedFilter = 'Статтi';
    }
    if (value === 'Всі публікації') {
      this.productsCard = productsCardConfig;
      this.selectedFilter = 'Всі публікації';
    }
  }

  constructor() {}
}
