export const AlternativePaidArray = [
  {
    type: 'alternative',
    logo: './assets/logo/globalGiving.png',
    description:
      "Інтернет-ресурс, що об'єднує благодійників та grassroots–проекти з країн, що розвиваються.",
    linkTo: '/',
  },
  {
    type: 'alternative',
    logo: './assets/logo/justgiving.png',
    description:
      "Інтернет-ресурс, що об'єднує благодійників та grassroots–проекти з країн, що розвиваються.",
    linkTo: '/',
  },
];
