<a
  class="flex flex-col w-full md:w-95 h-39 justify-between border-2 border-gray-stroke cursor-pointer py-7.75 pl-10 rounded-30 group hover:border-secondary-base duration-300"
  routerLink="{{ card.link }}">
  <div class="text-main-text-bold w-75">
    {{ card.cardTitle }}
  </div>
  <div
    class="flex flex-row justify-start text-accent-base cursor-pointer group-hover:text-secondary-base active:text-secondary-additional duration-300">
    <p class="text-smaller-bold pr-3">Відкрити</p>
    <svg
      class="fill-accent-base group-hover:fill-secondary-base active:fill-secondary-additional group-hover:ml-2 duration-300"
      [icon]="'right-arrow-small'"
      iconCollection="button-icons-sprite"
      height="26"
      width="30"></svg>
  </div>
</a>
