import { ROUTE_NAMES } from 'src/app/constants/route_names';
import { ISocialLink } from 'src/app/models/social-link.interface';
interface SubMenuItem {
  id: string;
  title: string;
  link: string;
  dementiaMenuId?: number;
}

interface SubMenu {
  id?: number | string;
  title: string;
  link: string;
  titleLinkId?: string;
  subMenuItems?: SubMenuItem[] | never[];
}
interface MenuItem {
  name: string;
  link: string;
  component: string;
  subMenu?: SubMenu[];
  menuSubTitle?: any[];
  titleLinkId?: string;
}

export const MenuItemsMock: MenuItem[] = [
  {
    name: 'Головна',
    link: '/',
    component: 'about-dementia1',
  },

  {
    name: 'Про деменцію',
    link: `/${ROUTE_NAMES.aboutDementia}`,
    component: 'about-dementia',
    subMenu: [
      {
        title: 'Що таке деменція?',
        link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.whatDementiaIs}`,
        subMenuItems: [
          {
            id: 'what-dementia-is-4047',
            title: 'Основне про деменцію',
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.whatDementiaIs}`,
            dementiaMenuId: 2023,
          },
          {
            id: 'what-dementia-is-4048',
            title: 'Стадії деменції',
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.whatDementiaIs}`,
            dementiaMenuId: 2023,
          },
        ],
      },
      {
        id: 2024,
        title: 'Типи деменції',
        link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.dementiaTypes}`,
        subMenuItems: [
          {
            id: 'dementia-types-4049',
            title: 'Поширені типи деменції',
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.dementiaTypes}`,
            dementiaMenuId: 2024,
          },
          {
            id: 'dementia-types-4050',
            title: 'Що таке рання деменція?',
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.dementiaTypes}`,
            dementiaMenuId: 2024,
          },
          {
            id: 'dementia-types-4051',
            title: "Пов'язані стани",
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.dementiaTypes}`,
            dementiaMenuId: 2024,
          },
        ],
      },
      {
        id: 2025,
        title: 'Діагностика та терапія',
        link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.diagnosis}`,
        subMenuItems: [
          {
            id: 'diagnosis-therapy-4052',
            title: 'Діагностика деменції',
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.diagnosis}`,
            dementiaMenuId: 2025,
          },
          {
            id: 'diagnosis-therapy-4053',
            title: 'Лікування та медична терапія',
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.diagnosis}`,
            dementiaMenuId: 2025,
          },
          {
            id: 'diagnosis-therapy-4054',
            title: 'Нефармакологічна терапія',
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.diagnosis}`,
            dementiaMenuId: 2025,
          },
          {
            id: 'diagnosis-therapy-4055',
            title: 'Альтернативні практики',
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.diagnosis}`,
            dementiaMenuId: 2025,
          },
        ],
      },
      {
        id: 2026,
        title: 'Зменшення ризику та профілактика',
        link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.prophylaxis}`,
        subMenuItems: [
          {
            id: 'prophylaxis-4047',
            title: 'Фактори ризику деменції',
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.prophylaxis}`,
            dementiaMenuId: 2026,
          },
          {
            id: 'prophylaxis-4048',
            title: 'Профілактика деменції',
            link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.prophylaxis}`,
            dementiaMenuId: 2026,
          },
        ],
      },
      {
        id: 2027,
        title: 'Часті питання',
        link: `/${ROUTE_NAMES.aboutDementia}/${ROUTE_NAMES.faq}`,
        subMenuItems: [],
      },
    ],
  },
  {
    name: 'Потрібна допомога?',
    link: `/${ROUTE_NAMES.needHelp}`,
    component: 'get-support',
    subMenu: [
      {
        title: 'Допомога з ліками',
        link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.helpWithMedicine}`,
      },
      {
        title: 'Психологічна підтримка',
        link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.psychologicalSupport}`,
      },
      {
        title: 'Юридична допомога',
        link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.legalAssistance}`,
      },
      {
        title: 'Медична допомога',
        link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.doctorConsultation}`,
      },
      {
        title: 'Допомога з доглядом',
        link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.careConsultation}`,
      },
      {
        title: 'Інформаційна допомога',
        link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.informationalAssistance}`,
      },
      {
        title: 'Кризова допомога',
        link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.crisisAssistance}`,
        titleLinkId: 'crisis-assistance',
        subMenuItems: [
          {
            id: 'help_with_generators',
            title: 'Допомога з генераторами',
            link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.crisisAssistance}`,
          },
          {
            id: 'assistance_with_relocation',
            title: 'Допомога з переміщенням та поселенням',
            link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.crisisAssistance}`,
          },
          {
            id: 'help_abroad',
            title: 'Допомога за кордоном',
            link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.crisisAssistance}`,
          },
        ],
      },
      {
        title: 'Що робити, якщо',
        link: `/${ROUTE_NAMES.needHelp}`,
        titleLinkId: 'whatToDoIf',
        subMenuItems: [
          {
            id: '',
            title: 'Я турбуюся, що в мене деменція',
            link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.whatToDoIf}/${ROUTE_NAMES.iWorryAboutMemory}`,
          },
          {
            id: '',
            title: 'У мене деменція',
            link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.whatToDoIf}/${ROUTE_NAMES.justDiagnosed}`,
          },

          {
            id: '',
            title: 'Я доглядальник, родич або друг',
            link: `/${ROUTE_NAMES.needHelp}/${ROUTE_NAMES.whatToDoIf}/${ROUTE_NAMES.relativeHasDementia}`,
          },
        ],
      },
    ],
  },
  {
    name: 'Долучитися',
    link: `/${ROUTE_NAMES.join}`,
    component: 'join',
    subMenu: [
      {
        title: 'Регулярний внесок',
        link: `/${ROUTE_NAMES.join}/${ROUTE_NAMES.donate}`,
      },
      {
        title: 'Стати волонтером',
        link: `/${ROUTE_NAMES.join}/${ROUTE_NAMES.becomeVolunteer}`,
      },
      {
        title: 'Стати партнером фонду',
        link: `/${ROUTE_NAMES.join}/${ROUTE_NAMES.becomePartner}`,
      },
      {
        title: 'Організувати збір коштів',
        link: `/${ROUTE_NAMES.join}/${ROUTE_NAMES.becomePartner}`,
      },
    ],
  },
  {
    name: 'Заходи',
    link: ROUTE_NAMES.activities,
    component: `/${ROUTE_NAMES.activities}`,
  },
  {
    name: 'Про нас',
    link: `/${ROUTE_NAMES.aboutUs}`,
    component: 'about-us',
    subMenu: [
      {
        title: 'Про фонд',
        link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.aboutFund}/${ROUTE_NAMES.ourIdea}`,
        subMenuItems: [
          {
            id: '',
            title: 'Наша ідея',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.aboutFund}/${ROUTE_NAMES.ourIdea}`,
          },
          {
            id: '',
            title: 'Історія',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.aboutFund}/${ROUTE_NAMES.history}`,
          },
          {
            id: '',
            title: 'Звіти та документи',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.aboutFund}/${ROUTE_NAMES.reports}`,
          },
          {
            id: 'payment',
            title: 'Реквізити',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.aboutFund}/${ROUTE_NAMES.help}`,
          },
          {
            id: '',
            title: 'Контакти',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.aboutFund}/${ROUTE_NAMES.contacts}`,
          },
        ],
      },
      {
        title: 'Наші люди',
        link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.ourPeople}`,
        subMenuItems: [
          {
            id: '',
            title: 'Команда фонду',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.ourPeople}/${ROUTE_NAMES.fundTeam}`,
          },

          {
            id: '',
            title: 'Наші партнери',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.ourPeople}/${ROUTE_NAMES.ourPartners}`,
          },
          {
            id: '',
            title: 'Вакансії',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.ourPeople}/${ROUTE_NAMES.vacancies}`,
          },
        ],
      },
      {
        title: 'Напрями діяльності',
        link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.ourPrograms}`,
        subMenuItems: [
          {
            id: '',
            title: 'Допомога та підтримка',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.ourPrograms}/${ROUTE_NAMES.helpDuringTheWar}`,
          },
          {
            id: '',
            title: 'Адвокація',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.ourPrograms}/${ROUTE_NAMES.supportForFamilies}`,
          },
          {
            id: '',
            title: 'Дружнє суспільство',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.ourPrograms}/${ROUTE_NAMES.friendlySociety}`,
          },
          {
            id: '',
            title: 'Навчання',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.ourPrograms}/${ROUTE_NAMES.study}`,
          },
          {
            id: '',
            title: 'Дослідження',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.ourPrograms}/${ROUTE_NAMES.research}`,
          },
        ],
      },
      {
        title: 'Новини та медіа',
        link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.newsAndMedia}`,
        subMenuItems: [
          {
            id: '',
            title: 'Новини',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.newsAndMedia}/${ROUTE_NAMES.news}`,
          },
          {
            id: '',
            title: 'Статистика',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.newsAndMedia}/${ROUTE_NAMES.statistic}`,
          },
          {
            id: '',
            title: 'Медіацентр',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.newsAndMedia}/${ROUTE_NAMES.mediaCenter}`,
          },
          {
            id: '',
            title: 'ЗМІ про нас',
            link: `/${ROUTE_NAMES.aboutUs}/${ROUTE_NAMES.newsAndMedia}/${ROUTE_NAMES.mediaAboutUs}`,
          },
        ],
      },
    ],
  },
];

export const SocialLinkMock: ISocialLink[] = [
  {
    link: 'https://www.instagram.com/nezabutni_/',
    image: 'instagram',
    width: '24px',
    height: '24px',
  },
  {
    image: 'facebook',
    link: 'https://www.facebook.com/nezabutni.org',
    width: '16px',
    height: '29px',
  },
  {
    image: 'youtube',
    link: 'https://www.youtube.com/channel/UCIhdADy2Q8xzImxhzQK549w/featured',
    width: '26px',
    height: '20px',
  },
  {
    image: 'telegram',
    link: 'https://t.me/NezabutniCF',
    width: '26',
    height: '22',
  },
  {
    image: 'viber',
    link: 'https://invite.viber.com/?g2=AQBrcrOiC1eYD0%2FwbncaUKw%2BDHJik%2FgYMT8bUv%2F0H3d28l1XNHIZLBdrDQKEoglQ',
    width: '26',
    height: '29',
  },
];
