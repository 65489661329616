<ng-container *ngIf="breadcrumbs.length > 1">
  <ol class="flex items-center content-container py-4.5">
    <li
      *ngFor="let breadcrumb of breadcrumbs; let i = index"
      class="text-smaller-main [:last-child]:no-underline underline-offset-5 h-6">
      <span
        [ngClass]="{
          'underline text-accent-base cursor-pointer':
            i < breadcrumbs.length - 1
        }"
        routerLink="{{ breadcrumb.url }}"
        routerLinkActive="router-link-active"
        [attr.data-breadcrumb]="breadcrumb.label"
        >{{ breadcrumb.label }}</span
      ><span *ngIf="i < breadcrumbs.length - 1" class="text-gray-dark px-2.5"
        >/</span
      >
    </li>
  </ol>
</ng-container>
