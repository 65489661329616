import { IKeyFactsAboutDementia } from 'src/app/models/KeyFactsAboutDementia.interface';

export const KeyFactsAboutDementiaMockData: IKeyFactsAboutDementia[] = [
  {
    title: 'Посилання на джерела інформації',
    HTML: [
      `<ol class="list-decimal ml-8 text-main-text text-accent-base">
      <li class="mt-1">
        <a
          target="_blank"
          href="https://www.who.int/publications/i/item/9789241550543"
          class="text-accent-base underline underline-offset-5"
          >World Health Organisation, Risk Reduction of Cognitive Decline and Dementia, 2020.</a
        >
      </li>
    </ol>`,
    ],
  },
];
