import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IVacanciesData } from 'src/app/models/vacancies.interface';

@Injectable({
  providedIn: 'root',
})
export class VacanciesApiService {
  private apiUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getData(): Observable<IVacanciesData[]> {
    const url = `${this.apiUrl}/Vacancy/vacancies`;
    return this.http.get<IVacanciesData[]>(url);
  }
}
