import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import IHelpWithMedicine from 'src/app/models/helpWithMedicine.interface';
import {
  MedicineForPersonWithDementia,
  MedicineForTheInstitution,
  MoreAboutTheProject,
} from 'src/mockData/helpWithMedicineMockData';
import IInfoPage from '../../../models/infoPage.interface';

@Component({
  selector: 'app-help-with-medicine',
  templateUrl: './help-with-medicine.component.html',
  styleUrls: ['./help-with-medicine.component.css'],
})
export class HelpWithMedicineComponent implements OnInit {
  medicine: IHelpWithMedicine[] = MedicineForPersonWithDementia;
  institution: IHelpWithMedicine[] = MedicineForTheInstitution;
  project: IHelpWithMedicine[] = MoreAboutTheProject;

  private pdfSubscription!: Subscription;
  isHovered: boolean = false;

  cards: IInfoPage[] = [
    {
      title: 'Інші види допомоги',
      text: 'Дізнайтеся більше про симптоми деменції з нашої сторінки.',
      link: '/get-support',
    },
    {
      title: 'Переглянути звіти',
      text: 'Різні типи деменції можуть по-різному впливати на людей, і кожен буде відчувати симптоми по-своєму.',
      link: '/about-us/about-fund/reports',
    },
  ];

  constructor(private http: HttpClient, private clipboard: Clipboard) {
    this.links = [
      {
        id: 'MedicineForPersonWithDementia',
        title: 'Як отримати ліки для людини з деменцією?',
      },
      {
        id: 'MedicineForTheInstitution?',
        title: 'Як отримати ліки для закладу?',
      },
      {
        id: 'MoreAboutTheProject',
        title: 'Детальніше про проєкт “Допомога з ліками”',
      },
    ];
  }
  protected links: { readonly id: string; readonly title: string }[] = [];
  arrayListOfMedicine: any = [
    { image: '../../../../assets/images/help-medicine/1.jpg' },
    { image: ' ../../../../assets/images/help-medicine/2.jpg' },
    { image: ' ../../../../assets/images/help-medicine/3.jpg' },
  ];

  ngOnInit(): void {}

  onChangeSlide(value: string) {
    if (value === 'nextSlide') {
      const deletedElement = this.arrayListOfMedicine.shift();
      this.arrayListOfMedicine.push(deletedElement);
    }
    if (value === 'prevSlide') {
      const deletedElement = this.arrayListOfMedicine.pop();
      this.arrayListOfMedicine.unshift(deletedElement);
    }
  }

  ngOnDestroy(): void {
    this.pdfSubscription && this.pdfSubscription.unsubscribe();
  }

  public openGoogleDocs(): void {
    window.open('https://docs.google.com/', '_blank');
  }

  copyMail() {
    this.clipboard.copy('a.kovalenko@nezabutni.org');
  }
}
