import newsCardInfo from 'src/app/models/newsCard.interface';

export const newsCardConfig: newsCardInfo[] = [
  {
    id: 1,
    date: '22 вересня 2021',
    title: 'Тестування мобільного застосунку Family Game Night',
    text: 'Вчора відбулося тестування мобільного застосунку Family Game Night, який зможе повідомити про перші сигнали деменції, а також тренуватиме пам’ять і увагу!/pФонд Незабутні став партнером компанії @scrum_launch на етапі тестування застосунку! /pМи хочемо сказати ВЕЛИКЕ ДЯКУЮ всім, завдяки кому, це стало можливим! /pУ першу чергу, дякуємо 18 - тьом прекрасним тестувальницям і тестувальникам віком від 60 до 81 року! Ви чудові і сміливі, з надзвичайним почуттям гумору! Ви готові пробувати нове і робити свій внесок у соціально важливі справи.',
    content:
      'Велика подяка Ярославу Казакову @kazakovy і компанії ScrumLaunch, які довірили фонду «Незабутні» організувати тестування застосунку. Нам пощастило, що першим партнером фонду стала компанія, яка розробляє продукт, що має таку саме місію як і фонд. Нехай все вийде!/pВелике спасибі волонтерам фонду - @olgasergeeva1315, @mani_in_vernice , @olalookoe , @nd.torbenko Настя Меркулова, @s.u.sonyaunicorn, @milka_rykun, @_ladno без вас тестування би не відбулося!!!/pДякуємо фотографу Юлія Солонько @jul_i_ko за чудові кадри!/pДякуємо чудовій локації @coworking_platforma - захід пройшов у сучасній, обладнаній залі, з комфортною температурою. Наші тестувальники відмітили зручне розташування – навпроти оперного театру! Дякуємо дуже, що відгукнулися!/pДякуємо @andreymiyusov, @dyarynych і @kama_ua ! Завдяки вам у фонду є брендовані футболки, наліпки, бейджі, пакети! І вони нам дуже подобаються!/pМи сподіваємося, що зворотній зв’язок від тестувальників дасть можливість ефективно допрацювати застосунок. Ми вже не можемо дочекаємося, коли вийде його фінальна версія! А тим часом, ми завжди готові тестувати його стільки разів, скільки треба! 😊',
    picturelink: './assets/images/news/image1.png',
    link: '',
    shortText:
      'Застосунок зможе повідомити про перші сигнали деменції, а також...',
    detailPictureLink: 'assets/images/image/6.jpg',
  },
  {
    id: 2,
    date: '21 вересня 2021',
    title: "Прем'єра вистави «Батько» за п'єсою Флоріана Зеллера",
    text: '',
    content: '',
    picturelink: './assets/images/news/image2.png',
    link: '',
    shortText:
      'В рамках вистави фонд разом із фотографинею Анастасією Накорнєєвою представив «МИ Є. ЛЮДИ з деменцією».',
    detailPictureLink: 'assets/images/image/2.jpg',
  },
  {
    id: 3,
    date: '20 вересня 2021',
    title: 'FDA схвалили препарат Адуканумаб',
    text: '',
    content:
      'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препарати, які використовуються зараз...',
    picturelink: './assets/images/news/image3.png',
    link: 'https://www.google.com.ua/',
    shortText:
      'В рамках вистави фонд разом із фотографинею Анастасією Накорнєєвою представив «МИ Є. ЛЮДИ з деменцією».',
    detailPictureLink: 'assets/images/image/3.jpg',
  },
  {
    id: 4,
    date: '17 вересня 2021',
    title: 'FDA схвалили препарат Адуканумаб',
    text: '',
    content:
      'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препарати, які використовуються зараз...',
    picturelink: './assets/images/news/image4.png',
    shortText:
      'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препарати, які використовуються зараз...',
  },
  {
    id: 9,
    title: "Прем'єра вистави «Батько»  за п'єсою Флоріана Зеллера",
    content:
      'В рамках вистави фонд разом із фотографинею Анастасією Накорнєєвою представив «МИ Є ЛЮДИ з деменцією».',
    text: '',
    picturelink: './assets/images/news/image9.png',
    shortText:
      'Застосунок зможе повідомити про перші сигнали деменції, а також...',
    date: '17 вересня 2021',
    link: '',
  },
  {
    id: 10,
    title: "Прем'єра вистави «Батько»  за п'єсою Флоріана Зеллера",
    content:
      'В рамках вистави фонд разом із фотографинею Анастасією Накорнєєвою представив «МИ Є ЛЮДИ з деменцією».',
    text: '',
    picturelink: './assets/images/news/image9.png',
    shortText:
      'Застосунок зможе повідомити про перші сигнали деменції, а також...',
    date: '17 вересня 2021',
    link: '',
  },
  {
    id: 5,
    date: '16 вересня 2021',
    title: 'Тестування мобільного застосунку Family Game Night',
    content:
      'Застосунок зможе повідомити про перші сигнали деменції, а також...',
    text: '',
    picturelink: './assets/images/news/image5.png',
    shortText:
      'Застосунок зможе повідомити про перші сигнали деменції, а також...',
    link: '',
  },
  {
    id: 6,
    title: "Прем'єра вистави «Батько»  за п'єсою Флоріана Зеллера",
    content:
      'В рамках вистави фонд разом із фотографинею Анастасією Накорнєєвою представив «МИ Є ЛЮДИ з деменцією».',
    text: '',
    picturelink: './assets/images/news/image6.png',
    shortText:
      'Застосунок зможе повідомити про перші сигнали деменції, а також...',
    date: '15 вересня 2021',
    link: '',
  },
  {
    id: 7,
    title: "Прем'єра вистави «Батько»  за п'єсою Флоріана Зеллера",
    content:
      'В рамках вистави фонд разом із фотографинею Анастасією Накорнєєвою представив «МИ Є ЛЮДИ з деменцією».',
    text: '',
    picturelink: './assets/images/news/image7.png',
    shortText:
      'Застосунок зможе повідомити про перші сигнали деменції, а також...',
    date: '14 вересня 2021',
    link: '',
  },
  {
    id: 8,
    title: "Прем'єра вистави «Батько»  за п'єсою Флоріана Зеллера",
    content:
      'В рамках вистави фонд разом із фотографинею Анастасією Накорнєєвою представив «МИ Є ЛЮДИ з деменцією».',
    text: '',
    picturelink: './assets/images/news/image8.png',
    shortText:
      'Застосунок зможе повідомити про перші сигнали деменції, а також...',
    date: '13 вересня 2021',
    link: '',
  },
  // {
  //   newsId: 7,
  //   addedOn: '17 вересня 2021',
  //   header: "Прем'єра вистави «Батько» за п'єсою Флоріана Зеллера",
  //   content:
  //     'В рамках вистави фонд разом із фотографинею Анастасією Накорнєєвою представив «МИ Є ЛЮДИ з деменцією».',
  //   image: './assets/images/news/image7.png',
  // },
  // {
  //   newsId: 8,
  //   addedOn: '16 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препарати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 9,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 10,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препарати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 11,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 12,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 13,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 14,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 15,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 16,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 17,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 18,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 19,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 20,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 21,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 22,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 23,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 24,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 25,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 26,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 27,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 28,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 29,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 30,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 31,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 32,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 33,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 34,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 35,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 36,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 37,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 38,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 39,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 40,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 41,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 42,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 43,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 44,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 45,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 46,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 47,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 48,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 49,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 50,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 51,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 52,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 53,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 54,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 55,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 56,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 57,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 58,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 59,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 60,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 61,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 62,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 63,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 64,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 65,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 66,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 67,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 68,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 69,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 70,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 71,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 72,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 73,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 74,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 75,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 76,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 77,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 78,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 79,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 80,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 81,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 82,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 83,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 84,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 85,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 86,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 87,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 89,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 90,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 91,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 91,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 92,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 93,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
  // {
  //   newsId: 94,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 95,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 96,
  //   addedOn: '15 вересня 2021',
  //   header: 'FDA схвалили препарат Адуканумаб',
  //   content:
  //     'Це перші в історії ліки, які потенційно можуть відтермінувати розвиток деменції. Препрати, які використовуються зараз...',
  //   image: './assets/images/news/image8.png',
  // },
  // {
  //   newsId: 97,
  //   addedOn: '15 вересня 2021',
  //   header: 'Тестування мобільного застосунку Family Game Night',
  //   content:
  //     'Застосунок зможе повідомити про перші сигнали деменції, а також...',
  //   image: './assets/images/news/image9.png',
  // },
];
