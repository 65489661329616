export const becomePartnerDataMock = {
  banerTitle: 'Стати партнером фонду',
  banerText:
    'Ставайте партнером фонду! Співпрацюючи з нами, ви збільшуєте можливості для системних змін і допомоги сім’ям «тут і зараз».',
  buttonText: 'Стати партнером',
  bannerImg: './assets/images/become-partner/become-partner-baner.jpg',
  email: 'info@nezabutni.org',
  cardsWithLink: [
    {
      title: 'Акція за участі клієнтів',
      text: 'Проводьте акцію і залучайте ваших клієнтів до допомоги.',
      link: '/get-support/crisis-assistance/help-with-generators',
    },
    {
      title: 'Допомога з бюджету компанії',
      text: 'Спрямовуйте певну частину бюджету на допомогу нашим підопічним.',
      link: '/get-support/crisis-assistance/help-with-evacuation',
    },
    {
      title: 'Інфраструктурна допомога',
      text: 'Благодійний фонд, як і будь-яка компанія, має багато адміністративних потреб.',
      link: '/get-support/crisis-assistance/help-abroad',
    },
    {
      title: 'Корпоративне волонтерство',
      text: 'Залучайте співробітників до благодійності у рамках внутрішньої програми соціальної відповідальності.',
      link: '/get-support/crisis-assistance/help-abroad',
    },
  ],
  title: 'Опції партнерства',
  text: '<p>Фонд надає допомогу громадянам України, які мають деменцію. Ми робимо все можливе, щоб кожна сім’я людини із деменцією отримала допомогу швидко і вповні.</p>',
};
