import { Component, OnInit } from '@angular/core';
import { IMenuItem, ISubMenu } from 'src/app/models/menu.interface';
import { MenuItemsMock } from 'src/mockData/menuMockData';

@Component({
  selector: 'app-submenu-about-us',
  templateUrl: './submenu-about-us.component.html',
  styleUrls: ['./submenu-about-us.component.css'],
})
export class SubmenuAboutUsComponent implements OnInit {
  menu: IMenuItem[];
  submenus: ISubMenu[];

  aboutFundLinks: ISubMenu[] = [];

  ourPeopleLinks: ISubMenu[] = [];

  ourProgramsLinks: ISubMenu[] = [];
  mediaLinks: ISubMenu[] = [];

  constructor() {
    this.menu = MenuItemsMock;
    this.submenus = this.menu.find(item => item.link === '/about-us')
      ?.subMenu as ISubMenu[];
    this.aboutFundLinks = this.submenus[0].subMenuItems as ISubMenu[];
    this.ourPeopleLinks = this.submenus[1].subMenuItems as ISubMenu[];
    this.ourProgramsLinks = this.submenus[2].subMenuItems as ISubMenu[];
    this.mediaLinks = this.submenus[3].subMenuItems as ISubMenu[];
  }

  ngOnInit(): void {}
  mainAction($event: any) {
    console.log($event.path[0].outerText);
  }
  functionCall1(event: any) {
    console.log('functionCall', event);
  }
  functionCall2(event: any) {
    console.log('functionCall', event);
  }
}
