<div class="bg-primary-base mt-28">
  <div id="take_part_in_research" class="container pt-30 pb-20">
    <h2 class="uppercase text-h2-heading text-gray-white mb-6">
      Актуальні дослідження
    </h2>
    <p class="text-main-text text-gray-white mb-10">
      Нижче наведено список поточних досліджень, які набирають учасників. Будь
      ласка, надсилайте будь-які запитання щодо конкретних досліджень до
      контактної особи дослідження.
    </p>
    <ul class="" *ngFor="let currentResearchInfo of currentResearchInfo">
      <li
        class="h-80 px-10 mx-auto w-295 rounded-30 border-2 border-gray-stroke pt-9 bg-white mb-10">
        <h2 class="text-h3-subheading text-gray-dark mb-5">
          {{ currentResearchInfo.title }}
        </h2>
        <P class="text-main-text text-gray-dark pb-5">{{
          currentResearchInfo.text
        }}</P>
        <div class="flex">
          <p class="text-details text-gray-details pb-5 mr-2">
            {{ currentResearchInfo.paragraph }}
          </p>
          <p class="text-details text-gray-details pb-5">
            {{ currentResearchInfo.data }}
          </p>
        </div>
        <app-button
          type="submit"
          class="w-55 text-main-text-bold mx-auto"
          [buttonType]="'Primary'"
          [title]="'Взяти участь'">
        </app-button>
      </li>
    </ul>
  </div>
</div>
