import IInfoPage from '../app/models/infoPage.interface';

export const aboutUsCardConfig: IInfoPage[] = [
  {
    title: 'Команда фонду',
    text: 'Прочитайте нашу сторінку «Що таке деменція?» для отримання додаткової інформації.',
    link: '/about-us/our-people/fund-team',
  },
  {
    title: 'Наші партнери',
    text: 'Ми створили для Вас зручний каталог у PDF, який можна переглянути за завантажити на сторінці.',
    link: '/about-us/our-people/our-partners',
  },
  {
    title: 'Вакансії',
    text: 'Детальна інформація по догляду людей із деменцією.',
    link: '/about-us/our-people/vacancies',
  },
];

export const aboutUsTextConfig: IInfoPage[] = [
  {
    title: 'Про нашу команду',
    text: 'Деменція – це загальний термін, який використовується для опису ряду прогресуючих неврологічних розладів, тобто станів, що впливають на мозок. Існує понад 200 підтипів деменції, але п’ять найпоширеніших: хвороба Альцгеймера, судинна деменція, деменція з тільцями Леві, лобно-скронева деменція та змішана деменція. Деякі люди можуть мати комбінацію різних типів деменції, і їх зазвичай називають змішаною деменцією.',
  },
];
