<div>
  <app-page-banner-item
    title="Адвокація"
    text="Фонд “Незабутні” представляє інтереси людей з деменцією та їх доглядальників на різних рівнях з метою покращення умов їхнього життя та створення інклюзивного середовища"
    [imgUrl]="
      './assets/images/banner/banner-advocacy.jpg'
    "></app-page-banner-item>

  <div class="container py-30">
    <p class="text-main-text text-gray-dark">
      Адвокація та захист прав людей з деменцією та їхніх родичів і
      доглядальників є критично важливою на шляху до створення суспільства,
      дружнього до людей з деменцією. Починаючи з 2022 року ми вирішили
      об’єднуватися з людьми з деменцією та їх родичами, лікарями, науковцями,
      громадськими і благодійними організаціями, органами влади та іншими
      партнерами задля створення умов для якісного надання послуг та
      інклюзивності середовища для людей з деменцією в Україні. 
    </p>
  </div>

  <app-table-of-content
    class="flex mx-auto w-full mb-30"
    [links]="links"></app-table-of-content>

  <div class="w-295 mx-auto" *ngFor="let advocacy of advocacyInfo">
    <h2
      id="NationalDementiaResponse"
      class="text-h2-heading uppercase text-primary-base mb-5 w-145">
      {{ advocacy.title }}
    </h2>
    <p class="text-main-text text-gray-dark mb-5">
      {{ advocacy.block }}
    </p>
    <div class="flex">
      <div class="w-145 mr-3" [innerHTML]="advocacy.text"></div>

      <div class="w-140">
        <app-carousel (changeSlide)="onChangeSlide($event)">
          <app-carousel-slide *ngFor="let ListOfImage of arrayListImage">
            <img
              class="rounded-3xl max-w-none ml-2"
              src="{{ ListOfImage.src }}" />
          </app-carousel-slide>
        </app-carousel>
      </div>
    </div>
    <p class="text-main-text text-gray-dark mt-7 mb-30">
      {{ advocacy.paragraph }}
    </p>
    <h2
      id="RepresentationInternational"
      class="text-h2-heading uppercase text-primary-base mb-5 w-145">
      {{ advocacy.titleNext }}
    </h2>
    <p
      class="text-main-text text-gray-dark mb-5"
      [innerHTML]="advocacy.textNext"></p>
    <app-carousel (changeSlide)="onChangeSlide2($event)">
      <app-carousel-slide *ngFor="let ListOfImage of arrayListImage2">
        <img
          class="rounded-3xl max-w-none mt-10 mb-5"
          src="{{ ListOfImage.src }}" />
      </app-carousel-slide>
    </app-carousel>
  </div>
</div>

<section class="mt-30 bg-primary-base h-432px px-42.5 py-30">
  <div class="rounded-30 flex flex-col">
    <p class="text-main-text text-gray-white w-295">
      Долучайтеся до нас у боротьбі за права та інтереси людей з деменцією та
      їхніх родин. Якщо ви маєте деменцію, піклуєтесь про когось із деменцією,
      проводите клінічні та соціальні дослідження чи надаєте послуги,
      представляєте державну чи неурядову організацію та маєте ідеї щодо
      адвокації - пишіть нам.
    </p>
    <app-link-button
      url="/about-us/about-fund/contacts"
      [linkButtonType]="'Primary'"
      [title]="'Зв’язатися з фондом'"
      class="w-55 flex mx-auto mt-15">
    </app-link-button>
  </div>
</section>
