import { Component, OnInit } from '@angular/core';
import IResearchCard from 'src/app/models/researchCard.interface';
import { otherPublicationsCards } from 'src/mockData//researchCardMockDate';

@Component({
  selector: 'app-other-publications-card',
  templateUrl: './other-publications-card.component.html',
  styleUrls: ['./other-publications-card.component.css'],
})
export class OtherPublicationsCardComponent implements OnInit {
  otherPublicationsCards: IResearchCard[] = otherPublicationsCards;
  constructor() {}

  ngOnInit(): void {}
}
