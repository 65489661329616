import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-projects-block',
  templateUrl: './our-projects-block.component.html',
  styleUrls: ['./our-projects-block.component.css'],
})
export class OurProjectsBlockComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
