import {
  MonthlyCardData,
  MonthlyData,
} from 'src/app/models/monthly-reports.interfaces';

export const MonthlyDataArray: MonthlyData[] = [
  {
    year: 2023,
    month: [
      {
        month: 'Січень',
        date: '01.2023',
        title: 'У Січні завдяки вам',
        summ: '214 000',
        helped: '134',
        imgUrl: 'assets/images/monthlyreportsPic/juneMounthlyRepfake.png',
        blockFirst:
          '<p class="mb-7">Ви забезпечили підопічних медикаментами та витратними матеріалами на 3,5 мільйона гривень, 2,4 мільйона з яких витратили на закупівлю закордонних ліків.</p><p>На 1,8 мільйона гривень було забезпечено лікарні медикаментами та витратними. Ще 1,7 мільйона гривень спрямували на закупівлю медичного обладнання, левову частку якого (1,6 мільйона гривень) – на придбання на мікробіологічного аналізатора для Миколаївської обласної дитячої клінічної лікарні.</p>',
        blockSecond:
          '<p class="mb-7">У червні лише за два дні Національного благодійного радіомарафону до Дня онкохворої дитини на Радіо НВ тисяча людей оформили підписку #наТаблеточки. Сума їхньої щомісячної допомоги дітям склала 240 тисяч гривень. Компанія АЛЛО додала ще 260 тисяч до перших внесків і в лютому діти отримали пів мільйона гривень життєво необхідної допомоги!</p><p class="text-main-text-bold">Ми безмежно вдячні благодійникам та партнерам фонду за підтримку людей із деменцією!</p>',
      },
      {
        month: 'Лютий',
        date: '02.2023',
        title: 'У лютому завдяки вам',
        summ: '100 000',
        helped: '234',
        imgUrl: 'assets/images/monthlyreportsPic/juneMounthlyRep.png',
        blockFirst:
          '<p class="mb-7">Ви забезпечили підопічних медикаментами та витратними матеріалами на 8 мільйона гривень, 7 мільйонів з яких витратили на закупівлю закордонних ліків.</p><p>На 1,8 мільйона гривень було забезпечено лікарні медикаментами та витратними. Ще 1,7 мільйона гривень спрямували на закупівлю медичного обладнання, левову частку якого (1,6 мільйона гривень) – на придбання на мікробіологічного аналізатора для Миколаївської обласної дитячої клінічної лікарні.</p>',
        blockSecond:
          '<p class="mb-7">У червні лише за два дні <a class="text-accent-base" href="/">Національного благодійного радіомарафону</a> до Дня онкохворої дитини на Радіо НВ тисяча людей оформили підписку #наТаблеточки. Сума їхньої щомісячної допомоги дітям склала 240 тисяч гривень. Компанія АЛЛО додала ще 260 тисяч до перших внесків і в лютому діти отримали пів мільйона гривень життєво необхідної допомоги!</p><p class="mb-10">У червні лише за два дні Національного благодійного радіомарафону до Дня онкохворої дитини на Радіо НВ тисяча людей оформили підписку #наТаблеточки. Сума їхньої щомісячної допомоги дітям склала 240 тисяч гривень. Компанія АЛЛО додала ще 260 тисяч до перших внесків і в лютому діти отримали пів мільйона гривень життєво необхідної допомоги!</p><p class="text-main-text-bold">Ми безмежно вдячні благодійникам та партнерам фонду за підтримку людей із деменцією!</p>',
      },
    ],
  },
];
export const monthlyCardData: MonthlyCardData = {
  title: 'Щомісячні звіти',
  text: '<p class="pb-7">Кожного місяця ми звітуємо про те, яку саме допомогу отримали люди з деменцією завдяки благодійникам. Також ми розповідаємо про основні досягнення у напрямку побудови суспільства, дружнього до людей з деменцією.</p><p class="pb-7">  Ми постійно аналізуємо потреби людей, що до нас звертаються, та шукаємо нові шляхи для втілення нашої місії. Обирайте рік і місяць нижче та читайте про головні події фонду та види наданої допомоги.</p>',
};
