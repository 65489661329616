import { Component, OnInit } from '@angular/core';
import { FaqPageDataInterface } from 'src/app/models/faqPageDataInterface';
import { FaqPageMockData } from 'src/mockData/faqPageMockData';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FAQComponent implements OnInit {
  faq: FaqPageDataInterface = FaqPageMockData;

  constructor() {}

  ngOnInit(): void {}
}
