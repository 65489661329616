import { Component, OnInit } from '@angular/core';
import newsCardInfo from '../../../../../models/newsCard.interface';
import { newsCardConfig } from 'src/mockData/newsCardConfig';

@Component({
  selector: 'app-block-news',
  templateUrl: './block-news.component.html',
  styleUrls: ['./block-news.component.css'],
})
export class BlockNewsComponent implements OnInit {
  newsCards: newsCardInfo[] = newsCardConfig;
  constructor() {}

  ngOnInit(): void {}
}
