import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-not-found',
  templateUrl: './report-not-found.component.html',
  styleUrls: ['./report-not-found.component.css'],
})
export class ReportNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
