<div class="content-container">
  <div class="text-gray-dark text-main-text">
    <div class="flex flex-row justify-between mb-20">
      <h2 class="text-primary-base uppercase text-h1-heading w-95">
        {{ faq.title }}
      </h2>
      <div class="w-195" [innerHTML]="faq.titleText"></div>
    </div>
    <app-accordion
      [AccordionsList]="faq.accordionListText"
      [classList]="'mb-30'"></app-accordion>
    <div class="flex flex-col justify-center items-start mb-18">
      <div class="flex flex-col items-start pb-11">
        <h2 class="text-h2-heading text-primary-base pb-8 uppercase">
          {{ faq.cantFind.title }}
        </h2>
        <p class="text-main-text" [innerHTML]="faq.cantFind.text"></p>
      </div>
    </div>
  </div>
</div>

<app-subscribe-news></app-subscribe-news>
