import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  SubscribeNewsDataMock,
  SubscribeNewsThansMessageDataMock,
} from 'src/mockData/subscribeNewsDataMock';
import { SubscribeNewsDataInterface } from 'src/app/models/subscribeNewsData.interface';
import { ISocialLink } from 'src/app/models/social-link.interface';
import { socialLinksMockData } from 'src/mockData/socialLinksMockData';
import { nameValidator } from 'src/app/components/forms/validators/name-validator';
import { emailValidator } from 'src/app/components/forms/validators/email-validator';

@Component({
  selector: 'app-subscribe-news',
  templateUrl: './subscribe-news.component.html',
  styleUrls: ['./subscribe-news.component.css'],
})
export class SubscribeNewsComponent implements OnInit {
  submitted: boolean = false;
  success: boolean = false;
  formSubscribeNews!: FormGroup;
  subscribeNewsData: SubscribeNewsDataInterface = SubscribeNewsDataMock;
  SubscribeNewsThansMessageData: SubscribeNewsDataInterface =
    SubscribeNewsThansMessageDataMock;
  socialsLinks: ISocialLink[] = socialLinksMockData.filter(
    (socialLink: ISocialLink) =>
      socialLink.image !== 'viber' && socialLink.image !== 'youtube',
  );
  constructor() {}
  name = new FormControl('', [Validators.required, nameValidator]);
  email = new FormControl('', [Validators.required, emailValidator]);
  ngOnInit(): void {
    this.formSubscribeNews = new FormGroup({
      name: this.name,
      email: this.email,
    });
  }

  onSubmit() {
    this.submitted = true;
    const form = this.formSubscribeNews.value;
    // this.http.post('http://localhost:4200/api/create-user', form).subscribe({
    //   next: (v) => console.log(v),
    //   error: (e) => console.error(e),
    //   complete: () => console.info('complete')

    //   })
    if (!this.formSubscribeNews.valid) {
      return false;
    } else {
      this.success = true;
      setTimeout(() => {
        this.success = false;
        this.submitted = false;
        this.formSubscribeNews.reset();
      }, 10000);
      return this.success;
    }
  }
}
