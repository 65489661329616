<div
  class="flex flex-row justify-start items-center flex-wrap mb-13 text-gray-dark">
  <div
    class="flex flex-row justify-center items-center w-48 h-12 border-2 color-gray-dark rounded-10 my-2.5 cursor-pointer text-smaller-bold"
    *ngFor="let sum of sumArray; let i = index"
    [ngClass]="{
      'mx-5': i === 1 || i === 4,
      'border-primary-base bg-primary-base text-gray-white': sum.checked
    }"
    (click)="setSumValue($event, sum)">
    {{ sum.value }}
  </div>
  <div class="relative">
    <input
      class="flex flex-row justify-center items-center w-48 h-12 border-2 color-gray-dark rounded-10 my-2.5 cursor-pointer text-center text-smaller-bold outline-none focus:outline-none focus:bg-gray-white appearance-none hover:appearance-none focus:appearance-none"
      id="summInput"
      type="{{ sumValue === 'Інша сума' ? 'text' : 'number' }}"
      (click)="active()"
      (input)="changeVal($event)"
      (keypress)="numberOnly($event)"
      [(ngModel)]="sumValue"
      [ngClass]="{
        'border-attention-base text-attention-base':
          sumControl.invalid && sumValue !== '',
        'border-primary-base text-gray-dark':
          (sumValue !== 'Інша сума' &&
            sumValue !== '0' &&
            !sumControl.errors) ||
          sumValue == ''
      }" />
    <small
      *ngIf="
        (!sumControl.valid && !inputActive) || sumControl.hasError('pattern')
      "
      class="w-60 absolute text-details text-attention-base -bottom-5 -left-3 animate-error-appear"
      >Будь ласка, введіть суму внеску</small
    >
  </div>
  <input class="hidden" type="number" [formControl]="sumControl" />
</div>
