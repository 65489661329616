import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit {
  @Input() productsCard: any;
  isHovered: boolean = false;

  articleUrls: string[] = [
    'https://zahid.espreso.tv/v-ukraini-zapustili-initsiativu-dlya-dopomogi-lyudyam-z-dementsieyu-v-umovakh-viyniv-ukraini-zapustili-initsiativu-dlya-dopomogi-lyudyam-z-dementsieyu-v-umovakh-viyni',
  ];
  youtubeChannelUrl: string = 'https://www.youtube.com/channel/';
  constructor() {}

  ngOnInit(): void {}

  openChannel() {
    if (this.productsCard.title === 'Відео') {
      window.open(this.youtubeChannelUrl);
    }
    if (this.productsCard.title === 'Стаття') {
      const url = this.articleUrls[0];
      window.open(url);
    }
  }
}
