<app-main-slider>
  <app-main-slides>
    <app-first-main-slide
      [title]="'ВІТАЄМО НА новому сайті фонду “НЕЗАБУТНІ”'"
      [text]="
        'Тут ви знайдете інформацію про деменцію, практичні рекомендації, а також зможете отримати допомогу'
      "
      [imgUrl]="'assets/images/bannerPicture/banner-first-slide.jpg'">
      <app-link-button
        class="btn"
        [title]="'Отримати допомогу'"
        [linkButtonType]="'Primary'"
        routerLink="/get-support">
      </app-link-button>
    </app-first-main-slide>
  </app-main-slides>
  <app-main-slides>
    <app-second-main-slide
      [title]="
        'Дізнайтеся, як ви можете підтримати людей з деменцією та їх родини!'
      ">
      <app-link-button
        class="btn"
        [title]="'Долучитися'"
        [linkButtonType]="'Primary'"
        routerLink="/join">
      </app-link-button>
    </app-second-main-slide>
  </app-main-slides>
  <app-main-slides>
    <app-third-main-slide
      [title]="
        'Підпишіться на наші соціальні мережі, щоб завжди бути у курсі головних подій фонду'
      ">
    </app-third-main-slide>
  </app-main-slides>
</app-main-slider>

<app-about-dementia-content></app-about-dementia-content>

<app-prevention-block></app-prevention-block>

<app-about-block></app-about-block>

<app-our-influence></app-our-influence>

<app-our-projects-block></app-our-projects-block>

<app-help-block [type]="'primary'"></app-help-block>

<app-block-news></app-block-news>

<app-subscribe-news></app-subscribe-news>
