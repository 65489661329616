import IProducts from 'src/app/models/productsCard.interface';

export const productsCardConfig: IProducts[] = [
  {
    title: 'Стаття',
    text: 'У Лондоні 08-12 червня відбулася найбільша Міжнародна конференція з деменції',
    block: '../../../assets/images/image/Rectangle.png',
    image: '../../../assets/images/image/1.jpg',
    button: 'Перейти',
    date: '22 вересня 2021',
    link: 'https://zahid.espreso.tv/v-ukraini-zapustili-initsiativu-dlya-dopomogi-lyudyam-z-dementsieyu-v-umovakh-viyniv-ukraini-zapustili-initsiativu-dlya-dopomogi-lyudyam-z-dementsieyu-v-umovakh-viyni',
  },
  {
    title: 'Стаття',
    text: 'У Лондоні 08-12 червня відбулася найбільша Міжнародна конференція з деменції',
    block: '../../../assets/images/image/горомадське.jpg',
    image: '../../../assets/images/image/2.jpg',
    button: 'Перейти',
    date: '22 вересня 2021',
    link: '',
  },
  {
    title: 'Відео',
    text: 'У Лондоні 08-12 червня відбулася найбільша Міжнародна конференція з деменції',
    block: '../../../assets/images/image/вісник.jpg',
    image: '../../../assets/images/image/3.jpg',
    button: 'Перейти',
    date: '22 вересня2021',
    link: '',
  },
  {
    title: 'Стаття',
    text: 'У Лондоні 08-12 червня відбулася найбільша Міжнародна конференція з деменції',
    block: '../../../assets/images/image/простір.jpg',
    image: '../../../assets/images/image/4.jpg',
    button: 'Перейти',
    date: '22 вересня 2021',
    link: '',
  },
  {
    title: 'Відео',
    text: 'У Лондоні 08-12 червня відбулася найбільша Міжнародна конференція з деменції',
    block: '../../../assets/images/image/вісник.jpg',
    image: '../../../assets/images/image/3.jpg',
    button: 'Перейти',
    date: '22 вересня 2021',
    link: '',
  },
  {
    title: 'Стаття',
    text: 'У Лондоні 08-12 червня відбулася найбільша Міжнародна конференція з деменції',
    block: '../../../assets/images/image/простір.jpg',
    image: '../../../assets/images/image/4.jpg',
    button: 'Перейти',
    date: '22 вересня 2021',
    link: '',
  },
  {
    title: 'Стаття',
    text: 'У Лондоні 08-12 червня відбулася найбільша Міжнародна конференція з деменції',
    block: '../../../assets/images/image/Rectangle.png',
    image: '../../../assets/images/image/1.jpg',
    button: 'Перейти',
    date: '22 вересня 2021',
    link: '',
  },
  {
    title: 'Стаття',
    text: 'У Лондоні 08-12 червня відбулася найбільша Міжнародна конференція з деменції',
    block: '../../../assets/images/image/горомадське.jpg',
    image: '../../../assets/images/image/2.jpg',
    button: 'Перейти',
    date: '22 вересня 2021',
    link: '',
  },
];
