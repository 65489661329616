import { Component, Input, OnInit } from '@angular/core';
import { moreInfoMemoryLoss } from 'src/mockData/moreInfoMockData';
import { moreInfoHeaderMemoryLoss } from 'src/mockData/moreInfoMainMockData';
import moreIInfo from 'src/app/models/moreInfo.interface';
import moreIInfoMain from 'src/app/models/moreInfoMainModel';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.css'],
})
export class MoreInfoComponent implements OnInit {
  @Input() moreInfos: moreIInfo[] = moreInfoMemoryLoss;
  @Input() mainText: moreIInfoMain[] = moreInfoHeaderMemoryLoss;

  constructor() {}

  ngOnInit(): void {}
}
