<app-page-banner-item
  text="Тут ви дізнаєтеся про те, як зрозуміти, чи розвивається у вас деменція чи ні; та що робити щоб запобігти появі деменції"
  title="Я турбуюся, що в мене деменція"
  [imgUrl]="
    'assets/images/banner/banner-i-worry-about-memory.webp'
  "></app-page-banner-item>

<section class="grid mt-30">
  <app-table-of-content
    class="flex mx-auto w-full px-4 md:px-0"
    [links]="cards"></app-table-of-content>
</section>

<section class="content-container text-start flex mt-30 px-4 md:px-0">
  <div [innerHTML]="lightText"></div>
</section>
<section class="content-container text-start flex mt-15 px-4 md:px-0">
  <div [innerHTML]="darkText"></div>
</section>

<section
  class="content-container text-start flex flex-col mb-30 px-4 md:px-0 mt-15">
  <app-page-link-cards-block
    [cardBlockId]="cards[0].id"
    [cardBlockTitle]="cards[0].title"
    [cardList]="sectionCards[0]"></app-page-link-cards-block>
</section>

<section class="content-container text-start flex flex-col mb-30 px-4 md:px-0">
  <app-page-link-cards-block
    [cardBlockId]="cards[1].id"
    [cardBlockTitle]="cards[1].title"
    [cardList]="sectionCards[1]"></app-page-link-cards-block>
</section>
<section class="content-container text-start flex flex-col mb-30 px-4 md:px-0">
  <app-page-link-cards-block
    [cardBlockId]="cards[2].id"
    [cardBlockTitle]="cards[2].title"
    [cardList]="sectionCards[2]"></app-page-link-cards-block>
</section>
<section class="content-container text-start flex flex-col mb-30 px-4 md:px-0">
  <app-page-link-cards-block
    [cardBlockId]="cards[3].id"
    [cardBlockTitle]="cards[3].title"
    [cardList]="sectionCards[3]"></app-page-link-cards-block>
</section>
