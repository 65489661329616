import moreIInfoMain from 'src/app/models/moreInfoMainModel';

export const moreInfoHeaderMemoryLoss: moreIInfoMain[] = [
  {
    mainHeader: 'Більше інформації про проблеми з памʼяттю',
    mainContent:
      'Дізнайтеся більше про деменцію, її симптоми, що робити, якщо ви хвилюєтесь, а також про підтримку, яку надає Товариство Альцгеймера. Незалежно від ваших питань чи проблем, ми можемо допомогти.',
  },
];

export const moreInfoHeaderLegalAid: moreIInfoMain[] = [
  {
    mainHeader: ' Більше інформації про види допомоги',
    mainContent:
      'Дізнайтеся більше про деменцію, її симптоми, що робити, якщо ви хвилюєтесь, а також про підтримку, яку надає Товариство Альцгеймера. Незалежно від ваших питань чи проблем, ми можемо допомогти.',
  },
];
