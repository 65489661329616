import IHelpWithMedicine from 'src/app/models/helpWithMedicine.interface';

export const MedicineForPersonWithDementia: IHelpWithMedicine[] = [
  {
    title: `<h2 class="text-h2-heading text-primary-base uppercase mb-5">Як отримати ліки для людини <br />з деменцією?</h2>`,
    text: `
      <li>
        Перегляньте перелік ліків, доступних до замовлення. Порівняйте їх з тими
        препаратами, що призначив лікар. 
      </li>
      <li>
        Звертайте увагу на назви діючих речовин та дозування. Якщо дозування
        відрізняються від тих, що призначили вам, обов’язково порадьтеся зі
        своїм лікарем, або запишіться на безкоштовну онлайн консультацію, адже
        деякі таблетки не діляться.
      </li>
      <li>
        Переконайтеся, що маєте на руках рецепт, заключення лікаря чи виписку з
        лікарні. Для отримання рецептурних препаратів необхідно буде надіслати
        консультанту фото одного з цих документів.
      </li>
      <li>Заповніть заявку та очікуйте на повідомлення від консультанта.</li>`,
  },
];

export const MedicineForTheInstitution: IHelpWithMedicine[] = [
  {
    title: 'Як отримати ліки установам?',
    info: ' Якщо ви представляєте заклад психоневрологічного чи геріатричного профілю, ви можете замовити ліки та медичні засоби для ваших підопічних чи пацієнтів. Готове замовлення можна забрати власним транспортом з Інституту геронтології. У разі відсутності такої можливості ми допоможемо з доставкою.',
    text: ` <li>
          Перегляньте
          <a class="text-accent-base hover:text-accent-additional underline cursor-pointer"
          href="/assets/pdf/glossary.pdf" 
          download>
          перелік</a>
          ліків та медичних засобів. Звертайте увагу на терміни придатності, а також
          кількість одиниць (таблеток, грам, мілілітрів тощо) в упаковці.
        </li>
        <li>
          Підготуйте лист на бланку вашого закладу та внесіть туди ваше
          замовлення
          <a
            class="text-accent-base hover:text-accent-additional underline"
            (click)="openGoogleDocs()"
            >Завантажити шаблон запиту</a
          >
        </li>
        <li>
          Надішліть скан-копію листа з вашим запитом на адресу:<a
            class="text-accent-base hover:text-accent-additional underline cursor-pointer"
            (click)="copyMail()">
            a.kovalenko@nezabutni.org
          </a>
        </li>`,
  },
];

export const MoreAboutTheProject: IHelpWithMedicine[] = [
  {
    title: `<h2 class="text-h2-heading text-primary-base uppercase mb-5">Детальніше про проект <br />"Допомога з ліками"</h2>`,
    text: `<p class="text-main-text text-gray-dark mb-7">Допомога з ліками - це перший проєкт фонду, направлений на гуманітарну допомогу. Він розпочався у кінці лютого 2022 року у зв’язку з початком повномасштабного вторгнення в Україну. Закриття аптек, призупинення роботи служб доставки, відсутність нових поставок ліків та значне підвищення їх вартості значно ускладнило доступ до життєво необхідних препаратів для людей з деменцією. Ми розпочали з пошуку ліків у різних містах України та їх доставки людям з деменцією волонтерами. Паралельно ми шукали способи доставити більше ліків в Україну та підтримати більшу кількість людей. </p>
<p class="text-main-text text-gray-dark mb-7">Завдяки нашим партнерам Americares, уже в березні була здійснена перша поставка ліків та медичних засобів, вагою в кілька тонн. Протягом 2022 року відбулося вісім таких поставок, завдяки яким ми отримали ліки на 5 млн доларів. Ми заручилися підтримкою волонтерів, зокрема фармацевтів та лікарів, а також Інституту геронтології ім. Чеботарьова та Центру організації психіатричної допомоги. Завдяки спільним зусиллям за 2022 рік ліки було доставлено більше 40 000 людей, що проживають вдома або в закладах стаціонарного догляду. </p>
<p class="text-main-text text-gray-dark mb-7">Також партнерами фонду у цьому проєкті виступає Національна Соціальна Сервісна Служба України. Завдяки співпраці та координації НСССУ фонд централізовано забезпечив ліками заклади соціального захисту у 4 областях. В цілому, на сьогодні ліки були відвантажені на заклади у 9 областях України. </p>
<p class="text-main-text text-gray-dark mb-7">Нижче ви можете переглянути фото з доставки ліків у заклади та фото-відгуки людей, що отримали ліки адресно.</p>`,
  },
];
