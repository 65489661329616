import { Component, OnInit } from '@angular/core';
import { IPreventionCard } from '../../models/prevention-card.interface';

@Component({
  selector: 'app-prevention-block',
  templateUrl: './prevention-block.component.html',
  styleUrls: ['./prevention-block.component.css'],
})
export class PreventionBlockComponent implements OnInit {
  protected preventionCards: IPreventionCard[];

  constructor() {
    this.preventionCards = [
      {
        icon: 'ic-sport',
        imageLink: '../../../assets/images/prevention_block/dumbbells.png',
        text: 'Фізична активність',
      },
      {
        icon: 'ic-food',
        imageLink: '../../../assets/images/prevention_block/healthy_eating.png',
        text: 'Здорове харчування',
      },
      {
        icon: 'ic-brain',
        imageLink: '../../../assets/images/prevention_block/brain_training.png',
        text: 'Тренування мозку',
      },
      {
        icon: 'ic-stethoscope',
        imageLink: '../../../assets/images/prevention_block/stethoscope.png',
        text: 'Регулярні обстеження',
      },
    ];
  }

  ngOnInit(): void {}
}
