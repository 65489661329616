<div class="mt-5 pb-5 flex items-center">
  <svg
    class="fill-accent-base group-hover/svg:fill-secondary-base active:fill-secondary-additional"
    [icon]="contact.image"
    iconCollection="social-icons-sprite"
    [attr.width]="contact.width"
    [attr.height]="contact.height"></svg>
  <div class="flex flex-col ml-3.75">
    <span class="text-gray-dark text-main-text mb-1.25">{{
      contact.heading
    }}</span>
    <span class="text-gray-dark w-70">{{ contact.contact }}</span>
  </div>
</div>
