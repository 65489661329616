<section class="container text-start px-2 md:px-0">
  <div class="flex justify-between">
    <h3
      class="text-h2-heading text-primary-base mb-5 uppercase w-full md:w-[580px] mt-5">
      {{ detailedNews.title }}
    </h3>
    <div class="flex mt-5 text-gray-details text-main-text">
      <svg
        width="22px"
        height="20px"
        icon="book"
        iconCollection="button-icons-sprite"
        class="svg items-start text-gray-dark"></svg>
      <span class="text-gray-dark text-main-text ml-2 justify-end">{{
        detailedNews.date
      }}</span>
    </div>
  </div>

  <div class="flex mb-30">
    <div>
      <img
        *ngIf="detailedNews.detailPictureLink"
        class="h-full object-contain md:h-100 md:float-right md:w-145 mx-auto pb-10 md:pb-0 md:ml-5 rounded-30"
        src="{{ detailedNews.detailPictureLink }}"
        alt="{{ detailedNews.title }}" />

      <p
        class="mb-7 text-lg text-gray-dark text-main-text"
        *ngFor="let paragraph of detailedNews.text.split('/p')">
        {{ paragraph }}
      </p>
      <div class="mt-17">
        <p
          class="mb-7 text-lg text-gray-dark text-main-text"
          *ngFor="let paragraph of detailedNews.content.split('/p')">
          {{ paragraph }}
        </p>
      </div>
    </div>
  </div>
</section>
<app-subscribe-news></app-subscribe-news>
