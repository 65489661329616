<div class="w-295 flex flex-col items-center mx-auto">
  <div class="flex items-start gap-5 mb-20">
    <h3 class="text-primary-base text-h1-heading w-95 uppercase">
      Наші Партнери
    </h3>
    <p class="text-lg w-195 text-gray-dark">
      Прочитайте про наші поточні партнерства, як вони співпрацюють з нами та що
      ваша компанія може зробити, щоб підтримати людей, уражених деменцією,
      розпочавши корпоративне партнерство з нами для постійної підтримки.
    </p>
  </div>
</div>
<div class="items-center mx-auto w-295 mb-10">
  <h2 class="text-h2-heading text-primary-base uppercase mb-5">Візіонери</h2>
  <p class="text-main-text text-gray-dark">
    Консультативний комітет Незабутні є національною платформою для людей, які
    живуть з деменцією, щоб спільно з Незабутні виступати з важливих для них
    питань.
  </p>
</div>
<app-visionary-card
  class="flex flex-col justify-start mx-auto w-295 mb-30"></app-visionary-card>
<app-fund-partners-block></app-fund-partners-block>
<app-help-block [type]="'primary'"></app-help-block>
