<div
  (click)="openChannel()"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false"
  class="w-145 group cursor-pointer rounded overflow-hidden">
  <img
    class="w-full object rounded-t-3xl"
    [src]="productsCard.image"
    alt="Три людини стоять та посміхаються" />

  <div
    class="px-8 border-4 border-gray-stroke border-t-gray-white rounded-3xl rounded-t-none group-hover:border-t-gray-white group-hover:border-secondary-base">
    <div class="flex mt-3 items-center justify-start">
      <a href="" class="">
        <img class="" [src]="productsCard.block" />
      </a>
      <h3 class="pl-5 text-gray-details text-main-text">
        {{ productsCard.title }}
      </h3>
      <div
        class="flex text-gray-details text-main-text pl-10 items-center justify-start">
        <svg
          width="22px"
          height="20px"
          icon="book"
          iconCollection="button-icons-sprite"
          class="svg fill-gray-details"></svg>
        <p class="ml-2">{{ productsCard.date }}</p>
      </div>
    </div>
    <p class="font-bold text-h3-subheading my-5 text-gray-dark">
      {{ productsCard.text }}
    </p>

    <div class="w-55 mt-10 mb-10">
      <app-link-button
        [title]="'Перейти'"
        [linkButtonType]="'Primary'"
        [isHovered]="isHovered">
      </app-link-button>
    </div>
  </div>
</div>
