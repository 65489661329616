export const requisitesPaid = [
  [
    'main',
    { name: 'UAH', value: 'main' },
    { name: 'Код ЄДРПОУ', value: '44228139' },
    { name: 'IBAN', value: 'UA603052990000026002036228556' },
    { name: 'МФО', value: '44228139' },
    { name: 'Банк', value: 'АТ КБ «ПРИВАТБАНК»' },
  ],
  [
    'main',
    { name: 'EUR', value: 'main' },
    { name: 'Correspondent bank', value: 'АТ КБ «ПРИВАТБАНК»' },
    { name: 'Correspondent bank SWIFT/BIC code', value: 'PRCBDEFF' },
    { name: 'Beneficiary bank', value: 'JSC ProCredit Bank, Kyiv, Ukraine' },
    { name: 'Beneficiary SWIFT/BIC code', value: 'MIFCUAUK' },
    { name: 'Beneficiary name', value: '«Nezabutni»' },
    { name: 'IBAN', value: 'UA563209840000026007210322320' },
  ],
  [
    'main',
    { name: 'USD', value: 'main' },
    { name: 'Correspondent bank', value: 'АТ КБ «ПРИВАТБАНК»' },
    { name: 'Correspondent bank SWIFT/BIC code', value: 'PRCBDEFF' },
    { name: 'Beneficiary bank', value: 'JSC ProCredit Bank, Kyiv, Ukraine' },
    { name: 'Beneficiary SWIFT/BIC code', value: 'MIFCUAUK' },
    { name: 'Beneficiary name', value: '«Nezabutni»' },
    { name: 'IBAN', value: 'UA563209840000026007210322320' },
  ],
  [
    'main',
    { name: 'ZLT', value: 'main' },
    { name: 'Correspondent bank', value: 'АТ КБ «ПРИВАТБАНК»' },
    { name: 'Correspondent bank SWIFT/BIC code', value: 'PRCBDEFF' },
    { name: 'Beneficiary bank', value: 'JSC ProCredit Bank, Kyiv, Ukraine' },
    { name: 'Beneficiary SWIFT/BIC code', value: 'MIFCUAUK' },
    { name: 'Beneficiary name', value: '«Nezabutni»' },
    { name: 'IBAN', value: 'UA563209840000026007210322320' },
  ],
];
