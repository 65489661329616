<app-page-banner-item
  [title]="'Стати волонтером'"
  [text]="
    'Ставайте партнером фонду! Співпрацюючи з нами, ви збільшуєте можливості для системних змін і допомоги сім’ям «тут і зараз».'
  "
  [imgUrl]="'./assets/images/become-volunteer/become-volunteer-baner.jpg'">
  <div class="flex mt-5">
    <div class="w-55 mr-11.75">
      <app-link-button [title]="'Стати волонтером'"> </app-link-button>
    </div>
    <button class="text-gray-white flex items-center">
      <svg
        width="28px"
        height="28px"
        icon="copy"
        iconCollection="button-icons-sprite"
        class="svg fill-gray-white hover:fill-secondary-additional active:fill-secondary-additional pr-2.5"></svg>
      <span class="w-fit h-fit">info@nezabutni.org</span>
    </button>
  </div>
</app-page-banner-item>

<div class="flex justify-center pt-30.5 pb-17.5">
  <div class="content-container">
    <h2 class="text-h2-heading text-primary-base uppercase w-120 h-fit">
      Напрямки волонтерської допомоги
    </h2>

    <div class="mt-5 text-main-text text-gray-dark w-144.75 h-fit">
      <p>
        Досягти змін у суспільстві і створити дружнє до деменції середовище
        простіше разом. Тож давате об’єднуватись, вчитися, ділитися ідеями та
        планами! Ми дуже хочемо, щоб виникла дружня, ідейна і весела спільнота
        людей, які разом змінюють цей світ на краще!
      </p>

      <span class="flex mt-6.75">
        Ми потребуємо наступні напрямки волонтерської діяльності:
      </span>
      <ul class="list-disc pl-7">
        <li class="mb-2.5">Пошук і аналіз інформації для сайту</li>
        <li class="mb-2.5">Редактура текстів</li>
        <li class="mb-2.5">
          Допомога з офісом, майданчиком для проведення івентів, конференції
        </li>
        <li class="mb-2.5">Фінансова допомога</li>
        <li class="mb-2.5">Написання грантів та фандрейзингу</li>
        <li class="mb-2.5">Спеціалісти-психологи і тд</li>
      </ul>
    </div>
  </div>
</div>

<div class="bg-gray-card flex justify-center">
  <div class="content-container pt-20.25 pb-20 relative">
    <div class="flex">
      <app-flower-svg [digit]="1"></app-flower-svg>
      <p class="text-main-text text-gray-dark w-125 mt-2 ml-6.5">
        <span class="font-semibold">«Школа турботи»</span> — серія безкоштовних
        тренінгів для родичів та доглядальників, на яких піднімаються медичні,
        соціальні та правові питання. Модератори тренінгів - фахівці дружнього
        фонду «Альцрус» та гостьові лектори. Наразі тренінги «Школи турботи»
        проводяться онлайн за попередньою реєстрацією.
      </p>
    </div>
    <img
      class="absolute right-0 bottom-20"
      src="./assets/images/become-volunteer/school.png"
      alt="Школа турботи" />
  </div>
</div>

<div class="flex justify-center">
  <div class="content-container relative pt-50 pb-75">
    <div class="flex justify-end">
      <app-flower-svg [digit]="2"></app-flower-svg>
      <div class="text-main-text text-gray-dark w-145 ml-6.25 mt-2.25 h-fit">
        <p>
          <span class="font-semibold">Онлайн-воркшопи</span> — тематичні
          зустрічі для родичів, доглядальників та всіх зацікавлених в темі
          деменції, що проводяться фахівцями фонд “Незабутні” та запрошеними
          спікерами. На зустрічах можна дізнатися базову та детальну інформацію
          про різні аспекти життя з деменцією, отримати корисні посилання та
          інструменти.
        </p>
        <p class="mt-7">
          Участь у воркшопах безкоштовна за здійснюється за попередньою
          реєстрацією.
        </p>
      </div>
    </div>
    <img
      class="absolute left-0 bottom-20"
      src="./assets/images/become-volunteer/workshops.png"
      alt="Онлайн-воркшопи" />
  </div>
</div>

<div class="bg-primary-base flex justify-center">
  <div class="content-container py-20 relative">
    <div class="flex">
      <app-flower-svg [digit]="3"></app-flower-svg>
      <div class="text-gray-white pt-2 pl-6.5">
        <h3 class="text-h3-subheading mb-5 h-fit">Психологічна підтримка</h3>
        <p class="text-main-text w-125 h-fit">
          Коли хтось із рідних починає хворіти на деменцію, це змінює спосіб
          життя всієї родини. І найбільший тягар лягає саме на людину, яка
          доглядає за хворим. Це важкий, виснажливий процес, який потребує
          підтримки! Адже благополуччя людини з деменцією безпосередньо
          пов’язано зі станом, у якому перебуває його доглядальник. З цією метою
          в БФ “Незабутні” створена група підтримки родичів.
        </p>
      </div>
    </div>
    <img
      class="absolute right-0 top-25"
      src="./assets/images/our projects block/psychological_support.png"
      alt="Психологічна підтримка" />
  </div>
</div>

<div class="flex justify-center mb-51.75">
  <div class="content-container">
    <p class="text-main-text text-gray-dark w-145 mt-15 h-fit">
      На щомісячних терапевтичних зустрічах групи ми говоримо про те, як
      підтримувати і відновлювати свій психічний та емоційний ресурс, як вносити
      сенси та радість у відносини з близькою людиною з деменцією.
    </p>
  </div>
</div>

<div class="flex justify-center mb-30">
  <div class="content-container flex flex-col items-center">
    <p class="text-main-text text-gray-dark h-fit">
      На щомісячних терапевтичних зустрічах групи ми говоримо про те, як
      підтримувати і відновлювати свій психічний та емоційний ресурс, як вносити
      сенси та радість у відносини з близькою людиною з деменцією.
    </p>
    <div class="w-55 mt-10">
      <app-link-button [title]="'Стати волонтером'"> </app-link-button>
    </div>
  </div>
</div>
