<div *ngIf="links" class="block mx-auto w-full">
  <div
    routerLink="{{ titleLink }}"
    appScrollToSectionAfterLoadData
    scrollToSection="{{ titleLinkId }}"
    (click)="stop($event)"
    [queryParams]="queryParams"
    class="text-h3-subheading text-primary-base border-b-2 border-b-primary-base hover:text-secondary-base hover:border-b-secondary-base mb-5 pb-1.5"
    [ngClass]="{
      'border-b-0': links.length === 0 && titleLink.includes('get-support')
    }">
    {{ title }}
  </div>
  <ul class="flex flex-col gap-5">
    <li *ngFor="let link of links" [ngSwitch]="!!link.id">
      <a
        *ngSwitchCase="true"
        appScrollToSectionAfterLoadData
        [routerLink]="link.link"
        scrollToSection="{{ link.id }}"
        (click)="stop($event)"
        class="text-gray-dark text-main-text hover:text-secondary-base"
        [ngClass]="{
          'text-accent-base text-main-text-bold': link.title === 'Контакти'
        }"
        >{{ link.title }}
      </a>
      <a
        *ngSwitchCase="false"
        [routerLink]="link.link"
        (click)="stop($event)"
        class="text-gray-dark text-main-text hover:text-secondary-base"
        [ngClass]="{
          'text-accent-base text-main-text-bold': link.title === 'Контакти'
        }"
        >{{ link.title }}
      </a>
    </li>
  </ul>
</div>
