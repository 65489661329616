<section>
  <app-page-banner-item
    [title]="'Дослідження'"
    [text]="
      'На цій сторінці ви можете ознайомитися з дослідженнями щодо деменції в Україні, проведеними БФ Незабутні та партнерів, а також взяти участь в актуальних дослідженнях'
    "
    [imgUrl]="
      '../../../../assets/images/banner/banner-our-programs.jpg'
    "></app-page-banner-item>
  <div class="mt-30">
    <app-table-of-content [links]="links"></app-table-of-content>
  </div>
  <div class="container">
    <h2
      id="fund_research"
      class="uppercase text-h2-heading text-primary-base mt-30 mb-10">
      Дослідження фонду Незабутні
    </h2>
    <app-research-card></app-research-card>
    <app-button
      type="submit"
      class="w-56 text-main-text-bold mx-auto mb-15 mt-10 flex justify-center"
      [buttonType]="'Secondary'"
      [title]="'Дивитись більше'">
    </app-button>
    <h2
      id="other_publications"
      class="uppercase text-h2-heading text-primary-base mt-30 mb-5">
      Інші публікації
    </h2>
    <p class="text-main-text text-gray-dark mb-10">
      Тут подані результати міжнародних досліджень, у яких брали участь
      українці, а також дослідження партнерів фонду "Незабутні!.
    </p>
    <app-other-publications-card></app-other-publications-card>
  </div>
</section>
<app-current-research-info></app-current-research-info>
