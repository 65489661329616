import { Component, OnInit } from '@angular/core';
import IInfluence from '../../models/influence.interface';
import { ButtonType } from '../button/button.component';

// type ButtonType2 = 'Donate' | 'Primary' | 'Secondary';
@Component({
  selector: 'app-our-influence',
  templateUrl: './our-influence.component.html',
  styleUrls: ['./our-influence.component.css'],
})
export class OurInfluenceComponent implements OnInit {
  buttonType: ButtonType = 'Primary';
  // buttonTitle = 'Перейти до звітів';
  constructor() {}

  influences: IInfluence[] = [
    {
      icon: 'cash',
      img: 'assets/images/influences/money.png',
      title: '123 000',
      subtitle: '₴',
      content: 'сума наданої допомоги',
    },
    {
      icon: 'account-group-outline',
      img: 'assets/images/influences/pill.png',
      title: '1234',
      content: 'кількість людей, які отримали допомогу',
    },
    {
      icon: 'map-marker-outline',
      img: 'assets/images/influences/clock.png',
      title: '56',
      content: 'кількість областей, де була надана допомога',
    },
    {
      icon: 'medication-outline',
      img: 'assets/images/influences/people.png',
      title: '142',
      content: 'кількість закладів, які отримали ліки та генератори',
    },
    {
      icon: 'clock-outline',
      img: 'assets/images/influences/heart.png',
      title: '456',
      content: 'кількість годин волонтерської роботи',
    },
    {
      icon: 'file-document-edit-outline',
      img: 'assets/images/influences/search.png',
      title: '6',
      content: 'кількість досліджень та публікацій фонду',
    },
  ];

  functionCall(event: any) {
    console.log('functionCall', event);
  }
  ngOnInit(): void {}
}
