<section
  class="container text-start"
  *ngFor="let detailedNew of detailedNewsPressRealize">
  <div class="flex justify-between">
    <h3 class="text-h2-heading text-primary-base mb-5 uppercase">
      {{ detailedNew.title }}
    </h3>
    <div class="flex text-gray-details text-main-text">
      <svg
        width="22px"
        height="20px"
        icon="book"
        iconCollection="button-icons-sprite"
        class="svg items-start fill-gray-details"></svg>
      <span class="ml-2 justify-end">{{ detailedNew.date }}</span>
    </div>
  </div>

  <p
    class="mb-5 text-lg text-gray-dark text-main-text"
    *ngFor="let paragraph of detailedNew.text.split('/p')">
    {{ paragraph }}
  </p>

  <div class="flex justify-center mt-15 mb-30">
    <app-button
      (click)="downloadPdfFile()"
      [title]="'Завантажити PDF'"
      class="w-70 mr-10"
      [buttonType]="'Primary'"
      [svg]="true">
      <svg
        width="25px"
        height="20px"
        icon="download"
        iconCollection="button-icons-sprite"
        class="svg fill-gray-white mt-1 mr-2"></svg>
    </app-button>

    <app-button
      class="w-70"
      type="button"
      [buttonType]="'Secondary'"
      [routerLink]="'/about-us/news-and-media/media-center/press-releases'"
      [title]="'Більше пресрелізів'"></app-button>
  </div>

  <div class="flex mb-30">
    <svg
      width="10px"
      height="96px"
      icon="line"
      iconCollection="button-icons-sprite"
      class="svg items-start z-1"></svg>
    <div class="text-h3-subheading ml-5 pt-5">
      <p class="">
        Контакти медіа центру:
        <span
          class="text-accent-base underline cursor-pointer"
          (click)="copyMail()"
          >media@nezabutni.org</span
        >
      </p>
      <p class="">
        Говорячи чи пишучи про деменцію, звертайтеся до
        <a
          href="/about-us/news-and-media/media-center/glossary"
          class="text-accent-base underline"
          >"Глосарію рекомендованих термінів"</a
        >
      </p>
    </div>
  </div>
</section>
