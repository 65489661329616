<img
  class="md:h-205"
  src="../assets/images/banner/banner-join.png"
  alt="banner" />
<div class="content-container text-start flex px-4 md:px-0 sm:mt-30 mt-20">
  <p class="text-gray-dark text-main-text">
    Кожна людина час від часу може щось забувати, і зазвичай це нормально. Але
    якщо проблеми з пам’яттю впливають на ваше повсякденне життя, краще
    звернутися до лікаря загальної практики.
  </p>
</div>

<div class="content-container mt-10 mb-30">
  <app-info-cards [cards]="cards"></app-info-cards>
</div>
