<form class="w-145 h-156 border-2 border-gray-stroke rounded-30 p-10">
  <div class="flex justify-between items-center">
    <h3 class="text-h2-heading uppercase text-primary-base">
      Дякуємо за звернення!
    </h3>
    <svg
      width="19px"
      height="19px"
      [icon]="'close'"
      iconCollection="button-icons-sprite"
      class="text-gray-dark cursor-pointer"></svg>
  </div>

  <p class="text-main-text text-gray-dark mt-5 mb-10">
    Ми розглянемо Ваш запит, і наш співробітник зв'яжеться з Вами протягом двох
    робочих днів.
  </p>
  <app-okey class="flex justify-center mb-10"></app-okey>

  <app-button
    [title]="'Надіслати ще лист'"
    [type]="'submit'"
    class="text-main-text-bold"></app-button>
</form>
