<app-page-banner-item
  title="Навчання"
  text="Дізнайтеся про наші навчальні та інформаційні заходи для родичів та доглядальників, працівників соціальної та медичної сфери, а також усіх зацікавлених у темі деменції"
  [imgUrl]="'./assets/images/banner/banner-study.jpg'"></app-page-banner-item>

<section class="grid mt-30 px-2 xl:px-0 sm:px-10">
  <app-table-of-content
    class="flex mx-auto w-full"
    [links]="links"></app-table-of-content>
</section>

<div
  class="mx-auto content-container text-start px-4 xl:px-0 mt-30 sm:px-10"
  *ngFor="let learn of learning">
  <div class="md:flex">
    <div class="">
      <h2
        id="SchoolOfCare"
        class="text-h2-heading uppercase text-primary-base mb-5">
        {{ learn.title }}
      </h2>
      <p
        class="text-main-text text-gray-dark mb-5 xl:w-145 sm:w-100"
        [innerHTML]="learn.text"></p>
    </div>
    <img
      class="rounded-3xl md:ml-5 sm:pr-10 md:px-0 xl:h-100 md:h-67.5"
      src="{{ learn.src }}" />
  </div>

  <div class="flex mt-10">
    <svg
      width="10px"
      height="96px"
      icon="line"
      iconCollection="button-icons-sprite"
      class="svg items-start z-1"></svg>

    <div class="text-h3-subheading ml-5 xl:pt-5">
      <p class="">{{ learn.data }}</p>
      <p class="" [innerHTML]="learn.link"></p>
    </div>
  </div>

  <div
    class="mx-auto content-container text-start mt-30"
    *ngFor="let learn of learningText">
    <div class="md:flex">
      <img
        class="rounded-3xl md:mr-5 xl:h-100 md:h-67.5"
        src="{{ learn.src }}" />
      <div class="">
        <h2
          id="Webinars"
          class="text-h2-heading uppercase text-primary-base md:mt-0 mt-10 mb-5">
          {{ learn.title }}
        </h2>
        <p
          class="text-main-text text-gray-dark mb-5 xl:w-145 sm:w-100"
          [innerHTML]="learn.text"></p>
      </div>
    </div>
    <div class="flex mt-10">
      <svg
        width="10px"
        height="68px"
        icon="line"
        iconCollection="button-icons-sprite"
        class="svg items-start z-1"></svg>
      <div class="text-h3-subheading ml-5 xl:pt-5">
        <p class="" [innerHTML]="learn.link"></p>
      </div>
    </div>

    <p
      class="text-main-text text-gray-dark mb-30 mt-10 content-container text-start">
      Ви можете також переглянути вебінари у записі на
      <a
        class="text-accent-base text-main-text-bold hover:text-accent-additional underline"
        href="https://www.youtube.com/"
        >Ютуб сторінці.
      </a>
    </p>
  </div>
</div>

<div class="" *ngFor="let learn of learningInfo">
  <div class="bg-gray-card">
    <div
      class="container pt-30 pb-23 mx-auto content-container text-start px-4 md:px-0 sm:px-10">
      <h2
        id="TrainingForService"
        class="text-h2-heading uppercase text-primary-base mb-5 w-145">
        {{ learn.title }}
      </h2>
      <p class="text-main-text text-gray-dark" [innerHTML]="learn.text"></p>
    </div>
  </div>

  <div class="mx-auto content-container text-start px-4 md:px-0 py-30 sm:px-10">
    <h2
      id="UsefulMaterials"
      class="text-h2-heading uppercase text-primary-base mb-5 w-145">
      Корисні матеріали
    </h2>
    <p class="text-main-text text-gray-dark mb-10">
      Ви також можете поглиблювати знання в темі деменції та опановувати нові
      навички самостійно. Ми постійно шукаємо свіжу та корисну інформацію,
      перекладаємо іноземні матеріали та пишемо власні, щоб допомогти вам краще
      розуміти та бути готовими до усіх викликів, пов’язаних з деменцією.
    </p>

    <div class="flex mt-3 pb-15">
      <svg
        width="10px"
        height="68"
        px
        icon="line"
        iconCollection="button-icons-sprite"
        class="svg items-start z-1"></svg>

      <div class="text-h3-subheading ml-5 xl:pt-5">
        <p class="">
          Завантажуйте навчальні та інформаційні матеріали на сторінці
          <a
            class="text-accent-base text-h3-subheading hover:text-accent-additional underline"
            href="/need-help/informational-assistance">
            Інформаційна допомога
          </a>
        </p>
      </div>
    </div>
    <app-link-button
      [routerLink]="'/about-us/about-fund/contacts'"
      class="sm:w-55 flex justify-center items-center mx-auto"
      [title]="'Зв’язатися з фондом'"
      [linkButtonType]="'Primary'"
      [isHovered]="isHovered">
    </app-link-button>
  </div>
</div>
