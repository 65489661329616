import { Component, OnInit } from '@angular/core';
import IInfoPage from '../../models/infoPage.interface';
import { joinCardConfig } from 'src/mockData/joinCardConfig';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css'],
})
export class JoinComponent implements OnInit {
  constructor() {}
  cards: IInfoPage[] = joinCardConfig;
  ngOnInit(): void {}
}
