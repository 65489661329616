import {
  Directive,
  HostBinding,
  HostListener,
  Input,
  NgZone,
} from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';

@Directive({
  selector: '[appScrollToSectionAfterLoadData]',
})
export class ScrollToSectionAfterLoadDataDirective {
  @Input() scrollToSection!: string;

  constructor(private ngZone: NgZone, private scrollService: ScrollService) {}

  @HostBinding('style.scroll-padding-top') scrollPaddingTop: string = '0px';

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    event.preventDefault();
    this.scrollToTargetSection();
    this.scrollService.isLoaded$.subscribe((isLoaded: boolean) => {
      if (isLoaded) {
        this.scrollToTargetSection();
      }
    });
  }

  scrollToTargetSection() {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const section = document.getElementById(this.scrollToSection);
        if (section) {
          const topOffset = section.offsetTop - 140;
          this.ngZone.run(() => {
            this.scrollPaddingTop = `${topOffset}px`;
          });
          window.scroll({ top: topOffset, behavior: 'smooth' });
        } else {
          window.scroll({ top: 0, behavior: 'smooth' });
        }
      });
    });
  }
}
