export const REQUIRED = 'Заповніть поле.';

// Text fields
export const INVALID_SYMBOLS = 'Будь ласка, введіть коректні символи';
export const EXTENDED_INVALID_SYMBOLS =
  "Будь ласка, використовуйте дозволені символи: Aa-Zz, Аа-Яя, 0-9, крапка(.), апостроф('), тире(-), пробіл";

// Email
export const INVALID_EMAIL = 'Будь ласка, введіть дійсний e-mail';
export const EXTENDED_INVALID_EMAIL =
  "Будь ласка, введіть адресу латиницею у форматі local-part@domain.com, дозволені спеціальні символи для local-part: !#$%&'*+-/=?^_`{|}~0-9";
