import { Component, OnInit } from '@angular/core';
import IInfoPage from 'src/app/models/infoPage.interface';
import { IKeyFactsAboutDementia } from 'src/app/models/KeyFactsAboutDementia.interface';
import { keyDementiaCardsConfig } from 'src/mockData/keyDementiaCardsConfigMockData';
import { KeyFactsAboutDementiaMockData } from 'src/mockData/keyFactsAboutDementiaMockData';

@Component({
  selector: 'app-key-facts-about-dementia',
  templateUrl: './key-facts-about-dementia.component.html',
  styleUrls: ['./key-facts-about-dementia.component.css'],
})
export class KeyFactsAboutDementiaComponent implements OnInit {
  constructor() {}
  links = [
    { id: 'WhatIsDementia', title: 'Що таке деменція?' },
    { id: 'DementiaInNumbers', title: 'Деменція в цифрах' },
    { id: 'PreventionOfDementia', title: 'Профілактика деменції' },
    { id: 'WhoAreUnforgettable', title: 'Хто такі “Незабутні”?' },
  ];
  linksDem: IKeyFactsAboutDementia[] = KeyFactsAboutDementiaMockData;
  keyDementiaCards: IInfoPage[] = keyDementiaCardsConfig;
  ngOnInit(): void {}
}
