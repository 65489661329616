import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import IInfoPage from 'src/app/models/infoPage.interface';

import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
@Component({
  selector: 'app-about-dementia-third-level',
  templateUrl: './about-dementia-third-level.component.html',
  styleUrls: ['./about-dementia-third-level.component.css'],
})
export class AboutDementiaThirdLevelComponent implements OnInit, OnDestroy {
  title = '';
  text = '';
  image = '';
  cards: IInfoPage[] = [];
  lightText = '';
  darkText = '';

  currentBreadcrumb: string | null = 'third';
  private routeSubscription: Subscription | null = null;
  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(params => {
      const routeId = params['thirdId'];
      if (routeId) {
        this.fetchCardList(routeId).subscribe((data: any[]) => {
          this.title = data[0].cardIntroductionTitle;
          this.text = data[0].cardIntroduction;
          this.image = data[0].cardImg;
          this.cards = data[0].cardText.map((card: any) => ({
            title: card.cardTextTitle,
            text: card.cardTextDescription,
            id: card.cardTextId,
          }));
          this.currentBreadcrumb = data[0].cardIntroductionTitle;
          const secondElement = document.querySelector(
            '[data-breadcrumb="second"]',
          );
          console.log(data[0]);
          if (secondElement) {
            secondElement.innerHTML = data[0].subMenu.dementiaMenu.menuTitle;
          }
          const thirdElement = document.querySelector(
            '[data-breadcrumb="third"]',
          );
          console.log(thirdElement);
          if (thirdElement) {
            thirdElement.innerHTML = data[0].cardIntroductionTitle;
          }
          // console.log(data[0]);
        });
      }
    });
  }

  fetchCardList(routeId: string): Observable<any[]> {
    const url = `https://nezabutni-backend.vercel.app/api/dementia-third-level-page?id=${routeId}`;
    return this.http.get<any[]>(url);
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
