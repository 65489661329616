<div class="relative flex flex-col text-main-text">
  <app-country-code-select
    class="absolute top-2.5 left-5"
    [selectedCountry]="selectedCountry"
    (currentCountry)="setCurrentCountry($event)"
    [formControl]="countryCode">
    <input
      (input)="inputValue($event)"
      class="w-60 ml-5 py-2.5 border-b-2 border-gray-stroke outline-none"
      placeholder="Введіть код"
      autofocus />
    <app-country-option
      *ngFor="let country of filteredCountries"
      [country]="country"></app-country-option>
    <ng-container *ngIf="!filteredCountries.length">
      <span
        class="flex items-center px-5 py-2.5 w-65 text-warning-base text-details"
        >Будь ласка, введіть дійсний телефонний код країни</span
      >
    </ng-container>
  </app-country-code-select>
  <input
    [formControl]="control"
    [ngClass]="{
      'border-attention-base': isInvalid
    }"
    class="w-75 h-12 rounded-10 pl-34 border-2 border-gray-stroke outline-none focus:border-primary-base"
    type="text"
    [placeholder]="selectedCountry.placeholder"
    [mask]="selectedCountry.mask"
    name="phone" />
  <span
    class="absolute top-2.5 right-2.5 text-attention-base text-main-text bg-gray-card w-7 rounded-full text-center"
    *ngIf="isInvalid"
    >!</span
  >
  <div
    class="text-details text-attention-base -bottom-6 left-1 animate-error-appear"
    *ngIf="control.touched && control.hasError('required')">
    Заповніть поле.
  </div>
</div>
