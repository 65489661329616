<app-page-banner-item
  [imgUrl]="'assets/images/banner/legal-aid.png'"
  title="Юридична допомога"
  text="Тут ви отримаєте інформацію про те, як отримати юридичну допомогу">
  <div class="flex gap-10 mt-5">
    <app-link-button
      url="/"
      [linkButtonType]="'Primary'"
      [title]="'Залишити заявку'"
      class="w-55">
    </app-link-button>
    <a
      role="button"
      class="flex w-70 h-11 py-2.5 pl-1.5 bg-transparent cursor-pointer"
      [copy-clipboard]="'info@nezabutni.org'">
      <svg
        width="28px"
        height="28px"
        icon="copy"
        iconCollection="button-icons-sprite"
        class="svg fill-gray-white hover:fill-secondary-base active:fill-secondary-base pr-2.5"></svg>
      <span
        class="text-main-text-bold text-gray-white hover:text-secondary-base active:text-secondary-base">
        info@nezabutni.org
      </span>
    </a>
  </div>
</app-page-banner-item>
<div class="items-center mx-auto w-295 my-30 text-main-text text-gray-dark">
  <p>
    Встановлення інвалідності відбувається за наявності хвороби, яка впливає на
    фізичні та розумові здібності людини, у тому числі, сюди відносять розлади
    психіки та поведінки, які тягнуть за собою часткову або повну втрату
    пам’яті, здатності мислення, нездатність керувати свідомістю, що перешкоджає
    виконанню найпростіших функцій.<br />
    Інвалідність встановлюється відповідно до міри впливу хвороби на
    життєдіяльність та організм людини.
  </p>
</div>
<div class="mb-30">
  <app-info-cards [cards]="cards"></app-info-cards>
</div>
<app-more-info [moreInfos]="moreInfosAid" [mainText]="mainText"></app-more-info>
