import { Component, OnInit } from '@angular/core';
import IInfoPage from 'src/app/models/infoPage.interface';
import { mediaCentreCardsConfig } from 'src/mockData/aboutUs_NewsAndMedia_MediaCentre';

@Component({
  selector: 'app-media-center',
  templateUrl: './media-center.component.html',
  styleUrls: ['./media-center.component.css'],
})
export class MediaCenterComponent implements OnInit {
  mediaCenterText =
    '<p class="text-main-text text-gray-dark">Ми з радістю відповімо на ваші запитання та допоможемо підготувати тексти й відео про роботу фонду й наших підопічних. Також ми можемо надати коментарі для матеріалів на тему благодійності або деменції.</p>';
  textValue: string = 'info@nezabutni.org';
  isCopy: boolean = false;
  protected mediaCentreCards: IInfoPage[] = mediaCentreCardsConfig;

  constructor() {}

  ngOnInit(): void {}

  changeIcon() {
    this.isCopy = true;
    setTimeout(() => {
      this.isCopy = false;
    }, 1000);
  }
}
