import moreIInfo from 'src/app/models/moreInfo.interface';

export const moreInfoMemoryLoss: moreIInfo[] = [
  {
    header: 'Які є найпоширеніші типи деменції?',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду різних станів.',
    img: './assets/images/news/image1.png',
  },
  {
    header: 'Які симптоми допоможуть розпізнати деменцію?',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду...',
    img: './assets/images/news/old_woman.png',
  },
  {
    header: 'Які є найпоширеніші типи деменції?',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду різних станів.',
    img: './assets/images/news/old_man.png',
  },
];

export const moreInfoLegalAid: moreIInfo[] = [
  {
    header: 'Психологічна підтримка',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду різних станів.',
    img: './assets/images/news/image1.png',
  },
  {
    header: 'Юридична допомога',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду...',
    img: './assets/images/news/old_woman.png',
  },
  {
    header: 'Консультація лікаря',
    content:
      'Деменція – це широкий термін, який використовується для опису ряду різних станів.',
    img: './assets/images/news/old_man.png',
  },
];
