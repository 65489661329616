import { IContact } from 'src/app/models/contact.interface';
import { ContactsEnum } from 'src/app/models/contacts.enum';
import { ISocialLink } from 'src/app/models/social-link.interface';

export const contactsConfig: IContact[] = [
  {
    id: ContactsEnum.Call,
    image: 'phone',
    heading: 'Подзвонити',
    contact: '+38 067 627 89 26',
    width: '40px',
    height: '40px',
  },
  {
    id: ContactsEnum.Write,
    image: 'mail',
    heading: 'Написати',
    contact: 'info@nezabutni.org',
    width: '40px',
    height: '40px',
  },
  {
    id: ContactsEnum.Address,
    image: 'location',
    heading: 'Адреса',
    contact: '01032, Україна, м. Київ, вул. Назарівська, 13, оф. 6',
    width: '40px',
    height: '40px',
  },
];

export const socialLinksConfig: ISocialLink[] = [
  {
    image: 'instagram',
    link: 'https://www.instagram.com/',
    width: '24px',
    height: '24px',
  },
  {
    image: 'facebook',
    link: 'https://www.facebook.com/',
    width: '16px',
    height: '29px',
  },
  {
    image: 'youtube',
    link: 'https://www.youtube.com/',
    width: '28',
    height: '19',
  },
  {
    image: 'telegram',
    link: 'https://web.telegram.org/',
    width: '26px',
    height: '22px',
  },
  {
    image: 'viber',
    link: 'https://www.viber.com/ua/',
    width: '26',
    height: '29',
  },
];
