import { Component, OnInit } from '@angular/core';
import { ReportsCardInterface } from '../reports-card/reports-card.component';

@Component({
  selector: 'app-documents-reports',
  templateUrl: './documents-reports.component.html',
  styleUrls: ['./documents-reports.component.css'],
})
export class DocumentsReportsComponent implements OnInit {
  docReportsData: ReportsCardInterface = {
    title: 'Установчі документи',
    text: [
      'На цій сторінці ви можете ознайомитися з нашим статутом, випискою з реєстру та рішенням про неприбутковість.',
      'Статут - є базовим документом, який визначає сферу нашої діяльності, визначає мету та завдання фонду. Виписка з Єдиного державного реєстру є свідченням того, що фонд зареєстрований як юридична особа. Рішення про неприбутковість є документом, що підтверджує включення фонду до реєстру неприбуткових установ та організацій.',
    ],
    reportsLinks: [
      { title: 'Статут Фонду', link: '/' },
      { title: 'Виписка з Єдиного Реєстру 2022', link: '/' },
      { title: 'Рішення про внесення до РНО', link: '/' },
    ],
  };

  constructor() {}

  ngOnInit(): void {}
}
