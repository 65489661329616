import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import IGlossary from 'src/app/models/glossary.interface';
import { glossaryMockData } from 'src/mockData/glossaryMockData';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.css'],
})
export class GlossaryComponent implements OnInit, AfterViewInit {
  @ViewChild('carousel', { static: true })
  carousel!: ElementRef;
  items!: HTMLElement[];
  currentIndex: number = 0;
  imageList: IGlossary[] = [];

  ngOnInit(): void {
    this.imageList = glossaryMockData;
  }
  ngAfterViewInit(): void {
    this.items = Array.from(
      this.carousel.nativeElement.querySelectorAll('.carousel-item'),
    );
    this.updateCarousel();
  }
  nextSlide(): void {
    if (this.currentIndex < this.items.length - 1) {
      this.currentIndex++;
      this.updateCarousel();
    }
  }

  prevSlide(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateCarousel();
    }
  }

  private updateCarousel(): void {
    const offset = -this.currentIndex * 100;
    this.carousel.nativeElement.querySelector(
      '.carousel-inner',
    ).style.transform = `translateX(${offset}%)`;
  }
}
